/** @jsxImportSource theme-ui */
import { Button } from './buttons';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { APIKeyBox } from '../components/APIKeys/APIKeyBox';
import { ListWithHeader } from '../components/layouts/ListWithHeader';
import { APIKey, User, Workspace } from '../consts/models';
import { capitalize, copyTextToClipboard } from '../utils/general';
import { isUserAdminOrOwner } from '../utils/identity';
import Icon from './Icon';

interface IProps {
  mode: string;
  user: User;
  workspace: Workspace;
  url: string;
  keys: APIKey[];
  setCreateKeyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setKeyToView: React.Dispatch<React.SetStateAction<APIKey | null>>;
  setKeyToRevoke: React.Dispatch<React.SetStateAction<APIKey | null>>;
}

const ListOfApiKeys: React.FC<IProps> = ({
  mode,
  user,
  workspace,
  url,
  keys,
  setCreateKeyModalOpen,
  setKeyToView,
  setKeyToRevoke,
}) => {
  const [hideInactive, setHideInactive] = React.useState<boolean>(true);
  const [visibleKeys, setVisibleKeys] = React.useState<APIKey[]>([]);
  const { addToast } = useToasts();

  React.useEffect(() => {
    setVisibleKeys(keys.filter((key) => (hideInactive ? key.isActive : true)));
  }, [hideInactive, keys]);

  // onClick handler function for the copy button
  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        addToast('Copied!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ListWithHeader
      title={capitalize(mode)}
      description={
        <div
          onClick={() => handleCopyClick(url)}
          sx={{
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <div>{url}</div>
          <div
            sx={{
              marginLeft: '.5em',
              position: 'relative',
              top: '3px',
              cursor: 'pointer',
            }}
          >
            <Icon size="1rem" icon="copy" color="#828E9D" transitionDuration="0ms" />
          </div>
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => setHideInactive(!hideInactive)}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            {hideInactive ? 'Show revoked keys' : 'Hide revoked keys'}
          </Button>
          {isUserAdminOrOwner(workspace, user) ? (
            <Button
              onClick={() => setCreateKeyModalOpen(true)}
              sx={{
                marginLeft: '.5rem',
              }}
            >
              Create new key
            </Button>
          ) : null}
        </>
      }
    >
      {keys &&
        keys.map((key) => (
          <div
            key={key?.apiKey}
            sx={{
              display: !hideInactive || key.isActive ? 'flex' : 'none',
            }}
          >
            <APIKeyBox
              apiKey={key}
              mode={mode}
              user={user}
              workspace={workspace}
              setKeyToView={setKeyToView}
              setKeyToRevoke={setKeyToRevoke}
            />
          </div>
        ))}

      {!visibleKeys.length && (
        <div
          sx={{
            color: '#627284',
            padding: '1em',
          }}
        >
          There are currently no active keys in {capitalize(mode)}.
        </div>
      )}
    </ListWithHeader>
  );
};

export default ListOfApiKeys;
