/** @jsxImportSource theme-ui */
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Theme, ThemeUIStyleObject, useThemeUI } from 'theme-ui';
import Icon from './Icon';
import { SupportedIcons } from './Icon';
interface IMenuItemProps {
  isActive?: boolean;
  label: string;
  icon: SupportedIcons;
  to: string;
  iconSize?: string;
  customSx?: {
    link?: ThemeUIStyleObject;
    icon?: ThemeUIStyleObject;
    label?: ThemeUIStyleObject;
  };
}

export type SideMenuItems = Array<(location: { pathname: string }) => IMenuItemProps>;

export const MenuItem: React.FC<
  IMenuItemProps & {
    theme: Theme;
  }
> = ({ icon, label, isActive, to, theme, iconSize, customSx }) => {
  const [isHovering, setHovering] = React.useState(false);
  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
      }}
      sx={customSx != null && customSx.link ? customSx.link : {}}
    >
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        sx={{
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          padding: '0rem 1rem',
          borderRadius: 0,
          cursor: 'pointer',
          color: 'textSecondary',
          transitionDuration: '200ms',
          marginBottom: '0.5rem',
          ...(isActive
            ? {
                backgroundColor: 'hoverGray',
                color: 'primary',
              }
            : {}),
          '&:hover': {
            backgroundColor: 'hoverGray',
            color: 'primary',
          },
        }}
      >
        <span
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon && (
            <Icon
              icon={icon}
              color={
                isHovering || isActive
                  ? (theme.colors!.primary as string)
                  : (theme.colors!.textSecondary as string)
              }
              size={iconSize}
            />
          )}{' '}
          <span
            sx={{
              marginLeft: '0.5rem',
              ...(customSx != null && customSx.label ? customSx.label : {}),
            }}
          >
            {label}
          </span>
        </span>
      </div>
    </Link>
  );
};

const SideMenu: React.FC<
  RouteComponentProps & {
    items: SideMenuItems;
  }
> = ({ location, items }) => {
  const { theme } = useThemeUI();
  return (
    <div
      sx={{
        width: '16rem',
      }}
    >
      {items.map((item: (location: { pathname: string }) => IMenuItemProps, index: number) => (
        <MenuItem {...{ ...item(location), theme, key: `menu-item__${index}` }} />
      ))}
    </div>
  );
};

export default withRouter(SideMenu);
