/** @jsxImportSource theme-ui */
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import DescriptionRow from './DescriptionRow';

export interface IColumn {
  label?: string;
  value: string | React.ReactElement;
  flex?: number;
  onClick?: () => void;
  customSx?: {
    container?: ThemeUIStyleObject;
    label?: ThemeUIStyleObject;
    value?: ThemeUIStyleObject;
  };
}

interface IHeader {
  value?: string;
  customSx?: {
    value?: ThemeUIStyleObject;
    container?: ThemeUIStyleObject;
  };
  flex?: number;
}

export type IRow = { customSx?: ThemeUIStyleObject; columns: IColumn[] };

export interface IProps {
  title?: string;
  headers?: IHeader[];
  actions?: React.ReactElement;
  rows: IRow[];
  customSx?: {
    title?: ThemeUIStyleObject;
    titleActionsContainer?: ThemeUIStyleObject;
    headers?: ThemeUIStyleObject;
    backdrop?: ThemeUIStyleObject;
  };
}

export const DescriptionList: React.FC<IProps> = ({ title, actions, rows, customSx, headers }) => {
  return (
    <div
      sx={{
        marginBottom: '3rem',
      }}
    >
      <div
        sx={{
          paddingBottom: '0.75rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...(customSx != null && customSx.titleActionsContainer != null
            ? customSx.titleActionsContainer
            : {}),
        }}
      >
        {title && (
          <div
            sx={{
              fontSize: 1,
              fontWeight: 500,
              ...(customSx != null && customSx.title != null ? customSx.title : {}),
            }}
          >
            {title}
          </div>
        )}

        <div>{actions}</div>
      </div>
      <div sx={customSx ? customSx.backdrop : {}}>
        {headers && (
          <div
            sx={{
              padding: '0.75rem 0rem',
              display: 'flex',
              borderBottom: 'gray',
              ...(customSx && customSx.headers != null ? customSx.headers : {}),
            }}
          >
            {headers.map((header, i) => (
              <div
                key={i}
                sx={{
                  flex: header.flex || 1,
                  ...(header.customSx != null && header.customSx.container != null
                    ? header.customSx.container
                    : {}),
                }}
              >
                <div
                  sx={{
                    fontSize: 3,
                    color: 'textSecondary',
                    fontWeight: 500,
                    ...(header.customSx && header.customSx.value != null
                      ? header.customSx.value
                      : {}),
                  }}
                >
                  {header.value}
                </div>
              </div>
            ))}
          </div>
        )}
        {rows.map((row, i) => (
          <DescriptionRow key={i} row={row} isLast={i >= rows.length - 1} />
        ))}
      </div>
    </div>
  );
};
