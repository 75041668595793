import gql from 'graphql-tag';

export const REVOKE_KEY = gql`
  mutation($mode: String!, $key: String!) {
    revokeAPIKey(mode: $mode, key: $key) {
      success
      error {
        message
        code
      }
      key
      inactiveAt
      isActive
    }
  }
`;
