/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import Card from '../uikit/Card';
import { PrimaryButton } from '../uikit/buttons';
import Input from '../uikit/Input';
import { Field } from '../uikit/forms';
import { get } from 'lodash';
import React, { FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router';
import { useAuth } from '../utils/authentication';
import { DevDashboardError } from '../consts/DevDashboardError';
import { SET_NEW_PASSWORD } from '../graphql/mutations/set-new-password';
import PasswordHoc, { PasswordHocInjectedProps } from '../hocs/PasswordHoc';
import logo from '../images/pinwheel-logo.svg';
import { User } from '../consts/models';
import { useSession } from '../utils/session';
import { getCurrentWorkspace } from '../utils/identity';

const SetNewPasswordForm: React.FC<
  PasswordHocInjectedProps &
    RouteComponentProps<{
      token: string;
    }>
> = ({
  match,
  newPassword,
  onFieldBlur,
  updatePasswordField,
  passwordValidationError,
  isReadyToSubmitPasswords,
}) => {
  const [setNewPassword] = useMutation(SET_NEW_PASSWORD);
  const [error, setError] = React.useState<string | null>(null);
  const auth = useAuth();
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);
  const session = useSession();

  const onRecaptchaChangeHandler = (token: string | null) => {
    setRecaptchaToken(!token ? '' : token);
  };

  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const user = session.data as User;
  const workspaces = get(user, 'workspaces', []);
  const currentWorkspace = getCurrentWorkspace(workspaces, auth.currentWorkspaceId);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setError(null);

    if (!isReadyToSubmitPasswords) {
      return;
    }

    try {
      const result = await setNewPassword({
        variables: {
          token: match.params.token,
          password: newPassword.password,
        },
        context: {
          recaptchaToken,
        },
      });
      const errorMsg = get(result, 'data.setNewPassword.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.setNewPassword.error.code', null);
        const err = new DevDashboardError(errorMsg, code);
        datadogLogs.logger.info('displayed error to user', {
          operation: 'setNewPassword',
          errorMsg: err.message,
        });
        setError(err.message);

        datadogLogs.logger.info('Password reset failed', {
          error: err.message,
          trackingIds: { workspaceName: currentWorkspace?.name, workspaceId: currentWorkspace?.id },
        });

        return;
      }

      datadogLogs.logger.info('Password reset succeeded', {
        trackingIds: { workspaceName: currentWorkspace?.name, workspaceId: currentWorkspace?.id },
      });

      auth.setAccessToken!(result.data.setNewPassword.accessToken);
    } catch (caughtError) {
      const errorMsg = get(
        caughtError,
        'graphQLErrors[0].message',
        'Unknown error, failed to reset your password'
      );
      setError(errorMsg);
      datadogLogs.logger.info('displayed error to user', { operation: 'setNewPassword', errorMsg });

      datadogLogs.logger.info('Password reset failed with unknown error', {
        error: caughtError,
        trackingIds: { workspaceName: currentWorkspace?.name, workspaceId: currentWorkspace?.id },
      });
    } finally {
      (window as any).grecaptcha.reset(recaptchaRef.current?.getWidgetId());
      onRecaptchaChangeHandler(null);
    }
  };

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        <div sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img alt="Pinwheel" src={logo} />
        </div>
        <Card
          sx={{
            width: '25rem',
          }}
        >
          <div
            sx={{
              fontSize: 1,
              fontWeight: '500',
              marginBottom: '2rem',
            }}
          >
            Set new password
          </div>

          <form onSubmit={handleSubmit}>
            <Field
              htmlFor="password"
              label="Password"
              sx={{
                '&:after': {
                  content:
                    passwordValidationError.password != null
                      ? `"${passwordValidationError.password}"`
                      : '""',
                  marginTop: '4px',
                  color: 'danger',
                  fontSize: '0.75rem',
                },
              }}
            >
              <Input
                type="password"
                id="password"
                value={newPassword.password}
                onChange={(value: string) =>
                  updatePasswordField({ ...newPassword, password: value })
                }
                onBlur={() => onFieldBlur('password')}
              />
            </Field>

            <Field
              htmlFor="confirmPassword"
              label="Confirm Password"
              sx={{
                marginBottom: '2rem',
                '&:after': {
                  content:
                    passwordValidationError.confirmation != null
                      ? `"${passwordValidationError.confirmation}"`
                      : '""',
                  marginTop: '4px',
                  color: 'danger',
                  fontSize: '0.75rem',
                },
              }}
            >
              <Input
                type="password"
                id="confirmPassword"
                value={newPassword.passwordConfirmation}
                onBlur={() => onFieldBlur('confirmation')}
                onChange={(value: string) =>
                  updatePasswordField({
                    ...newPassword,
                    passwordConfirmation: value,
                  })
                }
              />
            </Field>

            {error && (
              <div
                sx={{
                  marginTop: '-0.5rem',
                  color: 'danger',
                  marginBottom: '1rem',
                }}
              >
                {error}
              </div>
            )}

            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column',
                width: '100%',
                margin: error ? '1rem 0 2rem 0' : '2rem 0',
              }}
            >
              <div sx={{ width: 'auto', margin: 'auto' }}>
                <ReCAPTCHA
                  sitekey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
                  onChange={onRecaptchaChangeHandler}
                  onExpired={() => setRecaptchaToken(null)}
                  onErrored={() => setRecaptchaToken(null)}
                  ref={recaptchaRef}
                />
              </div>
            </div>

            <PrimaryButton block disabled={!isReadyToSubmitPasswords || !recaptchaToken}>
              Set Password
            </PrimaryButton>
          </form>
        </Card>
      </div>
    </div>
  );
};

const SetNewPassword: React.FC<RouteComponentProps<{
  token: string;
}>> = (props) => {
  return (
    <PasswordHoc initialProps={props} TargetComponent={SetNewPasswordForm} ignoreCurrentPassword />
  );
};

export default SetNewPassword;
