import React from 'react';
import { some } from 'lodash';
import {getPasswordValidation} from "../utils/validations";

export interface PasswordHocInjectedProps {
  newPassword: {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
  };
  onFieldBlur: (field: 'current' | 'password' | 'confirmation') => void;
  updatePasswordField: (updatedObject: {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
  }) => void;
  passwordValidationError: {
    [key: string]: string | undefined;
  };
  isReadyToSubmitPasswords: boolean;
  resetNewPasswordState: () => void;
}

interface Props<T> {
  initialProps: T;
  TargetComponent: typeof React.Component | React.FC<T & PasswordHocInjectedProps>;
  ignoreCurrentPassword?: boolean;
}

const PasswordHoc = <P,>({ initialProps, TargetComponent, ignoreCurrentPassword }: Props<P>) => {
  const [validationError, setValidationError] = React.useState<{
    [key: string]: string | undefined;
  }>({
    current: undefined,
    password: undefined,
    confirmation: undefined,
  });
  const [newPassword, updatePasswordField] = React.useState({
    password: '',
    passwordConfirmation: '',
    currentPassword: '',
  });

  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);
  const [blurredFields, setBlurredField] = React.useState<string[]>([]);

  React.useEffect(() => {
    const { password, passwordConfirmation, currentPassword } = newPassword;

    const invalidNewPassword = !password.length || !passwordConfirmation.length;
    const isFormNotReadyToEvaluate = !ignoreCurrentPassword
      ? invalidNewPassword || !currentPassword.length
      : !password.length || !passwordConfirmation.length;

    if (isFormNotReadyToEvaluate && isReadyToSubmit) {
      setIsReadyToSubmit(false);
      return;
    }

    const newPasswordsMatch =
      password.length > 0 && passwordConfirmation.length > 0 && password === passwordConfirmation;
    const newPasswordIsValid = !ignoreCurrentPassword
      ? newPasswordsMatch && currentPassword.length > 0
      : newPasswordsMatch;
    if (!newPasswordIsValid && isReadyToSubmit) {
      setIsReadyToSubmit(false);
      return;
    }

    const hasValidationErrors = some(
      Object.values(validationError),
      (value?: string) => value != null
    );
    if (!isReadyToSubmit && newPasswordIsValid && !hasValidationErrors) {
      setIsReadyToSubmit(true);
    }
  }, [newPassword, setIsReadyToSubmit, isReadyToSubmit, ignoreCurrentPassword, validationError]);

  React.useEffect(() => {
    const { password, passwordConfirmation, currentPassword } = newPassword;

    const isCurrentPasswordInvalid =
      !ignoreCurrentPassword && !currentPassword.length && blurredFields.includes('current');
    if (isCurrentPasswordInvalid && !validationError.current) {
      setValidationError({
        ...validationError,
        current: 'You need to provide your current password',
      });
      return;
    }

    if (!isCurrentPasswordInvalid && validationError.current) {
      setValidationError({
        ...validationError,
        current: undefined,
      });
      return;
    }

    const passwordValidation = password.length > 0 ? getPasswordValidation(password) : undefined;
    if (
      (passwordValidation && !validationError.password) ||
      validationError.password !== passwordValidation
    ) {
      setValidationError({
        ...validationError,
        password: passwordValidation,
      });
      return;
    }

    const isNewPasswordConfirmationInvalid =
      password.length > 0 &&
      passwordConfirmation.length > 0 &&
      password !== passwordConfirmation &&
      (blurredFields.includes('password') || blurredFields.includes('confirmation'));
    if (isNewPasswordConfirmationInvalid && !validationError.confirmation) {
      setValidationError({
        ...validationError,
        confirmation: "The new password doesn't match the confirmation",
      });
      return;
    }

    if (!isNewPasswordConfirmationInvalid && validationError.confirmation) {
      setValidationError({
        ...validationError,
        confirmation: undefined,
      });
    }
  }, [newPassword, setValidationError, validationError, blurredFields, ignoreCurrentPassword]);

  const onFieldBlur = (field: 'current' | 'password' | 'confirmation') =>
    !blurredFields.includes(field) && setBlurredField([...blurredFields, field]);

  const resetNewPasswordState = () => {
    updatePasswordField({
      password: '',
      passwordConfirmation: '',
      currentPassword: '',
    });
    setValidationError({
      current: undefined,
      password: undefined,
      confirmation: undefined,
    });
    setIsReadyToSubmit(false);
    setBlurredField([]);
  };

  const nextProps = {
    ...initialProps,
    newPassword,
    onFieldBlur,
    updatePasswordField,
    passwordValidationError: validationError,
    isReadyToSubmitPasswords: isReadyToSubmit,
    resetNewPasswordState,
  };

  return <TargetComponent {...nextProps} />;
};

export default PasswordHoc;
