/** @jsxImportSource theme-ui */
import { PrimaryButton } from '../../uikit/buttons';
import { get } from 'lodash';
import React from 'react';
import Modal from '../../uikit/Modal';
import { DangerIcon } from '../../uikit/Icon';

type Props<T> = {
  form: T;
  disabled?: boolean;
  submitFormFn: (form: T) => Promise<any>;
  onFormSuccess: (url: string) => void;
};

function CreateSmartBranchUrlButton<T>({ form, disabled, submitFormFn, onFormSuccess }: Props<T>) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setButtonLoadingState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const processError = (errors: any) => {
    const error = Array.isArray(errors) ? errors[0] : errors;
    if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      const message =
        typeof error.message !== 'string'
          ? get(
              error.message,
              'message',
              'An unknown error happened trying to create a smart branch url'
            )
          : error.message;
      setErrorMessage(message);
    }
    setModalOpen(true);
    setTimeout(() => setButtonLoadingState(false), 500);
  };

  const onSubmit = async () => {
    setButtonLoadingState(true);
    try {
      const { data, error } = await submitFormFn(form);
      if (error) {
        processError(error);
      }
      if (data) {
        onFormSuccess(data.url);
      }
      setButtonLoadingState(false);
    } catch (error) {
      processError(error);
    }
  };

  return (
    <React.Fragment>
      <div
        sx={{
          display: 'flex',
          '&>*': {
            margin: 1,
          },
        }}
      >
        <PrimaryButton data-testid="submit" onClick={() => onSubmit()}>
          {!isLoading && 'Create Smart Branch URL'}
          {isLoading && 'Creating...'}{' '}
        </PrimaryButton>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        width="25rem"
        appElement={document.getElementById('root') as HTMLElement}
      >
        <div
          sx={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <div
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row',
              marginBottom: '1rem',
              justifyContent: 'start',
            }}
          >
            <div
              sx={{
                width: '1rem',
                height: '1rem',
                marginRight: '0.3rem',
                marginTop: '2px',
              }}
            >
              <DangerIcon />
            </div>
            <div
              sx={{
                fontSize: '1.125rem',
                color: '#21292e',
                lineHeight: 1.44,
                fontWeight: 500,
              }}
            >
              URL could not be created
            </div>
          </div>
          <div
            sx={{
              fontSize: '1.125rem',
              lineHeight: 1.44,
              color: '#727d8b',
              marginBottom: '1.5rem',
            }}
          >
            {errorMessage}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default CreateSmartBranchUrlButton;
