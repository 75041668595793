/** @jsxImportSource theme-ui */
import React, { useContext } from 'react';
import checkImage from '../images/check.svg';

interface IRadioCtx {
  name?: string;
  onChange?: (newVal: string) => void;
  value?: string | null;
}

const RadioCtx = React.createContext<IRadioCtx>({});

export const RadioOption: React.FC<{
  value: string;
  label: string;
  help: string;
  id: string;
  required?: boolean;
}> = ({ id, label, help, value, required }) => {
  const { name, onChange, value: currentVal } = useContext(RadioCtx);
  const isSelected = currentVal === value;
  return (
    <div
      sx={{
        padding: '0.5rem 0rem',
        borderBottom: 'gray',
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <div
        sx={{
          position: 'relative',
          marginRight: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <img alt="check" src={checkImage} />
        </div>
        <input
          id={id}
          required={required}
          style={{
            appearance: 'none',
          }}
          onChange={(e) => {
            onChange!(e.target.value);
          }}
          sx={{
            height: '1rem',
            flexShrink: 0,
            width: '1rem',
            borderRadius: '50%',
            border: 'grayDarker',
            margin: 0,
            ...(isSelected
              ? {
                  backgroundColor: 'primary',
                  border: 'primary',
                }
              : {}),
          }}
          type="radio"
          name={name}
          value={value}
          checked={currentVal === value}
        />
      </div>
      <label htmlFor={id} sx={{ width: '7.5rem', flexShrink: 0, marginRight: '1rem' }}>
        {label}
      </label>
      <div
        sx={{
          color: 'textSecondary',
        }}
      >
        {help}
      </div>
    </div>
  );
};

const RadioGroup: React.FC<{
  className?: string;
  name: string;
  onChange: (v: string | null) => void;
  value: string | null;
}> = ({ className, children, name, value, onChange }) => {
  return (
    <RadioCtx.Provider value={{ value, name, onChange }}>
      <div
        sx={{
          borderTop: 'gray',
          borderBottom: 'gray',
        }}
        className={className}
      >
        {children}
      </div>
    </RadioCtx.Provider>
  );
};

export default RadioGroup;
