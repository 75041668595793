import _ from 'lodash';
import React, { useState } from 'react';
import Form, { OnUrlCreation } from './Form';

export type TSmartBranchForm = {
  name: string,
  email: string;
  mode: string;
  tags?: string;
};

export const formInitialState: TSmartBranchForm = {
  name: '',
  email: '',
  mode: 'sandbox',
  tags: JSON.stringify({
    branch_id: "branch_1",
    employee_id: "0123",
  }, null, 4),
};

export default function ({
  onUrlCreation,
  submitFormFn,
}: {
  onUrlCreation: OnUrlCreation;
  submitFormFn: (form: TSmartBranchForm) => Promise<any>;
}) {
  const [form, setForm] = useState(formInitialState);

  return (
    <>
      <Form<TSmartBranchForm>
        form={form}
        setForm={setForm}
        formInitialState={formInitialState}
        submitFormFn={submitFormFn}
        onFormSuccess={onUrlCreation}
      />
    </>
  );
}
