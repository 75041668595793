import { useHistory } from 'react-router';
import { Button } from '../../designs-v2/components/button';
import Graphic from './graphic.svg';
import { ExternalLink } from './resources';

export const AnalyticsCTA = () => {
  const history = useHistory();

  return (
    <div className="flex flex-row gap-20">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <div className="text-preset-1 font-bold p-2 text-content-primary bg-bg-primary--subtle rounded-lg">
            New!
          </div>
        </div>

        <h1 className="text-preset-6 font-bold">Keep a pulse on performance</h1>
        <p className="text-preset-3">
          Our intuitive dashboard allows you to track metrics in real-time, offering insights to
          fuel your growth and optimize your financial offerings.
        </p>

        <p className="text-preset-3">
          Check out our User Guide for details on how to use our new dashboard, or reach out to{' '}
          <a className="text-blue-500" href="mailto: sales@pinwheelapi.com">
            sales@pinwheelapi.com
          </a>{' '}
          with any questions.
        </p>
        <div>
          <ExternalLink
            text="Dashboard User Guide"
            href={'https://docs.pinwheelapi.com/docs/dashboard'}
          />
        </div>
      </div>
      <div className="min-w-[20rem] flex-1">
        <img className="object-contain " src={Graphic} />
      </div>
    </div>
  );
};
