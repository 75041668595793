import React from 'react';
import { DemandPartnerCustomer, Employer } from '../../consts/models';
import { SendFormRequest } from '../../utils/requests';
import TestConsoleForm from './TestConsoleForm';
import TestConsoleLinkToken from './TestConsoleLinkToken';
import { MODE_KEYS, ModeKey } from './form-helpers';
import { TLinkTokenForm } from './types';

const TestConsoleContainer = ({
  availableModes = MODE_KEYS,
  onFormChange,
  setEmployerSearchText,
  employers,
  demandPartnerCustomers,
  getSubmitFormFn,
}: {
  availableModes: ModeKey[];
  onFormChange?: (f: TLinkTokenForm) => void;
  getSubmitFormFn: (sendFormRequest: SendFormRequest) => (form: TLinkTokenForm) => Promise<any>;
  setEmployerSearchText: (search: string) => void;
  employers: Employer[];
  demandPartnerCustomers: DemandPartnerCustomer[];
}) => {
  const [token, setToken] = React.useState<string | undefined>();
  const [url, setUrl] = React.useState<string | undefined>();
  const [lastAccountId, setLastAccountId] = React.useState<string | undefined>();

  const [lastValueType, setLastValueType] = React.useState<'url' | 'token' | null>();

  return (
    <div className="testConsole">
      <TestConsoleForm
        getSubmitFormFn={getSubmitFormFn}
        employers={employers}
        demandPartnerCustomers={demandPartnerCustomers}
        setEmployerSearchText={setEmployerSearchText}
        onFormChange={onFormChange}
        availableModes={availableModes}
        lastAccountId={lastAccountId}
        onTokenCreation={(value: string, valueType: 'url' | 'token') => {
          if (valueType === 'token') setToken(value);
          if (valueType === 'url') setUrl(value);
          setLastValueType(valueType);
        }}
      />
      {token != null && lastValueType === 'token' && (
        <TestConsoleLinkToken
          type="token"
          linkToken={token}
          sdk_onLogin={({ accountId }) => setLastAccountId(accountId)}
        />
      )}
      {url != null && lastValueType === 'url' && (
        <TestConsoleLinkToken
          type="url"
          linkToken={url}
          sdk_onLogin={({ accountId }) => setLastAccountId(accountId)}
        />
      )}
    </div>
  );
};

export default TestConsoleContainer;
