import { get } from 'lodash';

export const getNewToken = async (currentToken: string) => {
  const requestHeaders = new Headers();
  requestHeaders.append('Authorization', currentToken);
  requestHeaders.append('Content-Type', 'application/json');

  const graphql = JSON.stringify({
    query: 'mutation {\n    token {\n        accessToken\n    }\n}',
    variables: {},
  });
  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: graphql,
  };
  const response = await fetch(`${process.env.REACT_APP_SRV_URL}/graphql`, requestOptions);
  const responseJson = await response.json();
  const token = get(responseJson, 'data.token.accessToken', null);
  if (!token) {
    throw Error('Failed to refresh your session.');
  }
  return token;
};
