/** @jsxImportSource theme-ui */
import React from 'react';
import { ThemeUIStyleObject, useThemeUI } from 'theme-ui';
import Icon from './Icon';
import { SupportedIcons } from './Icon';

interface IButtonProps {
  className?: string;
  block?: boolean;
  type?: 'submit' | 'button';
  size?: 'small' | 'medium';
  icon?: SupportedIcons;
  disabled?: boolean;
  onClick?: () => any;
  customSx?: ThemeUIStyleObject;
}

const heights = {
  small: '26px',
  medium: '2.5rem',
};

const lineHeights = {
  medium: '24px',
  small: '18px',
};

const fontSizes = {
  medium: 0,
  small: 3,
};

const paddings = {
  medium: '0.5rem 1.5rem',
  small: '0.25rem 0.5rem',
};

export const Button: React.FC<IButtonProps> = ({
  type,
  block,
  className,
  children,
  size,
  icon,
  disabled,
  onClick,
  customSx,
}) => {
  const extraStyles: { [key: string]: string } = {};
  const { theme } = useThemeUI();
  const [isButtonActive, setButtonActive] = React.useState(false);

  if (block) {
    extraStyles.width = '100%';
    extraStyles.justifyContent = 'center';
  }

  return (
    <button
      className={className}
      type={type}
      disabled={disabled}
      onMouseLeave={() => isButtonActive && setButtonActive(false)}
      onMouseDown={() => setButtonActive(true)}
      onMouseUp={() => setButtonActive(false)}
      onClick={onClick}
      sx={{
        height: heights[size!],
        padding: paddings[size!],
        lineHeight: lineHeights[size!],
        fontSize: fontSizes[size!],
        display: 'flex',
        alignItems: 'center',
        border: 'gray',
        borderRadius: 0,
        // backgroundColor: 'white !important',
        ...(isButtonActive
          ? {
              backgroundColor: 'primary !important',
              border: 'blue',
              outline: 'none',
              color: 'white !important',
            }
          : {}),
        '&:hover': {
          border: 'blue',
        },
        '&:focus': {
          outline: 'none',
        },
        ...extraStyles,
        ...(customSx != null ? customSx : {}),
      }}
    >
      {icon && (
        <div sx={{ marginRight: '0.5rem' }}>
          <Icon
            size="0.75rem"
            icon={icon}
            color={(isButtonActive ? 'white !important' : theme.colors!.text) as string}
            transitionDuration="0ms"
          />
        </div>
      )}
      {children}
    </button>
  );
};

type IPrimaryButtonProps = IButtonProps;

export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({ children, ...restProps }) => {
  return (
    <Button
      className="bg-bg-primary"
      {...restProps}
      sx={{
        backgroundColor: 'primary',
        border: 'blue',
        color: 'white',
        fontWeight: '500',
        '&:hover': {
          backgroundColor: '#257BA9',
          border: 'solid 1px #257BA9',
        },
        '&:active': {
          backgroundColor: '#005D88',
          border: 'solid 1px #005D88',
        },
      }}
    >
      {children}
    </Button>
  );
};

export const DangerButton: React.FC<IButtonProps> = ({ children, ...restProps }) => {
  return (
    <Button
      {...restProps}
      sx={{
        backgroundColor: 'danger',
        border: 'danger',
        color: 'white',
        fontWeight: '500',
        marginLeft: '0.5rem',
        '&:hover': {
          border: 'solid 1px #C34647',
          backgroundColor: '#C34647',
        },
        '&:active': {
          border: 'solid 1px #AA2F32',
          backgroundColor: '#AA2F32',
        },
      }}
    >
      {children}
    </Button>
  );
};

Button.defaultProps = {
  size: 'medium',
};
