export const TimeframeOptions = [
  {
    value: 'TODAY',
    label: 'Today',
  },
  {
    value: 'YESTERDAY',
    label: 'Yesterday',
  },
  {
    value: 'LAST_7_DAYS',
    label: 'Last 7 Days',
  },
  {
    value: 'LAST_14_DAYS',
    label: 'Last 14 Days',
  },
  {
    value: 'LAST_31_DAYS',
    label: 'Last 31 Days',
  },
  {
    value: 'LAST_90_DAYS',
    label: 'Last 90 Days',
  },
] as const;

export const ChannelOptions = [
  {
    value: 'SDK',
    label: 'SDK',
  },
  {
    value: 'BRANCH_NO_CODE',
    label: 'Smart Branch',
  },
] as const;

export const ProductOptions = [
  { label: 'Direct Deposit', value: 'Direct Deposit Switch' },
  { label: 'Earnings Stream', value: 'Identity & Employment' },
  { label: 'Paystubs', value: 'Paystubs' },
  { label: 'Shifts', value: 'Shifts' },
  { label: 'Other', value: 'Other' },
  { label: 'Income', value: 'Income' },
] as const;
