import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
