import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import JoinWorkspace from './pages/JoinWorkspace';
import Login from './pages/Login';
import RequestPasswordResetEmail from './pages/RequestPasswordResetEmail';
import SetNewPassword from './pages/SetNewPassword';

const UnauthenticatedApp = () => {
  return (
    <Switch>
      <Route exact path="/join/:token" component={JoinWorkspace} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={RequestPasswordResetEmail} />
      <Route exact path="/set-new-password/:token" component={SetNewPassword} />

      <Redirect to="/login" />
    </Switch>
  );
};

export default UnauthenticatedApp;
