/** @jsxImportSource theme-ui */
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { APIKey } from '../consts/models';
import Modal from '../uikit/Modal';
import { Button, PrimaryButton } from '../uikit/buttons';
import { copyTextToClipboard } from '../utils/general';

const ViewSecretModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
  keyToView: APIKey | null;
}> = ({ isOpen, onRequestClose, keyToView }) => {
  const { addToast } = useToasts();

  // onClick handler function for the copy button
  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        addToast('Copied!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="35rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        Sandbox API key secret
      </div>

      <div
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          padding: '1rem',
          border: '1px solid #ddd',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          sx={{
            fontSize: '.9em',
            fontWeight: 500,
            color: '#888',
            marginBottom: '.75rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            Secret
          </div>
          <div
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="small"
              icon="copy"
              sx={{
                float: 'right',
                color: '#888',
                marginTop: '-.2rem',
              }}
              onClick={() => handleCopyClick(keyToView?.apiSecret || '')}
            >
              Copy
            </Button>
          </div>
        </div>
        <div
          sx={{
            fontSize: '.7em',
            fontFamily: "'Roboto Mono', monospace, 'Circular'",
            fontWeight: 700,
            color: 'black',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {keyToView?.apiSecret}
        </div>
      </div>

      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <PrimaryButton sx={{ marginLeft: '1rem' }} onClick={onRequestClose}>
          Dismiss
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ViewSecretModal;
