/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@pinwheel/origami';
import { useLocation } from 'react-router-dom';
//Apparently React Router 5 doesn't have a direct way of getting query params
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const LoginAuth0: React.FC = () => {
  const auth0 = useAuth0();
  const { loginWithRedirect } = auth0;
  const query = useQuery();
  useEffect(() => {
    // HACK: An Auth0 error will occur if the domain changes in between redirecting to and from the Auth0 login page.
    if (window.location.href.includes('developer.')) {
      // Once the URL is updated, the page will reload, causing useEffect to run again.
      window.location.href = window.location.href.replace('developer.', 'dashboard.');
    } else {
      const connection = query.get('connection');
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin.replace('developer', 'dashboard'),
          ...(connection && { connection }),
        },
      });
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen h-screen">
      <Loader type="logo" />
    </div>
  );
};

export default LoginAuth0;
