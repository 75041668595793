/** @jsxImportSource theme-ui */
import React from 'react';

interface IAvatarProps {
  initials: string;
}

const Avatar: React.FC<IAvatarProps> = ({ initials }) => {
  return (
    <div
      sx={{
        borderRadius: '50%',
        height: '1.5rem',
        width: '1.5rem',
        backgroundColor: 'gray',
        color: 'white',
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '500',
        textTransform: 'uppercase',
      }}
    >
      {initials}
    </div>
  );
};

interface IUserAvatarProps {
  name: string;
  initials: string;
}

const UserAvatar: React.FC<IUserAvatarProps> = ({ name, initials }) => {
  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar initials={initials} />
      <div
        sx={{
          fontSize: 0,
          marginLeft: '0.5rem',
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default UserAvatar;
