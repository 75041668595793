import { get } from 'lodash';
import { useAuth } from '../utils/authentication';
import { Mode, User, Workspace } from '../consts/models';
import { useSession } from '../utils/session';
import { getActiveApiKeysByMode, getCurrentWorkspace } from '../utils/identity';
import { MODE_KEYS, ModeKey } from '../components/TestConsole/form-helpers';

export function useWorkspace(): Workspace | undefined {
  const session = useSession();
  const { currentWorkspaceId } = useAuth();

  if (session.loading || !session.data) {
    return undefined;
  }

  const user = session.data as User;
  const workspaces = get(user, 'workspaces', []);
  return getCurrentWorkspace(workspaces, currentWorkspaceId);
}

export function useRequiredWorkspace(): Workspace {
  const workspace = useWorkspace();

  if (!workspace) {
    throw new Error('Workspace not found');
  }

  return workspace;
}

export function useAvailableModes(workspace: Workspace): Mode[] {
  const activeKeysByMode = getActiveApiKeysByMode(workspace);

  const availableModes = MODE_KEYS.reduce(
    (acc, curr) => (activeKeysByMode[curr].length > 0 ? acc.concat(curr) : acc),
    [] as ModeKey[]
  );

  return availableModes;
}
