/** @jsxImportSource theme-ui */
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAuth } from '../utils/authentication';
import TestConsoleContainer from '../components/TestConsole';
import { ModeKey } from '../components/TestConsole/form-helpers';
import { TLinkTokenForm } from '../components/TestConsole/types';
import AppContextProvider from '../context/AppContextProvider';
import { EMPLOYERS_NEW } from '../graphql/queries/employers';
import { DemandPartnerCustomer, Employer } from '../consts/models';
import { SendFormRequest } from '../utils/requests';
import { useSession } from '../utils/session';
import { getActiveApiKeysByMode, getCurrentWorkspace } from '../utils/identity';
import { useAvailableModes } from '../hooks/useWorkspace';
import { DEMAND_PARTNER_CUSTOMERS } from '../graphql/queries/demand-partner-customers';

const TestConsole = () => {
  const session = useSession();
  const { currentWorkspaceId } = useAuth();
  const workspace = getCurrentWorkspace(session.data?.workspaces || [], currentWorkspaceId);

  const [mode, setMode] = useState<ModeKey>();

  const [getEmployers, { data: employersData, refetch: refetchEmployers }] = useLazyQuery<{
    employers: Employer[];
  }>(EMPLOYERS_NEW);

  const [
    getDemandPartnerCustomers,
    { data: demandPartnerCustomerData, refetch: refetchDemandPartnerCustomers },
  ] = useLazyQuery<{
    demandPartnerCustomers: DemandPartnerCustomer[];
  }>(DEMAND_PARTNER_CUSTOMERS);

  useEffect(() => {
    getEmployers({ variables: {} });
  }, [getEmployers]);

  useEffect(() => {
    if (workspace?.isDemandPartnerProxy) getDemandPartnerCustomers({ variables: {} });
  }, [workspace, getDemandPartnerCustomers]);

  if (session.loading || !session.data) {
    return null;
  }

  if (!workspace) {
    return (
      <div>
        <div
          sx={{
            fontSize: 2,
            fontWeight: 500,
            marginBottom: '2.5rem',
          }}
        >
          Test Console
        </div>
        <p>
          You need access to a workspace in order to use this feature. Please contact an
          adminstrator.
        </p>
      </div>
    );
  }

  const employers = employersData?.employers || [];
  const setEmployerSearchText = (search: string) => {
    void refetchEmployers({ search });
  };

  const demandPartnerCustomers = demandPartnerCustomerData?.demandPartnerCustomers || [];

  const activeKeysByMode = getActiveApiKeysByMode(workspace);
  const availableModes = useAvailableModes(workspace);

  const getSubmitFormFn = (sendFormRequest: SendFormRequest) => {
    return async (form: TLinkTokenForm) => {
      if (!mode || !workspace) return;
      const { apiSecret } = activeKeysByMode[mode][0];
      const result = await sendFormRequest(form, apiSecret);
      // eslint-disable-next-line consistent-return
      return result;
    };
  };

  return (
    <div
      sx={{
        backgroundColor: 'white',
        padding: '3rem 4rem',
        borderRadius: 0,
        boxShadow: 'main',
      }}
    >
      <AppContextProvider value={{}}>
        {!workspace && <p>You need to select a workspace.</p>}
        <TestConsoleContainer
          employers={employers}
          demandPartnerCustomers={demandPartnerCustomers}
          setEmployerSearchText={setEmployerSearchText}
          availableModes={availableModes}
          onFormChange={(f: TLinkTokenForm) => {
            if (f.mode?.value !== mode) setMode(f.mode?.value);
          }}
          getSubmitFormFn={getSubmitFormFn}
        />
      </AppContextProvider>
    </div>
  );
};

export default TestConsole;
