import { Popover } from '@headlessui/react';
import React, { useRef } from 'react';
import { numberFormatter } from '.';
import { ActionListItemProps } from '../../../designs-v2/components/dropdown/actionList';
import { Checkbox } from '../../../designs-v2/components/form/checkbox';
import { Tooltip } from '../../../designs-v2/components/tooltip/index';
import { Icon } from '../../../designs-v2/primitives/icons';
import { useFieldCopy } from '../copy/field-names';
import { FunnelFieldMapping, FunnelOptions, FunnelStep } from '../types';
import { FunnelVisualization } from './visualizer';

const ChevronRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    className="hidden group-hover:flex shrink-0"
    viewBox="0 0 24 24"
  >
    <path
      fill="white"
      fillRule="evenodd"
      d="M8.293 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L13.586 12 8.293 6.707a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="hidden group-hover:flex shrink-0"
  >
    <path
      fill="white"
      fillRule="evenodd"
      d="M5.293 8.293a1 1 0 0 1 1.414 0L12 13.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);

const FunnelButtons: React.FC<{
  closeFunnelStep: () => void;
  addNewStep: () => void;
  menuOpen: boolean;
}> = ({ menuOpen, closeFunnelStep, addNewStep }) => {
  return (
    <>
      <svg
        className={`group-hover:flex shrink-0 ${menuOpen ? 'flex' : 'hidden'}`}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        onClick={(e) => {
          e.preventDefault();
          closeFunnelStep();
        }}
      >
        <path
          fill="white"
          fillRule="evenodd"
          d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
          clipRule="evenodd"
        />
      </svg>
      <svg
        className={`group-hover:flex shrink-0 ${menuOpen ? 'flex' : 'hidden'}`}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        onClick={(e) => {
          e.preventDefault();
          addNewStep();
        }}
      >
        <path
          fill="white"
          fillRule="evenodd"
          d="M12 4a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-6v6a1 1 0 1 1-2 0v-6H5a1 1 0 1 1 0-2h6V5a1 1 0 0 1 1-1Z"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
};

const MenuButton = React.forwardRef<HTMLDivElement, ActionListItemProps>(
  ({ text, onClick, icon, active, selected, withCheckbox }, ref) => {
    return (
      <div
        ref={ref}
        className={`
        w-full flex flex-row px-3 py-4 gap-2 text-preset-3 
        font-bold
        cursor-pointer rounded-2xl hover:bg-neutral-50 hover:text-content-dim focus:bg-gray-50 z-10
        ${active ? 'bg-neutral-50 text-content-dim' : ''}`}
        onClick={onClick}
      >
        {withCheckbox && <Checkbox checked={selected} size="sm" />}
        {icon && (
          <div className="my-1 w-4 h-4">
            <Icon type={icon} className="w-4 h-4" />
          </div>
        )}
        {text}
      </div>
    );
  }
);

function useFunnelCopy(step: FunnelStep) {
  const field = FunnelFieldMapping[step];
  return useFieldCopy(field);
}

const FunnelStepMetrics: React.FC<{
  order: number;
  funnelStep: FunnelStep;
  open: boolean;
  addNewStep: () => void;
  closeFunnelStep: () => void;
}> = ({ order, funnelStep, open, addNewStep, closeFunnelStep }) => {
  const funnelCopy = useFunnelCopy(funnelStep);

  return (
    <>
      <div className="flex flex-row gap-2 items-center flex-1">
        <div className="select-none text-blue-600 bg-bg-primary--subtle text-center rounded-sm text-preset-1 w-4 shrink-0">
          {order + 1}
        </div>
        <div
          className={`flex-1 rounded-md text-preset-3 text-left py-2 whitespace-nowrap flex flex-row gap-2 items-center`}
          title={funnelCopy.tooltip}
        >
          {funnelCopy.heading}
          {funnelCopy.tooltip && <Tooltip color={'white'} tooltipText={funnelCopy.tooltip} />}
        </div>
      </div>

      <div className="flex flex-row gap-2">
        {open ? <ChevronDownIcon /> : <ChevronRightIcon />}

        <FunnelButtons menuOpen={open} addNewStep={addNewStep} closeFunnelStep={closeFunnelStep} />
      </div>
    </>
  );
};

const FunnelSectionLoader = () => (
  <>
    <div className="w-full py-4 rounded-xl bg-bg-muted/10 animate-pulse" />
    <div className="w-full py-6 rounded-xl bg-bg-muted/10 animate-pulse" />
  </>
);

export const FunnelSection: React.FC<{
  value: number;
  funnelStep: FunnelStep;
  order: number;
  funnelLength: number;
  percentage: number;
  relativePercentage: number;
  relativeNextPercentage: number;
  loading: boolean;
  setFunnelStep: (step: FunnelStep) => void;
  closeFunnelStep: () => void;
  addNewStep: () => void;
}> = ({
  order,
  funnelLength,
  value,
  funnelStep,
  percentage,
  relativePercentage,
  relativeNextPercentage,
  loading,
  setFunnelStep,
  closeFunnelStep,
  addNewStep,
}) => {
  const referenceElement = useRef<HTMLDivElement>(null);

  return (
    <Popover
      className={`relative group 
                  flex flex-1 flex-col 
                  h-120 min-w-[300px] mb-4
                  ${loading ? 'cursor-wait' : 'cursor-pointer'}`}
    >
      {({ open }) => (
        <Popover.Button
          as="div"
          className={`
          text-content-on-inverse 
          py-4 h-full rounded-3xl
          bg-white/0
          ${open ? 'bg-white/10' : 'group-hover:bg-white/5'}
          `}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-4 px-4">
              {loading ? (
                <FunnelSectionLoader />
              ) : (
                <>
                  <div
                    className="h-8 flex flex-row items-center justify-between gap-2"
                    ref={referenceElement}
                  >
                    <FunnelStepMetrics
                      order={order}
                      funnelStep={funnelStep}
                      open={open}
                      addNewStep={addNewStep}
                      closeFunnelStep={closeFunnelStep}
                    />
                  </div>
                  {!open && (
                    <div className="flex flex-row gap-4 h-10 pl-6 align-bottom mb-2">
                      <div className="text-preset-7 font-bold mt-auto ">
                        {Math.round(percentage)}%
                      </div>

                      <div className="text-preset-3 text-content-subtle mt-auto align-bottom">
                        {numberFormatter.format(value)}
                      </div>
                    </div>
                  )}
                  {open && (
                    <Popover.Panel
                      static
                      className="absolute z-10 bottom-2 w-fit overflow-y-scroll"
                      //@ts-ignore
                      style={{
                        width: referenceElement.current?.clientWidth,
                        maxHeight: '320px',
                      }}
                    >
                      {({ close }) => (
                        <div className="rounded-3xl flex flex-col p-4 shadow-xl z-10 opacity-100 h-[90%]">
                          {FunnelOptions.map(([funnelStep, fieldName], i) => {
                            const copy = useFieldCopy(fieldName);
                            return (
                              <MenuButton
                                key={`${funnelStep} - ${i}`}
                                text={copy.heading}
                                onClick={() => {
                                  setFunnelStep(funnelStep);
                                  close();
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                    </Popover.Panel>
                  )}
                </>
              )}
            </div>

            <div className={`flex h-60 items-center  ${open ? 'blur-2xl' : ''}`}>
              {!loading && (
                <FunnelVisualization
                  index={order}
                  funnelLength={funnelLength}
                  startPercentage={relativePercentage}
                  endPercentage={relativeNextPercentage}
                />
              )}
            </div>
          </div>
        </Popover.Button>
      )}
    </Popover>
  );
};
