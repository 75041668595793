import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import React from 'react';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import ApolloProvider, { ApolloProviderAuth0 } from './apollo';
import AuthProvider, { useAuth } from './utils/authentication';
import SessionProvider from './utils/session';
import { ThemeProvider } from 'theme-ui';
import theme from './uikit/theme';
import { Helmet } from 'react-helmet';
import { datadogLogs } from '@datadog/browser-logs';
import UnauthenticatedAppAuth0 from './UnauthenticatedAppAuth0';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useIsAuth0Mode } from './utils/auth0';
import { Loader } from '@pinwheel/origami';
import { Button } from './designs-v2/components/button';
import { Icon } from './designs-v2/primitives/icons';
import { useEffect } from 'react';

// TODO: move this into a separate file and create a separate error route
//      instead of returning this directly from <Auth0AppWrapper>.
const Auth0ErrorPage = () => {
  const { loginWithRedirect, error, user } = useAuth0();
  // TODO: Refreshing the page in this state causes an `invalid_state` Auth0 error. Look into whether we should
  //       manually call `history.replace('/')` here to clear the error query params in the URL added by Auth0.

  useEffect(() => {
    if (!error) {
      datadogLogs.logger.error('Error page rendered without an actual error', {
        email: user?.email,
        auth0UserId: user?.sub,
      });
      return;
    }
    if (error.message === 'Please verify your email before continuing.') {
      datadogLogs.logger.info('Email verification required', {
        email: user?.email,
        auth0UserId: user?.sub,
      });
    } else {
      datadogLogs.logger.error('Encountered unexpected error', {
        error: error.message,
        email: user?.email,
        auth0UserId: user?.sub,
      });
    }
  }, [error]);

  return (
    <div className="flex flex-col gap-3 items-center justify-center min-h-screen">
      <Icon type="info-circle" className="h-16 w-16" />
      {error?.message}
      <Button
        buttonType="primary"
        label="Continue"
        onClick={() => {
          void loginWithRedirect();
        }}
      />
    </div>
  );
};

const Auth0AppWrapper = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      // TODO: Update `NewLayout` to support both authenticated and unauthenticated cases
      <div className="flex items-center justify-center min-h-screen h-screen">
        <Loader type="logo" />
      </div>
    );
  }

  if (error) {
    return <Auth0ErrorPage />;
  }

  const { setCurrentWorkspaceId } = useAuth();
  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setCurrentWorkspaceId(null);
    }
  }, [isLoading, isAuthenticated, setCurrentWorkspaceId]);

  return isAuthenticated ? (
    <SessionProvider>
      <AuthenticatedApp />
    </SessionProvider>
  ) : (
    <UnauthenticatedAppAuth0 />
  );
};

const Children = ({ isAuth0Mode = false }) => {
  const auth = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    datadogLogs.logger.info('Route changed', { route: location.pathname });
  }, [location]);

  if (isAuth0Mode) {
    return <Auth0AppWrapper />;
  }

  return auth.accessToken ? (
    <SessionProvider>
      <AuthenticatedApp />
    </SessionProvider>
  ) : (
    <UnauthenticatedApp />
  );
};

const auth0ProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_TENANT_DOMAIN as string,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  authorizationParams: {
    redirect_uri: window.location.origin.replace('developer', 'dashboard'),
  },
};

function useDashboardRedirect() {
  useEffect(() => {
    // Redirect to dashboard.getpinwheel.com if we're on developer.getpinwheel.com
    if (window.location.href.includes('developer.')) {
      window.location.href = window.location.href.replace('developer.', 'dashboard.');
    }
  }, []);
}

const ProvidedApp: React.FC = () => {
  const isAuth0Mode = useIsAuth0Mode();
  useDashboardRedirect();

  // TODO: consolidate once SSO/Auth0 is released to all customers
  if (isAuth0Mode) {
    return (
      <ToastProvider placement="top-left">
        <Helmet>
          <link href={`/output.css?v=${process.env.GIT_HASH}`} rel="stylesheet" />
        </Helmet>
        <Auth0Provider {...auth0ProviderConfig} cacheLocation="localstorage">
          <AuthProvider>
            <ApolloProviderAuth0>
              <ThemeProvider theme={theme}>
                <Router>
                  <Children isAuth0Mode={true} />
                </Router>
              </ThemeProvider>
            </ApolloProviderAuth0>
          </AuthProvider>
        </Auth0Provider>
      </ToastProvider>
    );
  }

  return (
    <ToastProvider placement="top-left">
      <Helmet>
        <link href={`/output.css?v=${process.env.GIT_HASH}`} rel="stylesheet" />
      </Helmet>
      <AuthProvider>
        <ApolloProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <Children />
            </Router>
          </ThemeProvider>
        </ApolloProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default ProvidedApp;
