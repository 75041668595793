import { Badge } from '../../../../designs-v2/components/badge';
import { Card } from '../../../../designs-v2/components/card';
import { AnalyticsFieldName, useFieldCopy } from '../../copy/field-names';
import { numberFormatter } from '../../funnel/index';
import { BreakdownByPlatform } from '../../funnel/query';
import { FiltersInterface } from '../../filters/state';
import { Icon } from '../../../../designs-v2/primitives/icons';
import { useMemo, useState } from 'react';
import { ProgressBar } from '../../../../designs-v2/components/progress-bar/index';

function getProviderMetrics({
  has_attempted_login_user_count,
  login_success_user_count,
  dds_job_error_user_count,
  dds_job_success_user_count,
  is_dds_tof_user_count,
  dds_job_attempt_user_count,
}: BreakdownByPlatform) {
  if (is_dds_tof_user_count === 0) {
    return {
      topOfFunnelPercent: 0,
      errorRate: 0,
      loginPercent: 0,
      postLoginPercent: 0,
    };
  }

  return {
    topOfFunnelPercent: Math.round((dds_job_success_user_count / is_dds_tof_user_count) * 100),
    loginPercent: Math.round((login_success_user_count / has_attempted_login_user_count) * 100),
    postLoginPercent: Math.round((dds_job_success_user_count / dds_job_attempt_user_count) * 100),
    errorRate: Math.round((dds_job_error_user_count / dds_job_attempt_user_count) * 100),
  };
}

const ProviderRow: React.FC<{
  platform: BreakdownByPlatform;
  isLast: boolean;
  onClick: () => void;
}> = ({ platform, isLast, onClick }) => {
  const { topOfFunnelPercent, loginPercent, postLoginPercent, errorRate } = getProviderMetrics(
    platform
  );
  // If there is no image, just show an empty div with the same style as the image
  const image = platform.platform_logo_url ? <img className={'w-8 h-8 p-1 rounded-full'} src={platform.platform_logo_url} /> : <div className={'w-8 h-8 p-1 rounded-full'} />;
  return (
    <tr
      key={platform.platform}
      className={`h-16  hover:bg-bg-muted cursor-pointer ${
        isLast ? '' : 'border-b border-border-default'
      }`}
      onClick={onClick}
    >
      <td className="px-3 py-4 h-16 font-bold text-preset-2 flex flex-row gap-2 items-center">
        <div className="p-2 bg-bg-subtle rounded-full shrink-0">
          {image}
        </div>
        <div className="text-ellipsis whitespace-nowrap overflow-hidden shrink-1 w-[10rem]">
          {platform.platform || 'No platform selected'}
        </div>
        <Badge
          type={platform.platform_status === 'normal' ? 'success' : 'warning'}
          text={platform.platform_status === 'normal' ? 'Active' : 'Unusual'}
          tooltipText={platform.platform_status_tooltip ?? undefined}
        />
      </td>
      <td className="px-3 py-4">{numberFormatter.format(platform.is_dds_tof_user_count)}</td>
      <td className="px-3 py-4">
        {numberFormatter.format(platform.has_attempted_login_user_count)}
      </td>
      <td className="px-3 py-4">{numberFormatter.format(platform.login_success_user_count)}</td>
      <td className="px-3 py-4">{numberFormatter.format(platform.dds_job_success_user_count)}</td>
      <td className="px-3 py-4">
        <ProgressBar mode="success" percentage={topOfFunnelPercent} />
      </td>
      <td className="px-3 py-4">
        <ProgressBar mode="success" percentage={loginPercent} />
      </td>
      <td className="px-3 py-4">
        <ProgressBar mode="success" percentage={postLoginPercent} />
      </td>
      <td className="px-3 py-4">
        <ProgressBar mode="error" percentage={errorRate} />
      </td>
    </tr>
  );
};

type HeaderField = {
  subHeading: string;
  fieldName: AnalyticsFieldName;
};

const HeaderFields2: HeaderField[] = [
  {
    subHeading: 'Provider',
    fieldName: 'platformName',
  },
  {
    subHeading: 'User Count',
    fieldName: 'topOfFunnel',
  },
  {
    subHeading: 'User Count',
    fieldName: 'attemptedLogin',
  },
  {
    subHeading: 'User Count',
    fieldName: 'loginSuccess',
  },
  {
    subHeading: 'User Count',
    fieldName: 'successfulDDS',
  },
  {
    subHeading: 'Percentage',
    fieldName: 'conversionRate',
  },
  {
    subHeading: 'Percentage',
    fieldName: 'loginPercent',
  },
  {
    subHeading: 'Percentage',
    fieldName: 'postLoginPercent',
  },
  {
    subHeading: 'Percentage',
    fieldName: 'errorRate',
  },
];

function usePagination(total: number) {
  const [page, setPage] = useState(1);
  const numberOfPages = Math.ceil(total / PAGE_SIZE);

  return {
    page,
    numberOfPages,
    pageLeft: () => {
      if (page === 1) {
        return;
      }
      setPage(page - 1);
    },
    pageRight: () => {
      if (page === numberOfPages) {
        return;
      }
      setPage(page + 1);
    },
  };
}

const PAGE_SIZE = 10;

export const TrafficByProvider: React.FC<{
  data: BreakdownByPlatform[];
  setFilter: FiltersInterface['setFilter'];
}> = ({ data, setFilter }) => {
  const { page, numberOfPages, pageLeft, pageRight } = usePagination(data.length);

  const dataToShow = useMemo(() => {
    return data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }, [page, data]);

  return (
    <Card
      heading={<div className="text-preset-3 font-bold">Traffic by Provider</div>}
      body={
        <>
          <table className="table-auto w-full">
            <thead>
              <tr className="text-preset-1 text-left text-[#687083] border-b border-border-default">
                {HeaderFields2.map(({ fieldName, subHeading }) => {
                  const copy = useFieldCopy(fieldName);
                  return (
                    <th key={fieldName} className="p-3">
                      <div className="flex flex-row justify-start">
                        <div className="flex flex-col items-start whitespace-nowrap">
                          <div className="text-preset-1 text-content-disabled">{subHeading}</div>
                          <div className="text-preset-2 text-content-subtle">{copy.heading}</div>
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataToShow.map((platform, i) => (
                <ProviderRow
                  key={platform.platform}
                  platform={platform}
                  isLast={i === data.length - 1}
                  onClick={() => {
                    if (platform.platform_id) {
                      setFilter('platform', [platform.platform_id]);
                    }
                  }}
                />
              ))}
            </tbody>
          </table>
          {data.length ? (
            <div className="flex flex-row justify-between flex-1 mt-2">
              <div className="text-preset-1 bg-bg-muted p-1 rounded">
                {page} of {numberOfPages}
              </div>
              <div className="flex flex-row gap-2">
                <Icon
                  onClick={pageLeft}
                  type="arrow-left"
                  className="w-8 h-8 p-1 border border-border-default rounded-lg cursor-pointer"
                />
                <Icon
                  onClick={pageRight}
                  type="arrow-right"
                  className="w-8 h-8 p-1 border border-border-default rounded-lg cursor-pointer"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};
