import { ButtonType, NormalStates } from './types';
import React from 'react';
import { ButtonConfigs } from './consts';
import { IconType } from '../../primitives/icons/types';
import { Icon } from '../../primitives/icons';

type ButtonSize = 'sm' | 'md';

export function getSizeClassNames(size: ButtonSize): string[] {
  switch (size) {
    case 'sm':
      return ['py-1.5', 'px-3.5'];
    case 'md':
      return ['p-2.5'];
  }
}

type ButtonProps = {
  buttonType: ButtonType;
  label: React.ReactNode;
  size?: ButtonSize;
  isDisabled?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonType,
      label,
      isDisabled,
      onClick,
      iconLeft,
      iconRight,
      children,
      className,
      size = 'md',
    },
    ref
  ) => {
    function getVariantClassNames(): string[] {
      if (isDisabled) {
        return Object.values(ButtonConfigs[buttonType]['disabled']);
      }

      return NormalStates.flatMap((state) => Object.values(ButtonConfigs[buttonType][state]));
    }

    const classNames = [className, ...getSizeClassNames(size), ...getVariantClassNames()].join(' ');

    return (
      <button
        ref={ref}
        disabled={isDisabled}
        className={`${classNames} rounded-xl box-content`}
        onClick={onClick}
      >
        <div className="flex flex-row justify-between gap-2">
          {iconLeft && <Icon type={iconLeft} />}
          <div>{label}</div>
          {iconRight && <Icon type={iconRight} />}
        </div>
        {children}
      </button>
    );
  }
);
