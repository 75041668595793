export type Field = {
  heading: string;
  description?: string;
  tooltip?: string;
};


export const FieldNames = {
  defaultViewName: {
    heading: 'All Users',
  },

  // Overview Metrics
  topOfFunnel: {
    heading: 'Total Users',
    description: 'Number of users',
    tooltip: 'Number of unique users who have initiated a session for the given timeframe'
  },
  loginAttempts: {
    heading: 'Attempted Login',
    tooltip: 'Number of users who attempted to login'
  },
  totalSwitches: {
    heading: 'Total Switches',
    description: 'Number of switches',
    tooltip: 'The total number of successful switch jobs'
  },
  conversionRate: {
    heading: 'Conversion Rate',
    description: 'End-to-end conversion',
    tooltip: 'This is the rate of conversion with the numerator being the first step in the funnel and the denominator being the last'
  },

  // Platform Metrics
  platformName: {
    heading: 'Platform',
  },
  platformStatus: {
    heading: 'Status',
  },
  errorRate: {
    heading: 'Switch Error Rate',
    tooltip: 'The rate of errors for this platform within the given timeframe'
  },
  loginPercent: {
    heading: 'Login Success Rate',
    description: 'Sucess Rate',
  },
  postLoginPercent: {
    heading: 'Job Success Rate',
    description: 'Percentage Rate',
  },

  // Funnel Metrics
  viewedIntro: {
    heading: 'Viewed Intro',
    tooltip: 'Number of users who viewed the intro page. Note: This number may show as 0 depending on your Link Token settings; for example if the intro screen is skipped.'
  },
  viewedSearch: {
    heading: 'Viewed Search',
    tooltip: 'Number of users who viewed the search page. Note: This number may show as 0 depending on your Link Token settings; for example if search is skipped.'
  },
  selectedProvider: {
    heading: 'Selected Provider',
    tooltip: 'Number of users who selected a provider'
  },
  viewedLogin: {
    heading: 'Viewed Login',
    tooltip: 'Number of users who viewed the login page'
  },
  attemptedLogin: {
    heading: 'Attempted Login',
    tooltip: 'Number of users who attempted to login',
  },
  loginSuccess: {
    heading: 'Login Success',
    tooltip: 'Number of users who successfully logged in',
    description: 'Users who logged in',
  },
  viewedMFA: {
    heading: 'Viewed MFA',
    tooltip: 'Number of users who viewed the Multifactor Authentication page'
  },
  attemptedDDS: {
    heading: 'Attempted DDS',
    tooltip: 'Number of users who attempted to switch through their payroll provider'
  },
  successfulDDS: {
    heading: 'Switch Made',
    tooltip: 'Number of users who successfully switched direct deposit',
    description: 'Successful switches'
  }
} as const satisfies Record<string, Field>;


export type AnalyticsFieldName = keyof typeof FieldNames;

export function useFieldCopy(fieldName: AnalyticsFieldName): Field {
  return FieldNames[fieldName];
}
