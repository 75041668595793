/** @jsxImportSource theme-ui */
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { ThemeUIStyleObject } from 'theme-ui';
import { APIKey, User, Workspace } from '../../consts/models';
import { Button } from '../../uikit/buttons';
import { copyTextToClipboard } from '../../utils/general';
import { isUserAdminOrOwner } from '../../utils/identity';
import { isToday } from '../../utils/time';
import { ListRow } from '../layouts/ListRow';
import { SupportedIcons } from '../../uikit/Icon';

export const apiKeyRowStyle: ThemeUIStyleObject = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  border: '1px solid #DBE5F0',
  borderRadius: '4px',
  padding: '1em 1.2em',
};

interface IAPIKeyBoxProps {
  apiKey: APIKey;
  mode: string;
  user: User;
  workspace: Workspace;
  setKeyToView: React.Dispatch<React.SetStateAction<APIKey | null>>;
  setKeyToRevoke: React.Dispatch<React.SetStateAction<APIKey | null>>;
}

export const APIKeyBox: React.FC<IAPIKeyBoxProps> = ({
  apiKey,
  mode,
  user,
  workspace,
  setKeyToView,
  setKeyToRevoke,
}) => {
  const { addToast } = useToasts();

  // onClick handler function for the copy button
  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        addToast('Copied!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shortenAPIKeyForDisplay = (apiKey: string) => {
    const keylen = apiKey.length;
    const charsShown = 6;
    return apiKey.slice(0, charsShown) + '...' + apiKey.slice(keylen - charsShown, keylen);
  };

  const getTimeRemaining = (timestamp: Date): { hours: number; minutes: number } => {
    const difference = timestamp.getTime() - new Date().getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    return { hours, minutes };
  };

  const pluralize = (noun: string, count: number) => `${count} ${noun}${count !== 1 ? 's' : ''}`;

  const keyDetailString = (apiKey: APIKey) => {
    const createdAt = apiKey.createdAt && new Date(apiKey.createdAt);
    const createdAtString =
      createdAt &&
      (isToday(createdAt)
        ? 'today'
        : createdAt.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }));
    const createdByString = apiKey.createdByEmail ? `by ${apiKey.createdByEmail}` : '';
    const lastUsedAt = apiKey.lastUsedAt && new Date(apiKey.lastUsedAt);
    const lastUsedString =
      lastUsedAt &&
      (isToday(lastUsedAt)
        ? 'today'
        : lastUsedAt.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }));
    const inactiveAt = apiKey.inactiveAt && new Date(apiKey.inactiveAt);
    const revokedAt = apiKey.revokedAt && new Date(apiKey.revokedAt);
    let inactiveMsg;
    if (revokedAt) {
      let revokedAtString;
      if (isToday(revokedAt)) {
        revokedAtString = 'today';
      } else {
        revokedAtString = revokedAt.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      }
      inactiveMsg =
        `Revoked ${revokedAtString}` +
        (apiKey.revokedByEmail ? ` by ${apiKey.revokedByEmail}` : '');
    } else if (inactiveAt) {
      let inactiveAtString;
      if (isToday(inactiveAt)) {
        if (apiKey.isActive) {
          const { hours, minutes } = getTimeRemaining(inactiveAt);
          inactiveAtString =
            hours == 0 ? `in ${pluralize('minute', minutes)}` : `in ${pluralize('hour', minutes)}`;
        } else {
          inactiveAtString = 'today';
        }
      } else {
        inactiveAtString = inactiveAt.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      }
      if (apiKey.isActive) {
        inactiveMsg = `Expires ${inactiveAtString}`;
      } else {
        inactiveMsg = `Expired ${inactiveAtString}`;
      }
    }
    return (
      <span>
        Created {createdAtString} {createdByString}
        {apiKey.isActive ? (
          lastUsedString ? (
            <span>
              &nbsp;·&nbsp;Last used <strong>{lastUsedString}</strong>
            </span>
          ) : (
            <span>&nbsp;·&nbsp;Not in use</span>
          )
        ) : null}
        {inactiveMsg ? <span>&nbsp;·&nbsp;{inactiveMsg}</span> : null}
      </span>
    );
  };

  const iconForKey = (key: APIKey): SupportedIcons => {
    // revoked?
    if (!key.isActive) {
      return 'statusRevoked';
    }
    // keys that haven't been used in a week are stale
    if (key.lastUsedAt) {
      const lastUsedAt = new Date(key.lastUsedAt);
      const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      if (new Date().getTime() - lastUsedAt.getTime() > weekInMilliseconds) {
        return 'statusStale';
      }
    }
    // set to expire in the future?
    if (key.inactiveAt) {
      return 'statusAutoRevoke';
    }
    return 'statusActive';
  };

  const icon = iconForKey(apiKey);

  return (
    <ListRow
      icon={icon}
      title={shortenAPIKeyForDisplay(apiKey.apiKey)}
      subtitle={keyDetailString(apiKey)}
      strikethroughTitle={!apiKey.isActive}
      actionButtons={
        <>
          {mode === 'sandbox' && (
            <Button
              icon="secret"
              onClick={() => {
                setKeyToView(apiKey);
              }}
            >
              View Secret
            </Button>
          )}
          <Button icon="copy" onClick={() => handleCopyClick(apiKey.apiKey)}>
            Copy
          </Button>
          {isUserAdminOrOwner(workspace, user) && (
            <Button
              icon="revoke"
              onClick={() => {
                setKeyToRevoke(apiKey);
              }}
              sx={{
                '&:active': {
                  backgroundColor: 'danger',
                  border: 'danger',
                  color: 'white',
                },
                '&:focus': {
                  border: 'danger',
                },
              }}
            >
              Revoke
            </Button>
          )}
        </>
      }
    />
  );
};
