import { Icon } from '../../../designs-v2/primitives/icons';

import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { Button } from '../../../designs-v2/components/button';
import { useSavedViews } from '../saved-views/index';
import React from 'react';
import { InternalFiltersState } from '../filters/state';

const SaveViewModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
}> = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog className="relative z-50" open={open} onClose={onClose}>
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded bg-white p-4 flex flex-col gap-4">
          <Dialog.Title className="text-preset-5 font-bold self-center">
            Save your view
          </Dialog.Title>
          <Dialog.Description>Save this view for your team in the future</Dialog.Description>

          <div>
            <input
              className="border border-border-default p-4 rounded-xl"
              id="enterViewName"
              type="text"
              placeholder="Your Name Here"
            />
          </div>

          <div className="flex flex-row gap-4 justify-end">
            <Button buttonType="primary" label="Save View" onClick={() => onSubmit('dsd')} />
            <Button buttonType="outline" label="Cancel" onClick={onClose} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export const OverviewHeader: React.FC<{ filters: InternalFiltersState }> = ({ filters }) => {
  let [saveViewModalOpen, setSaveViewModalOpen] = useState(false);
  const { createView, recentlyUpdated, resetNotification } = useSavedViews();

  return (
    <>
      {/* compact with -my-2 */}
      <div className="flex flex-row justify-between -my-2">
        <div
          className="flex-1 items-center text-preset-4 font-bold flex flex-row"
          onClick={resetNotification}
        >
          Top of Funnel
          {/* <Icon className={recentlyUpdated ? 'animate-ping' : ''} type="caret-down" /> */}
        </div>

        <div>
          {/* Disabling until implemented on backend */}
          {false && (
            <Button buttonType="primary" label="Save" onClick={() => setSaveViewModalOpen(true)} />
          )}
        </div>
      </div>

      <SaveViewModal
        onSubmit={(name: string) => createView(name, filters)}
        open={saveViewModalOpen}
        onClose={() => setSaveViewModalOpen(false)}
      />
    </>
  );
};
