import { useQuery } from '@apollo/client';
import { InternalFiltersState } from '../filters/state';
import { GetFunnelData, GetBreakdowns } from '../graphql/getFunnelData';
import { FunnelStep } from '../types';

export type Breakdowns = {
  by_platform?: BreakdownByPlatform[];
  by_date?: BreakdownByDate[];
};

export type BreakdownByDate = BreakdownBase & {
  date: string;
  tooltip: string;
};

export type BreakdownBase = {
  dds_job_attempt_user_count: number;
  dds_job_success_user_count: number;
  has_attempted_login_user_count: number;
  has_intro_user_count: number;
  has_login_impression_user_count: number;
  has_search_impression_user_count: number;
  has_selected_platform_user_count: number;
  has_viewed_dda_page: number;
  has_viewed_mfa_user_count: number;
  is_dds_tof_user_count: number;
  login_success_user_count: number;
  dds_job_error_user_count: number;
};

export type BreakdownByPlatform = BreakdownBase & {
  platform: string;
  platform_id: string;
  tooltip: string;
  platform_logo_url: string;
  platform_picker_logo_url: string;
  platform_status: string;
  platform_status_tooltip: string;
};

export type ApiResponse = {
  breakdowns: Breakdowns;
  funnel: FunnelResponse;
};

export type FunnelResponse = {
  [key in FunnelStep]?: number;
};

function convertResponse(data: any): ApiResponse {
  const result = data?.getFunnelAnalytics;

  return {
    breakdowns: result?.breakdowns?.breakdown || {},
    funnel: result?.funnel || {},
  };
}

type FunnelQueryResult = {
  loading: boolean;
  funnel: FunnelResponse;
  breakdowns: Breakdowns;
  refetch: () => void;
};

export function useFunnelQuery(filters: InternalFiltersState): FunnelQueryResult {
  const { timeframe, channel, product, platform } = filters;

  const { data, loading, refetch, client } = useQuery(GetFunnelData, {
    variables: {
      channel,
      product,
      platform,
      start_date: timeframe?.startDate,
      end_date: timeframe?.endDate,
    },
  });

  if (loading) {
    return {
      loading: true,
      funnel: {},
      breakdowns: {},
      refetch,
    };
  }

  const { funnel, breakdowns } = convertResponse(data);

  return {
    funnel,
    breakdowns,
    loading: false,
    refetch,
  };
}

export const useBreakdownsData = (
  filters: InternalFiltersState
): {
  loading: boolean;
  breakdowns: Breakdowns;
  refetch: () => void;
} => {
  const { timeframe, channel, product, platform } = filters;

  const { data, loading, refetch } = useQuery(GetBreakdowns, {
    variables: {
      channel,
      product,
      platform,
      start_date: timeframe?.startDate,
      end_date: timeframe?.endDate,
    },
  });

  if (loading) {
    return {
      loading: true,
      breakdowns: {},
      refetch,
    };
  }

  const { breakdowns } = convertResponse(data);

  return {
    breakdowns,
    loading: false,
    refetch,
  };
};
