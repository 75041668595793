/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { get } from 'lodash';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { DevDashboardError } from '../consts/DevDashboardError';
import { REMOVE_MEMBER } from '../graphql/mutations/remove-member';
import { WORKSPACE_MEMBERS } from '../pages/teams/TeamMembers';
import Modal from '../uikit/Modal';
import { Button, DangerButton } from '../uikit/buttons';

const RemoveWorkspaceMemberModal: React.FC<{
  memberToRemove: any;
  onRequestClose: () => any;
}> = ({ memberToRemove, onRequestClose }) => {
  const { addToast } = useToasts();
  const [removeMember] = useMutation(REMOVE_MEMBER);
  const [loading, setLoading] = React.useState(false);

  const handleRemove = async () => {
    setLoading(true);
    try {
      const result = await removeMember({
        variables: {
          userId: memberToRemove.user.id,
        },
        refetchQueries: [{ query: WORKSPACE_MEMBERS }],
        awaitRefetchQueries: true,
      });
      const errorMsg = get(result, 'data.removeMember.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.removeMember.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }
      addToast('Member removed successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      const errorMsg = (error as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', { operation: 'removeMember', errorMsg });
      addToast(errorMsg, { appearance: 'error', autoDismiss: true });
    } finally {
      setLoading(false);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={Boolean(memberToRemove)}
      onRequestClose={onRequestClose}
      width="30rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div sx={{ fontSize: 1, fontWeight: 500, marginBottom: '1rem' }}>Remove user from team</div>
      <div sx={{ color: 'textSecondary' }}>Are you sure you want to remove this user?</div>

      <div sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
        <Button type="button" onClick={onRequestClose}>
          Cancel
        </Button>
        <DangerButton disabled={loading} onClick={handleRemove}>
          {loading ? 'Removing user...' : 'Remove User'}
        </DangerButton>
      </div>
    </Modal>
  );
};

export default RemoveWorkspaceMemberModal;
