/** @jsxImportSource theme-ui */
import React from 'react';
import { jsx, ThemeUIStyleObject } from 'theme-ui';

interface ICheckboxProps {
  onChange?: () => any;
  label?: string;
  isSelected?: boolean;
  customSx?: ThemeUIStyleObject;
}

const Checkbox: React.FC<ICheckboxProps> = ({ label, isSelected, onChange, customSx }) => (
  <div
    sx={{
      ...(customSx != null ? customSx : {}),
    }}
  >
    <label>
      <input
        type="checkbox"
        name={label}
        checked={isSelected}
        onChange={(e) => onChange && onChange()}
        sx={{
          marginRight: '.5rem',
          position: 'relative',
          top: '1px',
        }}
      />
      {label}
    </label>
  </div>
);

export default Checkbox;
