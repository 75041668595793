import React from 'react';
import { useHistory } from 'react-router';
import { Card } from '../../designs-v2/components/card';
import { colorTokens } from '../../designs-v2/primitives/colors';
import { GettingStartedDocUrl } from './constants';

const ExternalLink: React.FC<{ text: string; href: string }> = ({ text, href }) => (
  <a className="flex flex-row items-center gap-1 py-1 text-content-primary" href={href}>
    {text}
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
      <path
        fill={colorTokens['content-primary']}
        fillRule="evenodd"
        d="M7 8a1 1 0 0 1 0-2h10a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L14.586 8H7Z"
        clipRule="evenodd"
      />
    </svg>
  </a>
);

const ApiDocumentationTile: React.FC = () => {
  return (
    <Card
      className="flex-1"
      heading={<h3 className="text-preset-3 font-bold">API Documentation</h3>}
      body={
        <div className="flex flex-col gap-8">
          <p className="text-preset-3 text-content-muted">
            Learn more about our API Docs and product.
          </p>
          <div>
            <ExternalLink text="View Docs" href={GettingStartedDocUrl} />
          </div>
        </div>
      }
    />
  );
};

const TestConsoleTile: React.FC = () => {
  const history = useHistory();

  return (
    <Card
      className="flex-1"
      heading={<h3 className="text-preset-3 font-bold">Test Console</h3>}
      body={
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-8">
            <p className="text-preset-3 text-content-muted">
              Test API configurations with the Test Console
            </p>
            <div>
              <button
                className="text-content-primary"
                onClick={() => history.push('/test-console')}
              >
                Build with Console
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

const InviteTeammatesTile: React.FC = () => {
  const history = useHistory();

  return (
    <Card
      className="flex-1"
      heading={<h3 className="text-preset-3 font-bold">Invite Teammates</h3>}
      body={
        <div className="flex flex-col gap-8">
          <p className="text-preset-3 text-content-muted">View team status or manage invitations</p>

          <div>
            <button className="text-content-primary" onClick={() => history.push('/team')}>
              Manage Team
            </button>
          </div>
        </div>
      }
    />
  );
};

const ActionTiles = [ApiDocumentationTile, TestConsoleTile, InviteTeammatesTile];

export const GetStartedSection = () => (
  <div className="flex flex-col gap-6">
    <div className="flex flex-row justify-between">
      <h1 className="text-preset-5 font-bold">Get Started</h1>
      <ExternalLink text="View Documentation" href={GettingStartedDocUrl} />
    </div>
    <div className="flex flex-row gap-4">
      {ActionTiles.map((Tile, i) => (
        <Tile key={`tile-${i}`} />
      ))}
    </div>
  </div>
);
