/** @jsxImportSource theme-ui */

import Select, { SelectChangeValue } from '../../uikit/Select';
import React from 'react';
import { getErrorStateCss } from '../TestConsole/form-helpers';
import { ErrorMessage, getDisabledClass } from './utils';
import { Field } from '../../uikit/forms';

interface InputSelectProps {
  error?: string;
  name: string;
  required?: boolean;
  options: any[];
  value?: SelectChangeValue;
  isClearable?: boolean;
  isSearchable?: boolean;
  onBlur?: any;
  onChangeHandler?: (value: string) => void;
  onInputChange?: (input: string) => void;
  noOptionsMessage?: () => string;
  customStyles?: any;
  disabled?: boolean;
  label: string;
}

interface FormFieldProps {
  name: string;
  label: string;
  children?: React.ReactNode;
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

const FormField = ({ name, label, children, required, error, disabled }: FormFieldProps) => (
  <Field
    className={getDisabledClass(disabled)}
    customSx={{ root: getErrorStateCss(error) }}
    label={label}
    htmlFor={name}
    required={required}
    customStyles={getErrorStateCss(error)}
  >
    {children}
  </Field>
);

export const InputSelect = (props: InputSelectProps) => {
  const { name, required, error, disabled, label, ...rest } = props;
  return (
    <FormField label={label} name={name} required={required} disabled={disabled}>
      <Select id={name} name={name} isDisabled={disabled} {...rest} />
      <ErrorMessage error={error} />
    </FormField>
  );
};

export const MultiSelect = (props: InputSelectProps) => {
  const { name, required, error, disabled, label, ...rest } = props;

  return (
    <FormField label={label} name={name} required={required} disabled={disabled}>
      <Select isMulti id={name} name={name} isDisabled={disabled} {...rest} />
      <ErrorMessage error={error} />
    </FormField>
  );
};

// Use as a filler for the last
export const EmptyBox = () => {
  return <div />;
};
