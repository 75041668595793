import { Card } from '../../../../designs-v2/components/card';
import { Icon } from '../../../../designs-v2/primitives/icons';
import { useMemo, useState } from 'react';
import { Report } from '../../types';
import { Button } from '../../../../designs-v2/components/button';
import { useLazyQuery } from '@apollo/client';
import { GetReportURL } from '../../graphql/getReportURL';
import { saveAs } from 'file-saver';

const DownloadButton = ({ reportId, reportName }) => {
  const [downloadStatus, setDownloadStatus] = useState('idle');
  const [getReportUrl, { loading, error, data }] = useLazyQuery(GetReportURL);

  const handleDownload = async () => {
    if (downloadStatus === 'loading') {
      return;
    }
    setDownloadStatus('loading');
    try {
      // Fetch the URL only when the button is clicked
      const { data: urlData } = await getReportUrl({ variables: { reportId } });
      const url = urlData?.reportURL?.url;

      if (!url) {
        throw new Error('Failed to get download URL');
      }

      setDownloadStatus('success');
      window.location.href = url;
    } catch (err) {
      console.error('Download failed:', err);
      setDownloadStatus('error');
    }
  };

  return (
    <div>
      <Button
        buttonType="primary"
        label={downloadStatus === 'loading' ? 'Preparing Download...' : 'Download Report'}
        onClick={handleDownload}
        isDisabled={downloadStatus === 'loading'}
      />
      {downloadStatus === 'error' && <p>Download failed. Please try again.</p>}
    </div>
  );
};

const HeaderFields: string[] = ['Name', 'Data Start Time', 'Data End Time', 'Actions'];

const ReportRow: React.FC<{
  report: Report;
  isLast: boolean;
}> = ({ report, isLast }) => {
  return (
    <tr
      key={report.id}
      className={`h-16  hover:bg-bg-muted cursor-pointer ${
        isLast ? '' : 'border-b border-border-default'
      }`}
    >
      <td className="px-3 py-4">{report.name}</td>
      <td className="px-3 py-4">
        {new Date(report.startTime).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </td>
      <td className="px-3 py-4">
        {new Date(report.endTime).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </td>
      <td className="px-3 py-4">
        <DownloadButton reportId={report.id} reportName={report.name} />
      </td>
    </tr>
  );
};

function usePagination(total: number) {
  const [page, setPage] = useState(1);
  const numberOfPages = Math.ceil(total / PAGE_SIZE);

  return {
    page,
    numberOfPages,
    pageLeft: () => {
      if (page === 1) {
        return;
      }
      setPage(page - 1);
    },
    pageRight: () => {
      if (page === numberOfPages) {
        return;
      }
      setPage(page + 1);
    },
  };
}

const PAGE_SIZE = 25;

export const Reports: React.FC<{
  data: Report[];
}> = ({ data }) => {
  const { page, numberOfPages, pageLeft, pageRight } = usePagination(data.length);

  const dataToShow = useMemo(() => {
    return data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }, [page, data]);

  return (
    <Card
      heading={<div className="text-preset-3 font-bold">Reports</div>}
      body={
        <>
          <table className="table-auto w-full">
            <thead>
              <tr className="text-preset-1 text-left text-[#687083] border-b border-border-default">
                {HeaderFields.map((heading) => {
                  return (
                    <th key={heading} className="p-3">
                      <div className="flex flex-row justify-start">
                        <div className="flex flex-col items-start whitespace-nowrap">
                          <div className="text-preset-1 text-content-disabled">{heading}</div>
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataToShow.map((report, i) => (
                <ReportRow report={report} isLast={i === data.length - 1} />
              ))}
            </tbody>
          </table>
          {data.length ? (
            <div className="flex flex-row justify-between flex-1 mt-2">
              <div className="text-preset-1 bg-bg-muted p-1 rounded">
                {page} of {numberOfPages}
              </div>
              <div className="flex flex-row gap-2">
                <Icon
                  onClick={pageLeft}
                  type="arrow-left"
                  className="w-8 h-8 p-1 border border-border-default rounded-lg cursor-pointer"
                />
                <Icon
                  onClick={pageRight}
                  type="arrow-right"
                  className="w-8 h-8 p-1 border border-border-default rounded-lg cursor-pointer"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};
