import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AUTH0_MODE_ENABLED_LOCAL_STORAGE_KEY = 'pinwheelAuth0ModeEnabled';

/**
 * Context: https://www.notion.so/pinwheelapi/SSO-Auth-Technical-Details-301743a9e2af45069663c1b6a5eebdac
 * Before SSO with Auth0 is fully built out and released, during the implementation and testing phases, we want a way to
 * enable the Auth0 flow without affecting the default user flow. This is a temporary hook which provides a way to
 * manually set and unset whether the app should have the Auth0 flow enabled.
 * - To enable Auth0 mode: pass a query param `auth0Enabled=true`. Note that the mode state will be stored in local
 *   storage, so this query param only has to be passed once, and it will persist until the mode is manually disabled.
 * - To disable Auth0 mode: explicitly pass a query param `auth0Enabled=false`. Note that since the mode state is stored
 *   in local storage, not passing a query param does not equate to disabling the mode.
 */
export const useIsAuth0Mode = () => {
  return true;
  // const initialLocalStorageValue = useMemo(
  //   () => !!localStorage.getItem(AUTH0_MODE_ENABLED_LOCAL_STORAGE_KEY),
  //   []
  // );
  // const [auth0ModeEnabled, setAuth0ModeEnabled] = useState<boolean>(initialLocalStorageValue);
  //
  // const queryParamValue = useMemo(() => {
  //   return new URLSearchParams(window.location.search).get('auth0Enabled');
  // }, [window.location.search]);
  //
  // useEffect(() => {
  //   if (queryParamValue === 'true') {
  //     localStorage.setItem(AUTH0_MODE_ENABLED_LOCAL_STORAGE_KEY, 'true');
  //     setAuth0ModeEnabled(true);
  //   } else if (queryParamValue === 'false') {
  //     localStorage.removeItem(AUTH0_MODE_ENABLED_LOCAL_STORAGE_KEY);
  //     setAuth0ModeEnabled(false);
  //   }
  // }, [queryParamValue]);
  //
  // // Always enable on staging and local
  // const isStagingOrLocal =
  //   window.location.host.includes('staging') || window.location.host.includes('localhost');
  // return isStagingOrLocal ? true : auth0ModeEnabled;
};
