export type ButtonType = 'primary' | 'tonal' | 'outline' | 'text_primary' | 'text_tonal';

export type ButtonSize = 'default' | 'small' | 'compact';

export type ButtonState = 'default' | 'hover' | 'focus' | 'active' | 'disabled';

export const NormalStates: ButtonState[] = ['default', 'hover', 'focus', 'active'];

export type ColorConfig = {
  background: string;
  textColor: string;
  borderColor: string;
  elevation?: string;
};

export type ButtonConfig = Record<ButtonState, ColorConfig>;
