import { createContext } from 'react';

interface ICtx {
  selectedApiKey?: string;
  setSelectedApiKey?: (api: string) => void;
  currentWorkspaceId?: string;
  setCurrentWorkspaceId?: (workspaceId: string) => void;
}

export const AppContext = createContext<ICtx>({});

export default AppContext.Provider;
