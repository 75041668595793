import { InternalFiltersState } from '../../filters/state';
import { useQuery } from '@apollo/client';
import { GetDdsAnalytics } from '../../graphql/getDdsAnalytics';

export type SwitchTypeResponse = {
  switch_type: string;
  total_switch_user_count: number;
}[];

export type JobOutcomeResponse = {
  job_outcome: 'error' | 'success' | 'pending';
  product: string; // TODO: make this an enum
  total_user_count: number;
}[];

export type JobErrorResponse = {
  job_error_code: string;
  total_user_count: number;
}[];

function parseJobErrors(data: any): JobErrorResponse {
  const jobErrors: JobErrorResponse = data?.getDdsAnalytics?.job_errors ?? [];
  if (jobErrors.length <= 1) {
    return jobErrors;
  }
  const sortedCopy = [...jobErrors];
  sortedCopy.sort((a, b) => b.total_user_count - a.total_user_count);

  return sortedCopy;
}

export function useDDSQuery(filters: InternalFiltersState) {
  const { platform, timeframe, product, channel } = filters;
  const { data, loading, refetch, error } = useQuery(GetDdsAnalytics, {
    variables: {
      platform,
      channel,
      product,
      start_date: timeframe?.startDate,
      end_date: timeframe?.endDate,
    },
  });

  if (loading) {
    return {
      loading,
      data: undefined,
    };
  }

  if (error) {
    console.error('failed to fetch DDS analytics: ' + error.message);
  }

  const switchTypes: SwitchTypeResponse = data?.getDdsAnalytics?.switch_types ?? [];
  const jobOutcomes: JobOutcomeResponse = data?.getDdsAnalytics?.job_outcomes ?? [];

  return { switchTypes, jobOutcomes, jobErrors: parseJobErrors(data), loading };
}
