/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import Card from '../uikit/Card';
import { PrimaryButton } from '../uikit/buttons';
import Input from '../uikit/Input';
import { Field } from '../uikit/forms';
import { get } from 'lodash';
import React, { FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router';

import { useAuth } from '../utils/authentication';
import { DevDashboardError } from '../consts/DevDashboardError';
import { REQUEST_PASSWORD_RESET_EMAIL } from '../graphql/mutations/request-password-reset';
import logo from '../images/pinwheel-logo.svg';
import { User } from '../consts/models';
import { useSession } from '../utils/session';
import { getCurrentWorkspace } from '../utils/identity';
import { isEmailValid } from '../utils/validations';

const RequestPasswordResetEmail: React.FC<RouteComponentProps> = ({ history }) => {
  const [error, setError] = React.useState<null | string>(null);
  const [email, setEmail] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [requestPasswordResetEmail] = useMutation(REQUEST_PASSWORD_RESET_EMAIL);
  const [loading, setLoading] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);
  const auth = useAuth();
  const session = useSession();

  const onRecaptchaChangeHandler = (token: string | null) => {
    setRecaptchaToken(!token ? '' : token);
  };

  const user = session.data as User;
  const workspaces = get(user, 'workspaces', []);
  const currentWorkspace = getCurrentWorkspace(workspaces, auth.currentWorkspaceId);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await requestPasswordResetEmail({
        variables: {
          email,
        },
        context: {
          recaptchaToken,
        },
      });
      const errorMsg = get(result, 'data.requestPasswordResetEmail.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.requestPasswordResetEmail.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }

      datadogLogs.logger.info('Password reset request succeeded', {
        trackingIds: {
          email,
          workspaceName: currentWorkspace?.name,
          workspaceId: currentWorkspace?.id,
        },
      });

      setEmailSent(true);
    } catch (caughtError) {
      const errorMsg = (caughtError as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', {
        operation: 'requestPasswordResetEmail',
        errorMsg,
      });
      setError(errorMsg);

      datadogLogs.logger.info('Password reset request failed with error', {
        error: (caughtError as Error).message,
        trackingIds: {
          email,
          workspaceName: currentWorkspace?.name,
          workspaceId: currentWorkspace?.id,
        },
      });
    } finally {
      onRecaptchaChangeHandler(null);
      (window as any).grecaptcha.reset(recaptchaRef.current?.getWidgetId());
      setLoading(false);
    }
  };

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        <div sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img alt="Pinwheel" src={logo} />
        </div>
        <Card
          sx={{
            width: '25rem',
          }}
        >
          {emailSent ? (
            <div>
              <div sx={{ marginBottom: '2rem' }}>
                If this email address matches with a user in our records, you should receive an
                email with password reset instructions.
              </div>

              <PrimaryButton onClick={() => history.push('/login')} block>
                Back to Login
              </PrimaryButton>
            </div>
          ) : (
            <div>
              <div sx={{ color: 'textSecondary', marginBottom: '2rem' }}>
                To reset your password, enter the email associated with your account.
              </div>

              <form onSubmit={handleSubmit}>
                <Field sx={{ marginBottom: '2rem' }} label="Email" htmlFor="email">
                  <Input
                    id="email"
                    onChange={(e) => {
                      setEmail(e);
                    }}
                    value={email}
                    type="email"
                    required
                  />
                </Field>

                {error && (
                  <div
                    sx={{
                      marginTop: '-0.5rem',
                      color: 'danger',
                      marginBottom: '1rem',
                    }}
                  >
                    {error}
                  </div>
                )}

                <div
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'column',
                    width: '100%',
                    margin: error ? '1rem 0 2rem 0' : '2rem 0',
                  }}
                >
                  <div sx={{ width: 'auto', margin: 'auto' }}>
                    <ReCAPTCHA
                      sitekey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
                      onChange={onRecaptchaChangeHandler}
                      onExpired={() => setRecaptchaToken(null)}
                      onErrored={() => setRecaptchaToken(null)}
                      ref={recaptchaRef}
                    />
                  </div>
                </div>

                <PrimaryButton
                  block
                  sx={{ marginTop: '2rem' }}
                  type="submit"
                  disabled={!recaptchaToken || !isEmailValid(email)}
                >
                  {loading ? 'Submitting...' : 'Continue'}
                </PrimaryButton>
              </form>
            </div>
          )}

          {/* Disabled the link to singup since this is disabled for now */}
          {/* <div
            sx={{
              padding: "1.5rem",
              paddingBottom: "0rem",
              margin: "0rem -1.5rem",
              marginTop: "1.5rem",
              borderTop: "gray",
              textAlign: "center",
            }}
          >
            New to Pinwheel? <Link to="/signup">Sign up</Link>
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default RequestPasswordResetEmail;
