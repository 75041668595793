import { ApolloError, useQuery } from '@apollo/client';
import { get } from 'lodash';
import React from 'react';
import { useAuth } from './authentication';
import { SELF } from '../graphql/queries/self';
import { Membership, User, Workspace } from '../consts/models';

interface ISessionWorkspaceMembership {
  [key: string]: string;
}

export interface ISession {
  data?: User;
  memberships?: ISessionWorkspaceMembership;
  loading?: boolean;
  error?: ApolloError;
}

const SessionCtx = React.createContext<ISession & { refreshSession: () => void }>({
  refreshSession: () => undefined,
});

const getUserMemberships = (user: User) => {
  return get(user, 'self.memberships', []).reduce(
    (userMemberships: ISessionWorkspaceMembership, membership: Membership) => ({
      ...userMemberships,
      [membership.workspaceId]: membership.role,
    }),
    {}
  );
};

const Provider: React.FC = ({ children }) => {
  const { currentWorkspaceId, accessToken, setAccessToken, setCurrentWorkspaceId } = useAuth();

  const { data, error, loading, refetch } = useQuery(SELF, {
    fetchPolicy: 'no-cache',
  });

  const [session, setUserSessionData] = React.useState<ISession>({
    data: get(data, 'self', undefined),
    memberships: getUserMemberships(data),
    error,
    loading,
  });

  React.useEffect(() => {
    if (accessToken != null) {
      void refetch();
    } else {
      setAccessToken!(null);
    }
  }, [accessToken, refetch, setAccessToken]);

  React.useEffect(() => {
    if (error != null) {
      setAccessToken!(null);
      return;
    }
    setUserSessionData({
      data: get(data, 'self', undefined),
      memberships: getUserMemberships(data),
      error,
      loading,
    });
  }, [
    currentWorkspaceId,
    data,
    error,
    loading,
    setAccessToken,
    setCurrentWorkspaceId,
    setUserSessionData,
  ]);

  React.useEffect(() => {
    if (session.loading || !session.data) {
      return;
    }
    const workspaces = get(session.data, 'self.workspaces', []);
    const isWorkspaceInList =
      workspaces.length > 0 && currentWorkspaceId != null
        ? workspaces.find((workspace: Workspace) => workspace.id === currentWorkspaceId) != null
        : false;
    if ((!currentWorkspaceId || !isWorkspaceInList) && workspaces.length > 0) {
      setCurrentWorkspaceId(workspaces[0]);
    }
  }, [currentWorkspaceId, session, setCurrentWorkspaceId]);

  return (
    <SessionCtx.Provider
      value={{
        ...session,
        refreshSession: () => refetch(),
      }}
    >
      {children}
    </SessionCtx.Provider>
  );
};

export const useSession = () => React.useContext(SessionCtx);

export default Provider;
