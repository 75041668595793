/** @jsxImportSource theme-ui */
import React from 'react';
import { MemberRole } from '../../consts/models';
import RadioGroup, { RadioOption } from '../../uikit/RadioGroup';

export const TeamsRadioGroup: React.FC<{
  role: MemberRole | undefined;
  setRole: (role: MemberRole) => void;
}> = ({ role, setRole }) => {
  return (
    <RadioGroup
      name="role"
      value={role || null}
      onChange={(newRole) => setRole(newRole as MemberRole)}
      sx={{
        marginTop: '1rem',
        marginBottom: '2rem',
      }}
    >
      <RadioOption
        id="radio-admin"
        label="Administrator"
        help="Best for business owners and company administrators"
        value="administrator"
        required
      />
      <RadioOption
        id="radio-developer"
        label="Developer"
        help="Best for developers or people primarily using the API"
        value="developer"
      />
      <RadioOption
        id="radio-analyst"
        label="Analyst"
        help="Read-only role for viewing analytics data and dashboards"
        value="analyst"
      />
    </RadioGroup>
  );
};
