import Datepicker from 'react-tailwindcss-datepicker';
import { getDateMinusDays } from './state';

export type DateRange = {
  startDate: string;
  endDate: string;
};

type DatepickerProps = React.ComponentProps<typeof Datepicker>;

const AllShortcuts: DatepickerProps['configs'] = {
  shortcuts: {
    today: {
      text: 'Today',
      period: {
        start: new Date(),
        end: new Date(),
      },
    },
    yesterday: {
      text: 'Yesterday',
      period: {
        start: getDateMinusDays(1),
        end: getDateMinusDays(1),
      },
    },
    last7Days: {
      text: 'Last 7 days',
      period: {
        start: getDateMinusDays(7),
        end: new Date(),
      },
    },
    last14Days: {
      text: 'Last 14 days',
      period: {
        start: getDateMinusDays(14),
        end: new Date(),
      },
    },
    thisMonth: {
      text: 'Past month',
      period: {
        start: getDateMinusDays(31),
        end: new Date(),
      },
    },
    last3Months: {
      text: 'Last 3 months',
      period: {
        start: getDateMinusDays(90),
        end: new Date(),
      },
    },
  },
};

export const TimeframeFilter = ({
  currentValue,
  updateFilter,
}: {
  currentValue: DateRange | null;
  updateFilter: (filterValue: DateRange | null) => void;
}) => {
  return (
  <div className="basis-72 shrink-0 border border-default rounded-xl box-content">
    <Datepicker
        inputClassName={`relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full 
        border-gray-300 rounded-xl tracking-wide focus:bg-bg-muted active:text-content-dim
        font-light text-sm placeholder-gray-400 focus:ring  focus:ring focus:text-content-dim
        disabled:opacity-40 disabled:cursor-not-allowed`}
        showShortcuts
        value={currentValue}
        configs={AllShortcuts}
        onChange={(value) => {
          // No-op if they clear out date filters, just leave current filter
          // some start & end is required
          if (!value || !value?.startDate || !value.endDate) {
            return;
          }

          const converted =
            value && value.startDate && value.endDate
              ? { startDate: value.startDate, endDate: value.endDate }
              : null;
          // @ts-expect-error shitty library
          updateFilter(converted);
        }}
        separator="to"
        startFrom={getDateMinusDays(30)}
        maxDate={new Date()}
      />
    </div>
  );
};
