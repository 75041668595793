import gql from 'graphql-tag';

export const REMOVE_MEMBER = gql`
  mutation($userId: String!) {
    removeMember(userId: $userId) {
      success
      error {
        message
        code
      }
    }
  }
`;
