/** @jsxImportSource theme-ui */
import React from 'react';

interface IProps {
  className?: string;
}

const Card: React.FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={className}
      sx={{
        backgroundColor: 'white',
        padding: '1.5rem',
        borderRadius: 1,
        boxShadow: 'main',
      }}
    >
      {children}
    </div>
  );
};

export default Card;
