import React from 'react';

export const TooltipText: React.FC<{ tooltipText: string }> = ({ tooltipText }) => (
  <p className="min-w-[16rem] p-2 text-preset-1 font-normal">{tooltipText}</p>
);

export const TooltipPopover: React.FC<{ tooltipText: string; className?: string }> = ({
  tooltipText,
  className,
}) => (
  <div
    className={`tooltip
            whitespace-normal
            bg-white text-content-default border rounded
            ${className}
            `}
  >
    <TooltipText tooltipText={tooltipText} />
  </div>
);

export const Tooltip: React.FC<{ tooltipText: string; color?: string }> = ({
  color = 'black',
  tooltipText,
}) => (
  <div className="has-tooltip relative">
    <svg
      className="w-4 h-4 cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm10-4a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Zm1 3a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
    </svg>

    <TooltipPopover className="left-0 bottom-6" tooltipText={tooltipText} />
  </div>
);
