/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import Link from '../uikit/Link';
import Card from '../uikit/Card';
import { Field } from '../uikit/forms';
import { PrimaryButton } from '../uikit/buttons';
import Input from '../uikit/Input';

import { Base64 } from 'js-base64';
import { get } from 'lodash';
import React, { FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router';
import { DevDashboardError } from '../consts/DevDashboardError';
import { JOIN_WORKSPACE } from '../graphql/mutations/join-workspace';
import PasswordHoc, { PasswordHocInjectedProps } from '../hocs/PasswordHoc';
import logo from '../images/pinwheel-logo.svg';
import { useAuth } from '../utils/authentication';

const JoinWorkspaceForm: React.FC<
  PasswordHocInjectedProps &
    RouteComponentProps<{
      token: string;
    }>
> = ({
  match,
  newPassword,
  onFieldBlur,
  updatePasswordField,
  passwordValidationError,
  isReadyToSubmitPasswords,
}) => {
  const auth = useAuth();
  const [email, setEmail] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [errorCode, setErrorCode] = React.useState<string | null>(null);
  const [joinWorkspace] = useMutation(JOIN_WORKSPACE);
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);
  const tokenPayload = React.useRef<{
    workspaceName: string;
    invitationKey: string;
    email: string;
  } | null>(null);

  if (tokenPayload.current === null) {
    tokenPayload.current = JSON.parse(Base64.decode(match.params.token));
    setEmail(tokenPayload.current!.email);
  }

  const onRecaptchaChangeHandler = (token: string | null) => {
    setRecaptchaToken(!token ? '' : token);
  };

  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!isReadyToSubmitPasswords) {
      return;
    }

    if (errorCode) {
      return setErrorCode(null);
    }

    try {
      const result = await joinWorkspace({
        variables: {
          invitationKey: tokenPayload.current!.invitationKey,
          fullName,
          password: newPassword.password,
          email,
        },
        context: {
          recaptchaToken,
        },
      });
      const errorMsg = get(result, 'data.joinWorkspace.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.joinWorkspace.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }

      datadogLogs.logger.info('Join workspace succeeded', {
        trackingIds: { email, workspaceName: tokenPayload.current!.workspaceName },
      });

      return auth.setAccessToken!(result.data.joinWorkspace.accessToken);
    } catch (error) {
      onRecaptchaChangeHandler(null);
      (window as any).grecaptcha.reset(recaptchaRef.current?.getWidgetId());

      datadogLogs.logger.info('Join workspace failed with error', {
        error: (error as Error).message,
        trackingIds: { email, workspaceName: tokenPayload.current!.workspaceName },
      });

      const errorMsg = (error as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', { operation: 'joinWorkspace', errorMsg });
      return setErrorCode(errorMsg);
    }
  };

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        <div sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img alt="Pinwheel" src={logo} />
        </div>
        <Card
          sx={{
            width: '25rem',
          }}
        >
          <div
            sx={{
              fontSize: 1,
              fontWeight: '500',
              marginBottom: '1rem',
            }}
          >
            Create your Pinwheel account
          </div>
          <div
            sx={{
              color: 'textSecondary',
              marginBottom: '1.5rem',
            }}
          >
            You’ve been invited to join {tokenPayload.current!.workspaceName}. Create an account to
            log in to your dashboard.
          </div>

          <form onSubmit={handleSubmit}>
            <Field htmlFor="email" label="Email">
              <Input id="email" type="email" required onChange={setEmail} value={email} />
            </Field>

            <Field htmlFor="fullName" label="Full Name">
              <Input id="fullName" type="text" required onChange={setFullName} value={fullName} />
            </Field>

            <Field
              htmlFor="password"
              label="Password"
              sx={{
                '&:after': {
                  content:
                    passwordValidationError.password != null
                      ? `"${passwordValidationError.password}"`
                      : '""',
                  marginTop: '4px',
                  color: 'danger',
                  fontSize: '0.75rem',
                },
              }}
            >
              <Input
                id="password"
                type="password"
                required
                value={newPassword.password}
                onChange={(value: string) =>
                  updatePasswordField({ ...newPassword, password: value })
                }
                onBlur={() => onFieldBlur('password')}
              />
            </Field>

            <Field
              htmlFor="confirmPassword"
              label="Confirm Password"
              sx={{
                '&:after': {
                  content:
                    passwordValidationError.confirmation != null
                      ? `"${passwordValidationError.confirmation}"`
                      : '""',
                  marginTop: '4px',
                  color: 'danger',
                  fontSize: '0.75rem',
                  marginBottom: '2rem',
                },
              }}
            >
              <Input
                id="confirmPassword"
                type="password"
                required
                value={newPassword.passwordConfirmation}
                onBlur={() => onFieldBlur('confirmation')}
                onChange={(value: string) =>
                  updatePasswordField({
                    ...newPassword,
                    passwordConfirmation: value,
                  })
                }
              />
            </Field>

            {errorCode && (
              <div
                sx={{
                  marginTop: '-0.5rem',
                  color: 'danger',
                  marginBottom: '1rem',
                }}
              >
                {errorCode}
              </div>
            )}

            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column',
                width: '100%',
                margin: errorCode ? '1rem 0 2rem 0' : '2rem 0',
              }}
            >
              <div sx={{ width: 'auto', margin: 'auto' }}>
                <ReCAPTCHA
                  sitekey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
                  onChange={onRecaptchaChangeHandler}
                  onExpired={() => setRecaptchaToken(null)}
                  onErrored={() => setRecaptchaToken(null)}
                  ref={recaptchaRef}
                />
              </div>
            </div>

            <PrimaryButton
              sx={{ marginTop: '1rem' }}
              block
              type="submit"
              disabled={!recaptchaToken}
            >
              Create Account
            </PrimaryButton>
          </form>

          <div
            sx={{
              margin: '1rem 0',
              textAlign: 'center',
            }}
          >
            By creating an account I agree to the{' '}
            <a
              href="https://getpinwheel.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#4F9ACA',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Terms of Service
            </a>
            ,{' '}
            <a
              href="https://getpinwheel.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#4F9ACA',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Privacy Policy
            </a>
            , and{' '}
            <a
              href="https://getpinwheel.com/developer"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#4F9ACA',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Developer Policy
            </a>
            .
          </div>

          <div
            sx={{
              padding: '1.5rem',
              paddingBottom: '0rem',
              margin: '0rem -1.5rem',
              marginTop: '1.5rem',
              borderTop: 'gray',
              textAlign: 'center',
            }}
          >
            Already have an account? <Link to="/login">Log in</Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

const JoinWorkspace: React.FC<RouteComponentProps<{
  token: string;
}>> = (props) => {
  return (
    <PasswordHoc initialProps={props} TargetComponent={JoinWorkspaceForm} ignoreCurrentPassword />
  );
};

export default JoinWorkspace;
