import { Card } from '../../../../designs-v2/components/card';
import { Tooltip, TooltipPopover } from '../../../../designs-v2/components/tooltip';
import { colorTokens } from '../../../../designs-v2/primitives/colors';
import { JobErrorResponse } from '../deposit-switch-type/query';

const CountToShow = 7;

export const JobErrorChart: React.FC<{ jobErrors: JobErrorResponse }> = ({ jobErrors }) => {
  return (
    <Card
      noBorder
      className={`flex-1 bg-card-bg bg-cover text-content-on-inverse`}
      heading={
        <div className="flex flex-row gap-2 items-center">
          <h3 className="font-bold text-preset-3">Reasons for Unsuccessful Job Outcomes</h3>
          <Tooltip
            tooltipText={`Breakdown of the reasons jobs have failed. Showing the top ${CountToShow} reasons.`}
            color={colorTokens['content-on-inverse']}
          />
        </div>
      }
      body={<JobErrorChartBody jobErrors={jobErrors} />}
    />
  );
};

export const JobErrorChartBody: React.FC<{ jobErrors: JobErrorResponse }> = ({ jobErrors }) => {
  const largestCount = jobErrors.reduce((acc, curr) => {
    return Math.max(acc, curr.total_user_count);
  }, 0);

  return (
    <div className="flex flex-col gap-6 w-full">
      {jobErrors.slice(0, CountToShow).map((jobError) => {
        const message = formatErrorText(jobError.job_error_code);

        return (
          <div className="flex flex-row w-full gap-1 items-center" key={jobError.job_error_code}>
            <h4 className="text-preset-3 flex-1 overflow-hidden">{message}</h4>

            <div className="flex-1">
              <div
                className="cursor-pointer has-tooltip relative"
                style={{
                  marginRight: `${(1 - jobError.total_user_count / largestCount) * 100}%`,
                }}
              >
                <div
                  className={`h-12 rounded before:rounded `}
                  style={{
                    backgroundColor: colorTokens['bg-primary'],
                    filter: `drop-shadow(0 2px 4px ${colorTokens['bg-primary']})`,
                  }}
                />
                <TooltipPopover
                  className="-top-8"
                  tooltipText={`${message}: ${jobError.total_user_count}`}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function formatErrorText(error: string) {
  const result = error.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
