import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GetAnalyticsConfigs = gql`
  query GetAnalyticsConfigs {
    getAnalyticsConfigs {
      platforms {
        platform_id
        platform_name
      }
      products {
        product
      }
      channels {
        channel
      }
    }
  }
`;

type AnalyticsConfigsResponse = {
  channels: {
    channel: string;
  }[];
  products: {
    product: string;
  }[];
  platforms: {
    platform_id: string;
    platform_name: string;
  }[];
};

export function useAnalyticsConfigs() {
  const { data, loading, error } = useQuery(GetAnalyticsConfigs);

  if (error) {
    throw new Error('failed to load analytics configs: ' + error.message);
  }

  if (loading) {
    return {
      loading,
      data: undefined,
    };
  }

  const configs = data?.getAnalyticsConfigs as AnalyticsConfigsResponse | undefined;

  return { configs, loading };
}
