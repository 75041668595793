import alertIcon from '../../images/red-alert.svg';
import { Employer } from '../../consts/models';
import { ReadOrWriteJob, TLinkTokenForm } from './types';

export type ModeKey = 'sandbox' | 'development' | 'production';
export const MODE_KEYS: ModeKey[] = ['sandbox', 'development', 'production'];

type WorkspaceMode = {
  option: { label: ModeKey; value: ModeKey };
  id: string;
};

export type WorkspaceModes = Partial<Record<ModeKey, WorkspaceMode>>;

export const getEmployerOptions = (employers: Employer[] = []) => {
  return employers
    .map((employer) => {
      return {
        label: employer.name,
        value: employer.id,
      };
    })
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
};

export const getErrorStateCss = (content?: string | null): any => {
  if (content) {
    return {
      '&:before': {
        content: `url(${alertIcon})`,
        position: 'absolute',
        height: '0.75rem',
        width: '0.75rem',
        color: '#df5657',
        bottom: '-1.1rem',
        transition: 'all .5s linear',
      },
      '&:after': {
        content: `"${content}"`,
        bottom: '-1.5rem',
        position: 'absolute',
        left: '1rem',
        color: '#df5657',
        fontSize: '0.875rem',
        transition: 'all .5s linear',
      },
    };
  }
  return {};
};

export const formHasRequiredJob = (job: ReadOrWriteJob, form: TLinkTokenForm) =>
  form.requiredJobs?.some((x) => x.value === job);

export const shouldEnableAmount = (form: TLinkTokenForm): boolean | undefined => {
  return formHasRequiredJob('direct_deposit_switch', form);
};
