import { Card } from '../../designs-v2/components/card';
import { useHistory } from 'react-router';
import { useWorkspace } from '../../hooks/useWorkspace';

const ModespaceIndicator: React.FC<{ modespace: string; enabled: boolean }> = ({
  modespace,
  enabled,
}) => (
  <div className="flex flex-row justify-between">
    <h6 className="text-preset-2">{modespace}</h6>
    <div
      className={`text-preset-1 rounded-xl py-0.5 px-2 ${
        enabled ? 'bg-bg-success--subtle text-content-success' : 'bg-[#F0F0F5]'
      }`}
    >
      {enabled ? 'Active' : 'Not in use'}
    </div>
  </div>
);

const IntegrationStatusCard = () => {
  const history = useHistory();
  const workspace = useWorkspace();

  return (
    <Card
      className=""
      headingClassName="border-b bg-bg-subtle"
      heading={
        <>
          <h4 className="text-preset-2 font-bold">Integration Status</h4>
          <button
            className="text-preset-2 text-content-primary font-bold"
            onClick={() => history.push('/developers')}
          >
            Manage
          </button>
        </>
      }
      body={
        <div className="flex flex-col gap-4 pt-6">
          <ModespaceIndicator modespace="Sandbox" enabled={Boolean(workspace?.sandboxEnabledAt)} />

          <ModespaceIndicator
            modespace="Development"
            enabled={Boolean(workspace?.developmentEnabledAt)}
          />

          <ModespaceIndicator
            modespace="Production"
            enabled={Boolean(workspace?.productionEnabledAt)}
          />
        </div>
      }
    />
  );
};

export const RightSideRail = () => {
  return (
    <div className="flex flex-col gap-8 min-w-[20rem]">
      <IntegrationStatusCard />
    </div>
  );
};
