import React from 'react';
import { useHistory } from 'react-router';
import { Card } from '../../designs-v2/components/card';
import { colorTokens } from '../../designs-v2/primitives/colors';
import { DepositSwitchDocUrl, DocumentationUrl, WebhooksDocUrl } from './constants';

export const ExternalLink: React.FC<{ text: string; href: string }> = ({ text, href }) => (
  <a
    className="flex flex-row items-center gap-1 py-1 text-content-primary cursor-pointer"
    onClick={() => window.open(href)}
  >
    {text}
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
      <path
        fill={colorTokens['content-primary']}
        fillRule="evenodd"
        d="M7 8a1 1 0 0 1 0-2h10a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L14.586 8H7Z"
        clipRule="evenodd"
      />
    </svg>
  </a>
);

const DepositSwitchTile: React.FC = () => {
  return (
    <Card
      className="flex-1"
      heading={<h3 className="text-preset-3 font-bold">Deposit Switch</h3>}
      body={
        <div className="flex flex-col gap-8">
          <p className="text-preset-3 text-content-muted">
            By connecting to their payroll account via Pinwheel, users can make updates to their
            direct deposit settings in an easy, automated fashion.
          </p>
          <div>
            <ExternalLink text="Read More" href={DepositSwitchDocUrl} />
          </div>
        </div>
      }
    />
  );
};

const WebhooksTile: React.FC = () => {
  return (
    <Card
      className="flex-1"
      heading={<h3 className="text-preset-3 font-bold">User Guide</h3>}
      body={
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-8">
            <p className="text-preset-3 text-content-muted">
              Learn how to best leverage our Dashboard to achieve the goals of their business
            </p>
            <div>
              <ExternalLink text="Read More" href={'https://docs.pinwheelapi.com/docs/dashboard'} />
            </div>
          </div>
        </div>
      }
    />
  );
};

const ResourceTiles = [DepositSwitchTile, WebhooksTile];

export const TopResourcesSection = () => (
  <div className="flex flex-col gap-6">
    <div className="flex flex-row justify-between">
      <h1 className="text-preset-5 font-bold">Top resources for you</h1>
      <ExternalLink text="See All" href={DocumentationUrl} />
    </div>
    <div className="flex flex-row gap-4">
      {ResourceTiles.map((Tile, i) => (
        <Tile key={`tile-${i}`} />
      ))}
    </div>
  </div>
);
