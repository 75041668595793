import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { formHasWriteJob } from '../components/TestConsole/form-configs';
import { ModeKey } from '../components/TestConsole/form-helpers';
import { TLinkTokenForm } from '../components/TestConsole/types';
import { Mode } from '../consts/models';

export type HubEnvironments = 'production' | 'staging';

export type ApiConfig = {
  env: HubEnvironments;
  mode: ModeKey;
  key: string;
  secret: string;
};

export type LinkTokenRequest = {
  url: string;
  method: string;
  headers: object;
  body?: object;
};

const getEnv = () => {
  if (window.location.host.includes('localhost')) return 'staging';
  if (window.location.host.includes('staging')) return 'staging';

  return 'production';
};

const getHubUrl = (env: 'staging' | 'production', mode: ModeKey = 'sandbox') => {
  const arr = ['getpinwheel.com'];

  // prepend mode
  if (mode === 'sandbox' || mode === 'development') arr.unshift(`${mode}.`);
  else arr.unshift('api.');

  // prepend env
  if (env === 'staging') arr.unshift('staging-');

  arr.unshift('https://');

  return arr.join('');
};

export const buildRequest = (
  mode: Mode = 'sandbox',
  apiSecret: string,
  path: string,
  version = '2023-07-18',
  method = 'POST'
) => {
  const env = getEnv();

  return {
    url: getHubUrl(env, mode) + path,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-API-SECRET': apiSecret,
      'pinwheel-version': version,
    },
  };
};

export async function sendRequestStringified(
  stringifiedRequest: string
): Promise<{ data: any; error: undefined } | { error: any; data: undefined }> {
  const { url, ...request }: LinkTokenRequest = JSON.parse(stringifiedRequest);
  const body = JSON.stringify(request.body);
  const headers = Object.entries(request.headers);
  const result = await (await fetch(url, { ...request, body, headers })).json();

  return result;
}

export function getLinkTokenRequest(
  form: TLinkTokenForm,
  apiSecret: string,
  path: string,
  extra?: object
): LinkTokenRequest {
  const requiredJobs = form.requiredJobs?.length ? form.requiredJobs : null;

  const allocation: any = {};
  const apiVersion = '2023-11-22';
  const { accounts } = form;
  try {
    if (form.amount) {
      allocation.type = 'amount';
      allocation.value = form.amount;
    }
    allocation.targets = accounts;
  } catch (err) {
    // TODO: proper error handling
    console.log(err);
  }

  let card = form.card;
  if (Object.keys(card).length === 0) {
    card = null;
  }

  let platform_matching = form.platformMatching;
  if (Object.keys(platform_matching).length === 0) {
    platform_matching = null;
  }
  const linkTokenRequest = {
    ...buildRequest(form.mode?.value, apiSecret, path, apiVersion),
    body: _.omitBy(
      {
        required_jobs: requiredJobs?.map((x) => x.value),
        org_name: form.orgName,
        amount: form.amount,
        employer_id: form.selectedEmployer?.value?.id,
        disable_direct_deposit_splitting: form.disableDirectDepositSplitting,
        skip_intro_screen: form.skipIntroScreen,
        document_uploads: form.documentUploads?.value,
        deposit_forms: form.depositForms?.value,
        account_id: form.accountId,
        // If we have an account ID already, no need to specify an end user ID, because
        // it is already associated with payroll account.
        end_user_id: form.accountId ? null : form.endUserId ? form.endUserId : uuidv4(),
        platform_id: form.platformId,
        platform_type: form.platformType?.value || form.platformTypeWithTax?.value,
        smart_branch: form.smartBranchEnabled ?  {enabled: true}  : null,
        language: form.language?.value,
        allocation: formHasWriteJob(form) ? allocation : null,
        card: card,
        cards: form.cards,
        enable_card_switch: false,
        end_user: {
          platform_matching: platform_matching,
        },
        enable_bill_navigator: form.enableBillNavigator,
        tags: form.demandPartnerCustomer?.value?.customerId
          ? { customer_id: form.demandPartnerCustomer.value.customerId }
          : null,
        ...extra,
      },
      (x) => !x
    ),
  };

  return linkTokenRequest;
}

export type SendFormRequest = (form: TLinkTokenForm, apiSecret: string) => Promise<any>;

export async function sendLinkTokenRequest(form: TLinkTokenForm, apiSecret: string) {
  return sendRequestStringified(
    JSON.stringify(getLinkTokenRequest(form, apiSecret, '/v1/link_tokens'))
  );
}

export async function sendSiteTokenRequest(form: TLinkTokenForm, apiSecret: string) {
  return sendRequestStringified(
    JSON.stringify(
      getLinkTokenRequest(form, apiSecret, '/v1/sites', {
        redirect_url: form.redirectUrl || 'https://pinwheelapi.com',
      })
    )
  );
}
