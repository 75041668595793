import gql from 'graphql-tag';

export const CREATE_KEY = gql`
  mutation($mode: String!, $expiresAt: String) {
    createAPIKey(mode: $mode, expiresAt: $expiresAt) {
      success
      error {
        message
        code
      }
      key
      secret
      inactiveAt
      isActive
    }
  }
`;
