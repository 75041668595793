import gql from 'graphql-tag';

export const SET_NEW_PASSWORD = gql`
  mutation($token: String!, $password: String!) {
    setNewPassword(token: $token, password: $password) {
      accessToken
      error {
        message
        code
      }
    }
  }
`;
