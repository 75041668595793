import gql from 'graphql-tag';

export const DEMAND_PARTNER_CUSTOMERS = gql`
  query {
    demandPartnerCustomers {
      customerWorkspaceId
      customerWorkspaceName
      demandPartnerWorkspaceId
      customerId
    }
  }
`;
