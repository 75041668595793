import gql from 'graphql-tag';

export const UPDATE_PASSWORD = gql`
  mutation($password: String!, $passwordConfirmation: String!, $currentPassword: String!) {
    updatePassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      currentPassword: $currentPassword
    ) {
      accessToken
      error {
        message
        code
      }
    }
  }
`;
