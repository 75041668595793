import gql from 'graphql-tag';

export const GetOverviewAnalytics = gql`
  query GetOverviewAnalytics(
    $platform: [String!]
    $product: [String!]
    $channel: [String!]
    $start_date: String!
    $end_date: String!
  ) {
    getOverviewAnalytics(
      start_date: $start_date
      end_date: $end_date
      time_period: null
      timezone: "America/New_York"
      filters: { platform_id: $platform, product: $product, channel: $channel }
    ) {
      overview {
        is_dds_tof_user_count
        has_attempted_login_user_count
        login_success_user_count
        dds_job_success_user_count
      }
      request {
        start_date
        end_date
        time_period
        timezone
        filters {
          platform_id
          product
          channel
        }
      }
    }
  }
`;
