import gql from 'graphql-tag';

export const GET_EMPLOYER_MATCHING_JOBS = gql`
  query {
    getEmployerMatchingJobs {
      jobs {
        id
        uploadFilePath
        createdAt
        uploadingUser {
          firstName
          lastName
          email
        }
        matchedFilePath
      }
    }
  }
`;
