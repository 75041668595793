/** @jsxImportSource theme-ui */

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { ThemeUIStyleObject } from 'theme-ui';
import React, { ReactElement } from 'react';
import Tippy from '@tippyjs/react';

// Shameless copy paste from uikit.
export const CopyIcon: React.FC<{ onClick: () => void }> = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="copy">
          <rect width="24" height="24" opacity="0" fill={isHovered ? '#4f9aca' : '#727d8b'} />
          <path
            fill={isHovered ? '#4f9aca' : '#727d8b'}
            d="M18 9h-3V5.67A2.68 2.68 0 0 0 12.33 3H5.67A2.68 2.68 0 0 0 3 5.67v6.66A2.68 2.68 0 0 0 5.67 15H9v3a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3zm-9 3v1H5.67a.67.67 0 0 1-.67-.67V5.67A.67.67 0 0 1 5.67 5h6.66a.67.67 0 0 1 .67.67V9h-1a3 3 0 0 0-3 3z"
          />
        </g>
      </g>
    </svg>
  );
};

type Props = {
  value: string;
  characterLimit?: number;
  iconSize?: string;
  customSx?: {
    container?: ThemeUIStyleObject;
    value?: ThemeUIStyleObject;
    icon?: ThemeUIStyleObject;
  };
  icon?: ReactElement;
};

export const LongTextWithCopyAction = (props: Props) => {
  const { customSx, value, characterLimit, icon, iconSize } = props;
  if (!value.length) {
    return null;
  }
  const [visible, setVisible] = React.useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const copyToClipboard = (value: string) => {
    const tempElement = document.createElement('textarea');
    tempElement.value = value;
    tempElement.setAttribute('readonly', '');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
    show();
    setTimeout(() => hide(), 500);
  };

  return (
    <React.Fragment>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'row',
          ...customSx?.container,
        }}
      >
        <div
          sx={{
            overflow: 'hidden',
            lineHeight: 1.5,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            ...customSx?.value,
          }}
        >
          {characterLimit && characterLimit < value.length
            ? `${value.substr(0, characterLimit - 1)}...`
            : value}
        </div>
        <Tippy theme="light" content="Copied!" visible={visible} onClickOutside={hide}>
          <div
            sx={{
              marginLeft: '6px',
              width: iconSize || '20px',
              height: 'auto',
              cursor: 'pointer',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              ...customSx?.icon,
            }}
          >
            {icon || <CopyIcon onClick={() => copyToClipboard(value)} />}
          </div>
        </Tippy>
      </div>
    </React.Fragment>
  );
};
