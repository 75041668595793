import { getEntries } from '../../types/entries';
import { AnalyticsFieldName } from './copy/field-names';

export type FilterType = 'platform' | 'timeframe' | 'channel' | 'product';

export type FilterDefinition = {
  label: string;
  resolveOptions: () => Promise<FilterOption[]>;
};

export type FilterOption = {
  label: string;
  value: string;
};

export type FunnelStep =
  | 'is_dds_tof_user_count'
  | 'has_intro_user_count'
  | 'has_search_impression_user_count'
  | 'has_selected_platform_user_count'
  | 'has_login_impression_user_count'
  | 'has_attempted_login_user_count'
  | 'has_viewed_mfa_user_count'
  | 'login_success_user_count'
  | 'dds_job_attempt_user_count'
  | 'dds_job_success_user_count';

export const FunnelFieldMapping: Record<FunnelStep, AnalyticsFieldName> = {
  is_dds_tof_user_count: 'topOfFunnel',
  has_intro_user_count: 'viewedIntro',
  has_search_impression_user_count: 'viewedSearch',
  has_selected_platform_user_count: 'selectedProvider',
  has_login_impression_user_count: 'viewedLogin',
  has_attempted_login_user_count: 'attemptedLogin',
  has_viewed_mfa_user_count: 'viewedMFA',
  login_success_user_count: 'loginSuccess',
  dds_job_attempt_user_count: 'attemptedDDS',
  dds_job_success_user_count: 'totalSwitches',
};

export const FunnelOptions = getEntries(FunnelFieldMapping);

export type ReportConfig = {
  workspace_id: string;
  report_type: string;
  frequency: string;
  start_date: string;
};

export type Report = {
  id: string;
  name: string;
  startTime: string;
  endTime: string;
};
