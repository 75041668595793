import { useQuery } from '@apollo/client';
import React from 'react';
import { Card } from '../../../designs-v2/components/card';
import { Tooltip } from '../../../designs-v2/components/tooltip/index';
import { AnalyticsFieldName, useFieldCopy } from '../copy/field-names';
import { InternalFiltersState } from '../filters/state';
import { numberFormatter } from '../funnel';
import { GetOverviewAnalytics } from '../graphql/getOverviewAnalytics';
import { FormField } from '../../../designs-v2/components/form/form-field/index';
import { LifetimeValueCard } from './lifetime-value';

type OverviewFields = keyof OverviewData | 'conversionRate';

type OverviewData = {
  is_dds_tof_user_count: number | null;
  has_attempted_login_user_count: number | null;
  dds_job_success_user_count: number | null;
  login_success_user_count: number | null;
};

function calculateMetrics(data?: OverviewData) {
  if (!data || !data.dds_job_success_user_count || !data.is_dds_tof_user_count) {
    return {};
  }

  return {
    conversionRate: (data.dds_job_success_user_count / data.is_dds_tof_user_count) * 100,
  };
}

function useOverviewQuery(filters: InternalFiltersState) {
  const { platform, timeframe, product, channel } = filters;
  const { data, loading, refetch } = useQuery(GetOverviewAnalytics, {
    variables: {
      platform,
      channel,
      product,
      start_date: timeframe?.startDate,
      end_date: timeframe?.endDate,
    },
  });

  const overviewData = data?.getOverviewAnalytics?.overview as OverviewData | undefined;

  const calculatedMetrics = calculateMetrics(overviewData);
  // TODO: conversion rate:  dds_job_success_user_count / is_dds_tof_user_count

  return { overviewData, calculatedMetrics, loading, refetch };
}

const HeadingWithTooltip: React.FC<{ heading: string; tooltipText: string }> = ({
  heading,
  tooltipText,
}) => (
  <div className="flex flex-row gap-2 text-preset-2">
    {heading}
    <Tooltip tooltipText={tooltipText} />
  </div>
);

const OverviewCard: React.FC<{ field: AnalyticsFieldName; loading: boolean; value: string }> = ({
  field,
  loading,
  value,
}) => {
  const { heading, tooltip, description } = useFieldCopy(field);

  return (
    <Card
      className="flex-1"
      heading={<HeadingWithTooltip heading={heading} tooltipText={tooltip!} />}
      body={
        <div className="flex flex-col gap-1.5">
          {loading ? (
            <div className="h-10 bg-bg-muted animate-pulse rounded-xl" />
          ) : (
            <div id={heading} className="h-10 text-preset-6 font-bold">
              {value}
            </div>
          )}
          <div className="text-preset-1 text-content-subtle">{description}</div>
        </div>
      }
    />
  );
};

export const Overview: React.FC<{ filters: InternalFiltersState }> = ({ filters }) => {
  const { overviewData, calculatedMetrics, loading, refetch } = useOverviewQuery(filters);

  const fields: [OverviewFields, AnalyticsFieldName][] = [
    ['is_dds_tof_user_count', 'topOfFunnel'],
    ['login_success_user_count', 'loginSuccess'],
    ['dds_job_success_user_count', 'successfulDDS'],
  ];

  return (
    <div className="flex flex-row gap-4 flex-grow">
      {/* <LifetimeValueCard /> */}
      {fields.map(([field, fieldName]) => {
        return (
          <OverviewCard
            key={field}
            field={fieldName}
            loading={loading}
            value={numberFormatter.format(overviewData?.[field] || 0)}
          />
        );
      })}
      <OverviewCard
        field="conversionRate"
        loading={loading}
        value={`${Math.round(calculatedMetrics?.conversionRate || 0)}%`}
      />
    </div>
  );
};
