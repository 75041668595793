import * as React from 'react';
import Select, { CommonProps, OnChangeValue, Props } from 'react-select';
import Icon from './Icon';

export type SelectChangeValue =
  | OnChangeValue<{ value: any; label: string }, boolean>
  | null
  | undefined;

type CustomSelectProps = Props & {
  onChangeHandler?: (value: any) => void;
};

const customStyles = {
  control: (provided: React.CSSProperties) => ({
    ...provided,
    backgroundColor: '#e7ebed',
    border: 'none',
  }),
  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
};

export const DropdownIndicator = (props) => {
  const { cx, getStyles, innerProps } = props;
  const nextClassName = cx(
    {
      indicator: true,
      'dropdown-indicator': true,
    },
    'customDropdown'
  );
  return (
    <div {...innerProps} style={getStyles('dropdownIndicator', props)} className={nextClassName}>
      <div sx={{ width: '1.5rem', height: '1.5rem', margin: 'auto' }}>
        <Icon icon="dropdownArrows" />
      </div>
    </div>
  );
};

export default (props: CustomSelectProps) => {
  const { onChangeHandler, ...rest } = props;
  return (
    <Select
      onChange={(value) =>
        props.onChangeHandler &&
        props.onChangeHandler(
          value != null ? (value as { value: string; label: string }).value : ''
        )
      }
      // @ts-expect-error idk
      styles={customStyles}
      components={{
        DropdownIndicator,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary75: 'rgba(79, 154, 202, 0.75)',
          primary50: 'rgba(79, 154, 202, 0.5)',
          primary25: 'rgba(79, 154, 202, 0.25)',
          primary: '#4F9ACA',
        },
      })}
      {...rest}
    />
  );
};
