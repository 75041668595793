/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import Card from '../uikit/Card';
import { Field } from '../uikit/forms';
import Input from '../uikit/Input';

import React, { useState } from 'react';
import logo from '../images/pinwheel-logo.svg';
import { Button } from '../designs-v2/components/button';
import { UPDATE_USER_PROFILE } from '../graphql/mutations/update-profile';
import { useHistory } from 'react-router-dom';

const CompleteProfile: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // TODO: handle/show errors
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const history = useHistory();

  // TODO: refactor to allow submit by enter key (e.g. use form)
  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        <div sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img alt="Pinwheel" src={logo} />
        </div>
        <Card
          sx={{
            width: '25rem',
          }}
        >
          <div
            sx={{
              fontSize: 1,
              fontWeight: '500',
              marginBottom: '1rem',
            }}
          >
            Complete your profile
          </div>
          <div
            sx={{
              color: 'textSecondary',
              marginBottom: '1.5rem',
            }}
          >
            Complete your profile to access your dashboard.
          </div>

          <Field htmlFor="email" label="First Name">
            <Input id="firstName" type="text" required onChange={setFirstName} value={firstName} />
          </Field>

          <Field htmlFor="lastName" label="Last Name">
            <Input id="lastName" type="text" onChange={setLastName} value={lastName} />
          </Field>

          <Button
            buttonType="primary"
            label="Continue"
            onClick={async () => {
              await updateUserProfile({
                variables: {
                  ...(firstName && { firstName }),
                  ...(lastName && { lastName }),
                },
              });
              history.push('/');
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default CompleteProfile;
