export type CheckboxProps = {
  size?: 'md' | 'sm';
  isDisabled?: boolean;
  checked?: boolean;
};

const Sizes = {
  sm: 4,
  md: 6,
};

function getSizeClass(size: 'md' | 'sm') {
  if (size === 'sm') {
    return {
      checkbox: 'w-4 h-4',
      icon: 'w-3 h-3',
    };
  }

  return {
    checkbox: 'w-6 h-6 rounded-lg',
    icon: 'w-5 h-5',
  };
}

export const Checkbox: React.FC<CheckboxProps> = ({ size = 'md', isDisabled, checked }) => {
  const { checkbox, icon } = getSizeClass(size);

  return (
    <div className="flex">
      <input
        type="checkbox"
        readOnly
        checked={checked}
        className={`
        ${checkbox}
          relative peer shrink-0
          appearance-none border border-border-primary rounded bg-white
          mt-1 cursor-pointer
        checked:bg-border-primary checked:border-0
          focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-border-default
          disabled:border-steel-400 disabled:bg-steel-400
        `}
        disabled={isDisabled}
      />
      <svg
        className={`
        ${icon}
        mt-1.5 ml-0.5
        absolute 
        hidden peer-checked:block
        pointer-events-none
        `}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M10.3536 2.64645C10.5488 2.84171 10.5488 3.15829 10.3536 3.35355L4.85355 8.85355C4.65829 9.04882 4.34171 9.04882 4.14645 8.85355L1.64645 6.35355C1.45118 6.15829 1.45118 5.84171 1.64645 5.64645C1.84171 5.45118 2.15829 5.45118 2.35355 5.64645L4.5 7.79289L9.64645 2.64645C9.84171 2.45118 10.1583 2.45118 10.3536 2.64645Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
