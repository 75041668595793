import React, { Fragment } from 'react';
import { FunnelStep } from '../types';
import { FunnelSection } from './FunnelSection';
import { FunnelResponse } from './query';
import { FunnelSeparator } from './separator';

export const numberFormatter = Intl.NumberFormat('en-US');

function useFunnelSteps() {
  const [funnelSteps, setFunnelSteps] = React.useState<FunnelStep[]>([
    'is_dds_tof_user_count',
    'has_search_impression_user_count',
    'has_attempted_login_user_count',
    'dds_job_success_user_count',
  ]);

  return {
    funnelSteps,
    updateStep: (index: number, step: FunnelStep) => {
      const newSteps = funnelSteps
        .slice(0, index)
        .concat(step)
        .concat(funnelSteps.slice(index + 1));
      setFunnelSteps(newSteps);
    },
    deleteStep: (index: number) => {
      const newSteps = funnelSteps.slice(0, index).concat(funnelSteps.slice(index + 1));
      setFunnelSteps(newSteps);
    },
    addStep: (index: number, step: FunnelStep) => {
      const newSteps = funnelSteps.slice(0, index).concat(step).concat(funnelSteps.slice(index));
      setFunnelSteps(newSteps);
    },
  };
}

export const Funnel: React.FC<{
  funnel: FunnelResponse;
  loading: boolean;
}> = ({ funnel, loading }) => {
  const { funnelSteps, updateStep, deleteStep, addStep } = useFunnelSteps();

  const values = funnelSteps.map((step) => funnel[step] || 0);
  const topOfFunnel = funnel.is_dds_tof_user_count || 1;
  const maximum = Math.max(...values) || 1;
  // const maximum = funnel.is_dds_tof_user_count || 1;

  return (
    <div className="flex flex-col bg-card-bg  bg-cover bg-clip-padding rounded-3xl overflow-x-auto">
      <div className="flex flex-row justify-between m-8">
        <div className="text-preset-5 text-content-on-inverse">Funnel Steps</div>
      </div>
      <div className="flex flex-row flex-nowrap	w-full">
        {funnelSteps.map((step, index) => {
          const value = funnel[step] || 0;

          const percentage = (value / topOfFunnel) * 100;
          const nextPercentage =
            index === funnelSteps.length - 1 ? percentage : (values[index + 1] / maximum) * 100;

          return (
            <Fragment key={`${step}-${index}`}>
              <FunnelSection
                value={value}
                funnelStep={step}
                order={index}
                funnelLength={funnelSteps.length}
                percentage={percentage}
                relativePercentage={(value / maximum) * 100}
                relativeNextPercentage={nextPercentage}
                loading={loading}
                setFunnelStep={(step) => updateStep(index, step)}
                closeFunnelStep={() => deleteStep(index)}
                addNewStep={() => addStep(index, step)}
              />
              {index !== funnelSteps.length - 1 && <FunnelSeparator />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
