import React from 'react';

type Color = { r: number; g: number; b: number };

const FunnelColors = {
  left: { r: 13, g: 78, b: 188 },
  center: { r: 130, g: 250, b: 254 },
  right: { r: 220, g: 146, b: 241 },
};

function resolveColor({
  startColor,
  endColor,
  index,
  length,
}: {
  startColor: Color;
  endColor: Color;
  index: number;
  length: number;
}) {
  if (index < 0 || index > length) {
    throw new Error('invalid index: ' + index + ', ' + length);
  }

  const percentageEndColor = index / length;
  const percentageStartColor = 1 - percentageEndColor;

  const result = {
    r: startColor.r * percentageStartColor + endColor.r * percentageEndColor,
    g: startColor.g * percentageStartColor + endColor.g * percentageEndColor,
    b: startColor.b * percentageStartColor + endColor.b * percentageEndColor,
  };

  return result;
}

function getColorAtPoint(index: number, total: number) {
  if (index === 0) {
    return FunnelColors.left;
  }
  if (index === total) {
    return FunnelColors.right;
  }
  if (index === total / 2) {
    return FunnelColors.center;
  }

  if (index <= total / 2) {
    return resolveColor({
      startColor: FunnelColors.left,
      endColor: FunnelColors.center,
      index,
      length: total / 2,
    });
  }
  return resolveColor({
    startColor: FunnelColors.center,
    endColor: FunnelColors.right,
    index: index - total / 2,
    length: total / 2,
  });
}

function toString({ r, g, b }: Color, opacity?: number) {
  if (opacity) {
    return `rgba(${r},${g},${b},${opacity})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

function getGradientColorsV2(index: number, funnelLength: number) {
  if (funnelLength <= 1) {
    return { startColor: FunnelColors.left, endColor: FunnelColors.right };
  }
  if (funnelLength === 2) {
    if (index === 0) {
      return { startColor: FunnelColors.left, endColor: FunnelColors.center };
    }
    return { startColor: FunnelColors.center, endColor: FunnelColors.right };
  }

  return {
    startColor: getColorAtPoint(index, funnelLength),
    endColor: getColorAtPoint(index + 1, funnelLength),
  };
}

export type FunnelVisualizationProps = {
  startPercentage: number;
  endPercentage: number;
  index: number;
  funnelLength: number;
};

export const FunnelVisualization = React.forwardRef<SVGSVGElement, FunnelVisualizationProps>(
  ({ index, funnelLength, startPercentage, endPercentage }, ref) => {
    const { startColor, endColor } = getGradientColorsV2(index, funnelLength);
    const heightStart = (100 - startPercentage) / 2;
    const heightEnd = (100 - endPercentage) / 2;
    const heightMid = (heightStart + heightEnd) / 2;

    const FunnelGraphic: React.FC<{ fill: string; offset: number }> = ({ fill, offset }) => {
      const endHeight = 120 - offset;

      return (
        <path
          fill={fill}
          d={`
          M 0, ${heightStart + offset}
          L 20, ${heightStart + offset}
          Q 35,${heightStart + offset},50,${heightMid + offset}
          Q 65,${heightEnd + offset}, 80, ${heightEnd + offset}
          L 100, ${heightEnd + offset}
          L 100, ${endHeight - heightEnd}
          L 80, ${endHeight - heightEnd}
          Q 65, ${endHeight - heightEnd}, 50, ${endHeight - heightMid}
          Q 35, ${endHeight - heightStart}, 20, ${endHeight - heightStart}
          L 0, ${endHeight - heightStart}
          Z
        `}
        />
      );
    };

    return (
      <svg
        viewBox="0 0 100 120"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-60 w-full border-gray-100`}
        preserveAspectRatio="none"
        ref={ref}
      >
        <defs>
          <linearGradient id={`gradient-${index}`}>
            <stop offset="5%" stopColor={toString(startColor)} />
            <stop offset="95%" stopColor={toString(endColor)} />
          </linearGradient>
          <linearGradient id={`gradient-${index}-border-1`}>
            <stop offset="5%" stopColor={toString(startColor, 0.1)} />
            <stop offset="95%" stopColor={toString(endColor, 0.1)} />
          </linearGradient>
          <linearGradient id={`gradient-${index}-border-2`}>
            <stop offset="5%" stopColor={toString(startColor, 0.2)} />
            <stop offset="95%" stopColor={toString(endColor, 0.2)} />
          </linearGradient>
        </defs>
        {/* Wrappers */}
        <FunnelGraphic fill={`url(#gradient-${index}-border-1)`} offset={3} />
        <FunnelGraphic fill={`url(#gradient-${index}-border-2)`} offset={6} />
        {/* Main funnel */}
        <FunnelGraphic fill={`url(#gradient-${index})`} offset={10} />
      </svg>
    );
  }
);
