/** @jsxImportSource theme-ui */
import { PrimaryButton } from '../uikit/buttons';
import Modal from '../uikit/Modal';
import { saveAs } from 'file-saver';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { APIKey, Mode } from '../consts/models';
import { Button } from '../uikit/buttons';
import { capitalize, copyTextToClipboard } from '../utils/general';

const CreateKeySuccessModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
  newAPIKey: APIKey | null;
  mode: Mode;
}> = ({ isOpen, onRequestClose, mode, newAPIKey }) => {
  const { addToast } = useToasts();

  function generateKeyCSV(apiKey: APIKey): string {
    return `key,secret\n${apiKey.apiKey},${apiKey.apiSecret}\n`;
  }

  function handleDownload() {
    if (!newAPIKey) {
      return;
    }
    const text = generateKeyCSV(newAPIKey);
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `pinwheel-api-key-${newAPIKey.apiKey.substr(0, 6)}.csv`);
  }

  // onClick handler function for the copy button
  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        addToast('Copied!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="35rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        Here's your new API key for {capitalize(mode)}
      </div>

      <div
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          padding: '1rem',
          border: '1px dotted #bbb',
        }}
      >
        <div
          sx={{
            fontSize: '.9em',
            fontWeight: 500,
            color: '#888',
            marginBottom: '.75rem',
          }}
        >
          Key
          <Button
            size="small"
            icon="copy"
            sx={{
              float: 'right',
              color: '#888',
              marginTop: '-.2rem',
            }}
            onClick={() => handleCopyClick(newAPIKey?.apiKey || '')}
          >
            Copy
          </Button>
        </div>
        <div
          sx={{
            fontSize: '.7em',
            fontFamily: "'Roboto Mono', monospace, 'Circular'",
            color: 'black',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {newAPIKey?.apiKey}
        </div>
      </div>

      <div
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          padding: '1rem',
          border: '1px dotted #bbb',
        }}
      >
        <div
          sx={{
            fontSize: '.9em',
            fontWeight: 500,
            color: '#888',
            marginBottom: '.75rem',
          }}
        >
          Secret
          <Button
            size="small"
            icon="copy"
            sx={{
              float: 'right',
              color: '#888',
              marginTop: '-.2rem',
            }}
            onClick={() => handleCopyClick(newAPIKey?.apiSecret || '')}
          >
            Copy
          </Button>
        </div>
        <div
          sx={{
            fontSize: '.7em',
            fontFamily: "'Roboto Mono', monospace, 'Circular'",
            color: 'black',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {newAPIKey?.apiSecret}
        </div>
      </div>

      <div
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          fontSize: '.8em',
          color: '#627284',
        }}
      >
        {newAPIKey?.inactiveAt ? (
          <div sx={{ marginBottom: '1rem' }}>
            <span>
              This API key will automatically be revoked on <strong>{newAPIKey?.inactiveAt}</strong>
              .
            </span>
          </div>
        ) : (
          ''
        )}
        {mode != 'sandbox' ? (
          <div>
            For security purposes, this <strong>key secret will not be shown again</strong>. Please
            download or copy it now.
          </div>
        ) : (
          ''
        )}
      </div>

      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <PrimaryButton type="button" onClick={handleDownload}>
          Download
        </PrimaryButton>
        <PrimaryButton sx={{ marginLeft: '1rem' }} onClick={onRequestClose}>
          Done
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default CreateKeySuccessModal;
