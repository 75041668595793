/* eslint-disable no-console */
import React, { useState } from 'react';
import { PrimaryButton } from '../../uikit/buttons';
import PinwheelModal, { PinwheelModalProps } from '@pinwheel/react-modal';

export type Props = {
  token: string;
  sdk_onLogin: PinwheelModalProps['onLogin'];
  demoModeEnabled: boolean;
};

const srcUrl =
  // On production, use the default URL from react-modal which will set the `sandbox`
  // attribute on the iframe and also set the integrity hash on the Portal script.
  process.env.REACT_APP_PINWHEEL_ENVIRONMENT === 'production'
    ? undefined
    : 'https://staging.cdn.getpinwheel.com/pinwheel-v2.js?sandbox=true';

export default ({ token, sdk_onLogin, demoModeEnabled }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <PinwheelModal
        open={open}
        linkToken={token}
        onExit={(...e) => {
          setOpen(false);
          console.log('onExit was fired with args:', ...e);
        }}
        _srcUrl={srcUrl}
        onError={(...e) => console.log('onError was fired with args:', ...e)}
        onEvent={(...e) => console.log('onEvent was fired with args:', ...e)}
        onLogin={(...e) => {
          console.log('onLogin was fired with args:', ...e);
          if (sdk_onLogin) sdk_onLogin(...e);
        }}
        onSuccess={(...e) => console.log('onSuccess was fired with args:', ...e)}
        // @ts-ignore
        _demoModeEnabled={demoModeEnabled ?? undefined}
        ariaHideDocumentContent
      />
      <PrimaryButton onClick={() => setOpen(true)}>Launch Link Modal</PrimaryButton>
    </React.Fragment>
  );
};
