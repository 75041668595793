import { ThemeUIStyleObject } from 'theme-ui';

export const rowStyles: ThemeUIStyleObject = {
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  marginTop: '2.5rem',
};

export const rowStylesNoWrap: ThemeUIStyleObject = {
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  width: '100%',
  marginTop: '2.5rem',
};

export const columnStyles: ThemeUIStyleObject = {
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  marginTop: '2.5rem',
};

export const columnStylesSimple: ThemeUIStyleObject = {
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
};

export const titleStyles: ThemeUIStyleObject = {
  fontSize: 2,
  fontWeight: 500,
  marginBottom: '2.5rem',
};

export const containerStyles: ThemeUIStyleObject = {
  backgroundColor: 'white',
  padding: '3rem 4rem',
  borderRadius: 0,
  boxShadow: 'main',
};

export const flexFilledCenteredStyles: ThemeUIStyleObject = {
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  alignItems: 'center',
};
