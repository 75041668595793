/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { get } from 'lodash';
import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { DevDashboardError } from '../consts/DevDashboardError';
import { APIKey, Mode } from '../consts/models';
import { CREATE_KEY } from '../graphql/mutations/create-key';
import Checkbox from '../uikit/Checkbox';
import DatePicker from '../uikit/DatePicker';
import Modal from '../uikit/Modal';
import { Button, PrimaryButton } from '../uikit/buttons';
import { capitalize } from '../utils/general';
import { useSession } from '../utils/session';
import { localTimeToUTC } from '../utils/time';

const CreateKeyModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
  setError: Dispatch<SetStateAction<DevDashboardError | null>>;
  setNewAPIKey: Dispatch<SetStateAction<APIKey | null>>;
  mode: Mode;
}> = ({ isOpen, onRequestClose, mode, setError, setNewAPIKey }) => {
  const [createKey] = useMutation(CREATE_KEY);
  const [expiresChecked, setExpiresChecked] = React.useState<boolean>(false);
  const [expiresAt, setExpiresAt] = React.useState<Date | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const { refreshSession } = useSession();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  React.useEffect(() => {
    if (!isOpen) {
      setExpiresAt(undefined);
      setExpiresChecked(false);
    }
  }, [isOpen]);

  const handleCheckboxChange = () => {
    setExpiresChecked(!expiresChecked);
  };

  const handleDateChange = (date: string) => {
    setExpiresChecked(true);
    setExpiresAt(new Date(date));
  };

  const isSubmitDisabled = () =>
    loading || (expiresChecked && (!expiresAt || localTimeToUTC(expiresAt) < tomorrow));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);
    try {
      const result = await createKey({
        variables: {
          mode,
          expiresAt: expiresChecked && expiresAt ? localTimeToUTC(expiresAt) : null,
        },
      });
      const errorMsg = get(result, 'data.createAPIKey.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.createAPIKey.error.code', null);
        throw new DevDashboardError(errorMsg, code, 'createAPIKey');
      }
      const apiKey = get(result, 'data.createAPIKey', null);
      setNewAPIKey({
        apiKey: apiKey.key,
        apiSecret: apiKey.secret,
        inactiveAt: apiKey.inactiveAt
          ? new Date(apiKey.inactiveAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : undefined,
        isActive: true,
      });
      // refreshSession();

      datadogLogs.logger.info('API key created', {
        newApiKey: apiKey.key,
        mode,
      });
    } catch (error) {
      if (error instanceof DevDashboardError) {
        setError(error);
      } else {
        setError(DevDashboardError.fromException(error as Error, 'createAPIKey'));
      }
    } finally {
      setLoading(false);
      onRequestClose();
    }
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="35rem"
      appElement={document.getElementById('root') as HTMLElement}
      customStyles={{
        content: {
          cursor: loading ? 'wait' : 'auto',
        },
      }}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '2rem',
        }}
      >
        Create new API key for {capitalize(mode)}?
      </div>

      <div
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
      >
        <Checkbox
          label="Set to automatically be revoked on..."
          isSelected={expiresChecked}
          onChange={handleCheckboxChange}
        />
        <DatePicker
          onChange={(date: string) => handleDateChange && handleDateChange(date)}
          min={tomorrow.toISOString().slice(0, 10)}
          customSx={{
            marginLeft: '1rem',
            input: {
              fontSize: '1rem',
            },
          }}
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div
          sx={{
            marginTop: '2em',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type="button" onClick={onRequestClose}>
            Cancel
          </Button>
          <PrimaryButton type="submit" sx={{ marginLeft: '1rem' }} disabled={isSubmitDisabled()}>
            Create new key
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateKeyModal;
