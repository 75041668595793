import { Button } from '../../../designs-v2/components/button';
import { PlatformFilter } from './pill';
import { FiltersInterface } from './state';
import { TimeframeFilter } from './timeframe';

type FilterRowProps = FiltersInterface & {
  togglePrimaryView: () => void;
  primaryView: 'funnel' | 'chart';
};

export const FilterRow = ({
  filters,
  primaryView,
  setFilter,
  resetFilter,
  togglePrimaryView,
}: FilterRowProps) => {
  return (
    <div className="mt-4 flex flex-row justify-between">
      {/* Future product can live here */}

      <div className="flex flex-row gap-4">
        <TimeframeFilter
          currentValue={filters.timeframe}
          updateFilter={(value) => setFilter('timeframe', value)}
        />
        <PlatformFilter
          currentValue={filters.platform}
          updateFilter={(value) => setFilter('platform', value)}
        />
      </div>

      <Button
        buttonType="text_primary"
        label={primaryView === 'chart' ? 'Show Funnel' : 'Show Timeseries'}
        onClick={() => togglePrimaryView()}
      />
    </div>
  );
};
