import React from 'react';
import alertIcon from '../../images/red-alert.svg';

export const ErrorMessage = ({ error }: { error?: string }) => (
  <div className="form-field-error">
    {error ? (
      <>
        <img className="form-field-error-img" src={alertIcon} alt="Error icon" />
        <span>{error}</span>
      </>
    ) : null}
  </div>
);

export const getLabelText = (label: string, required?: boolean) => (
  <>
    {label}
    {required ? <span className="form-field-asterisk"> *</span> : null}
  </>
);

export const getDisabledClass = (disabled?: boolean) => (disabled ? 'form-field-disabled' : '');

export type InputSelectOption<T, U = T> = { value: T; label: U };
export type EmptyValue = '' | '\u00a0';

// Using the nbsp character to ensure that the option has the same height as others
export const getEmptyOption = (): InputSelectOption<EmptyValue> => ({ value: '', label: '\u00a0' });

export function getOptionWithDifferentLabel<T = string, U = string>(
  value: T,
  label: U
): InputSelectOption<T, U> {
  return { value, label };
}

export function getOption<T>(value: T, label = value): InputSelectOption<T> {
  return { value, label };
}
