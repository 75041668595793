import React, { MouseEventHandler } from 'react';
import { IconType } from './types';

export const Icon: React.FC<{
  type: IconType;
  className?: string;
  onClick?: MouseEventHandler;
}> = ({ type, className, onClick }) => {
  const svg = require(`./assets/${type}.svg`);

  return <img onClick={onClick} className={className} src={svg} alt={type} />;
};
