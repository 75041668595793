import { APIKey, MemberRole, Mode, User, Membership, Workspace } from '../consts/models';
import { get } from 'lodash';
import { useAuth } from './authentication';
import { useSession } from './session';
import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

export const useCurrentWorkspace = () => {
  const { currentWorkspaceId } = useAuth();
  const session = useSession();

  const user = session.data as User;
  const workspaces = get(user, 'workspaces', []);
  const currentWorkspace = getCurrentWorkspace(workspaces, currentWorkspaceId);
  const isOwner = user && user.id === currentWorkspace?.ownerId;
  useEffect(() => {
    datadogLogs.addLoggerGlobalContext('user', {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    });
    datadogLogs.addLoggerGlobalContext('currentWorkspace', {
      id: currentWorkspace?.id,
      name: currentWorkspace?.name,
      role: isOwner ? 'owner' : getMembershipFromUserWorkspace(currentWorkspace, user)?.role,
    });
  }, [currentWorkspace, user]);
  if (session.data) {
    datadogLogs.logger.info('Authenticated session');
  }
  if (session.loading || !session.data) {
    return undefined;
  }

  return currentWorkspace;
};

export const getCurrentWorkspace = (
  workspaces: Workspace[],
  currentWorkspaceId: string | null | undefined
) =>
  Array.isArray(workspaces) && workspaces.length > 0
    ? workspaces.find((workspace: Workspace) => workspace.id === currentWorkspaceId)
    : undefined;

export const isUserWorkspaceAdmin = (workspace: Workspace | undefined, user: User): boolean => {
  const membership = getMembershipFromUserWorkspace(workspace, user);
  return (membership && membership.role === 'administrator') || false;
};

export const isUserWorkspaceOwner = (workspace: Workspace | undefined, user: User) => {
  return (workspace && workspace.ownerId === user.id) || false;
};

export const isUserAdminOrOwner = (workspace: Workspace | undefined, user: User): boolean => {
  return isUserWorkspaceAdmin(workspace, user) || isUserWorkspaceOwner(workspace, user);
};

export const useCurrentWorkspaceRole = (): MemberRole | undefined => {
  const isUserAdminOrOwner = useIsAdminOrOwner();
  const session = useSession();

  const user = session.data as User;
  const currentWorkspace = useCurrentWorkspace();

  const membership = getMembershipFromUserWorkspace(currentWorkspace, user);
  // Due to the way the backend works, we can't guarantee that the user will have a membership
  // in the workspace. This is because workspace owners are not added as members of the workspace.
  // Why? Who knows. But for now, we are considering all workspace owners to be administrators.
  if (!membership) {
    if (isUserAdminOrOwner) return 'administrator';
    console.warn("Couldn't find membership for user in workspace");
  }

  return membership?.role;
};

export const getMembershipFromUserWorkspace = (
  workspace: Workspace | undefined,
  user: User
): Membership | undefined => {
  // I don't trust this logic but copying from above
  const membership =
    workspace && user.memberships?.filter((m) => m.workspaceId == workspace.id).pop();
  return membership;
};

export const useIsAdminOrOwner = (): boolean => {
  const session = useSession();

  const user = session.data as User;
  const currentWorkspace = useCurrentWorkspace();
  return isUserAdminOrOwner(currentWorkspace, user);
};

export const getActiveApiKeysByMode = (workspace: Workspace): Record<Mode, APIKey[]> => {
  function filterActiveKeys(keys: APIKey[] | undefined): APIKey[] {
    return keys ? keys.filter((k) => k.isActive) : [];
  }
  return {
    sandbox: filterActiveKeys(workspace.sandboxAPIKeys),
    development: filterActiveKeys(workspace.developmentAPIKeys),
    production: filterActiveKeys(workspace.productionAPIKeys),
  };
};
