import cloneDeep from 'lodash/cloneDeep';

export function capitalize(string: string) {
  const firstLetter = string.slice(0, 1).toUpperCase();
  return `${firstLetter}${string.slice(1)}`;
}

export function setFunctionally(obj: object, pathString: string, newValue: any) {
  const path = pathString.split('.');
  const alteredObj = cloneDeep(obj);
  let propertyToAlter = alteredObj;
  path.forEach((property, i) => {
    if (!propertyToAlter) return;
    if (i === path.length - 1) {
      propertyToAlter[property] = newValue;
    } else {
      propertyToAlter = propertyToAlter[property];
    }
  });
  propertyToAlter = newValue;
  return alteredObj;
}

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}
