import statsig from 'statsig-js';

type DynamicConfigName = 'dc-adp-partnership-test-user';

const state = { loaded: false };

(async function init() {
  if (!process.env.REACT_APP_STATSIG_CLIENT_KEY) return;
  await statsig.initialize(process.env.REACT_APP_STATSIG_CLIENT_KEY);
  state.loaded = true;
})();

export function getConfig(gate: DynamicConfigName) {
  if (!state.loaded) return {};
  return statsig.getConfig(gate).value;
}
