import gql from 'graphql-tag';

export const INVITE_MEMBER = gql`
  mutation($email: String!, $role: String!) {
    inviteMember(email: $email, role: $role) {
      success
      error {
        message
        code
      }
    }
  }
`;
