/** @jsxImportSource theme-ui */
import { Button, PrimaryButton } from '../../uikit/buttons';
import { get } from 'lodash';
import React from 'react';
import { useGate } from 'statsig-react';
import Modal from '../../uikit/Modal';
import { SendFormRequest, sendLinkTokenRequest, sendSiteTokenRequest } from '../../utils/requests';
import { DangerIcon } from '../../uikit/Icon';

type Props<T> = {
  onFormSuccess: (token: string, valueType: 'url' | 'token') => void;
  disabled?: boolean;
  getSubmitFormFn: (sendFormRequest: SendFormRequest) => (form: T) => Promise<any>;
  form: T;
};

function CreateLinkTokenButton<T>({ form, onFormSuccess, disabled, getSubmitFormFn }: Props<T>) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setButtonLoadingState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const linkSitesEnabledGate = useGate('ff-link-sites-enabled');
  const processError = (errors: any) => {
    const error = Array.isArray(errors) ? errors[0] : errors;
    const message =
      typeof error.message !== 'string'
        ? get(
            error.message,
            'message',
            'An unknown error happened trying to create a new link token'
          )
        : error.message;
    setErrorMessage(message.replace('GraphQL error: ', ''));
    setModalOpen(true);
    setTimeout(() => setButtonLoadingState(false), 500);
  };

  const createLinkToken = getSubmitFormFn(sendLinkTokenRequest);
  const createSiteToken = getSubmitFormFn(sendSiteTokenRequest);

  const onSubmit = async (sendFn: (_form: T) => Promise<any>) => {
    setButtonLoadingState(true);
    try {
      const { data, error } = await sendFn(form);
      if (error) {
        processError(error);
      }
      if (data?.token) {
        onFormSuccess(data.token, 'token');
      }
      if (data?.url) {
        onFormSuccess(data.url, 'url');
      }
      setButtonLoadingState(false);
    } catch (error) {
      processError(error);
    }
  };

  return (
    <React.Fragment>
      <div
        sx={{
          display: 'flex',
          '&>*': {
            margin: 1,
          },
        }}
      >
        <PrimaryButton
          data-testid="submit"
          onClick={() => onSubmit(createLinkToken)}
          disabled={disabled}
        >
          {!isLoading && 'Create Link Token'}
          {isLoading && 'Creating Token...'}{' '}
        </PrimaryButton>
        {linkSitesEnabledGate.value ? (
          <Button
            data-testid="submit"
            onClick={() => onSubmit(createSiteToken)}
            // eslint-disable-next-line dot-notation
            disabled={disabled || !form['redirectUrl']}
            customSx={{
              backgroundColor: 'white',
              color: '#4F9ACA',
              '&:disabled': {
                backgroundColor: '#ABB5BB!important',
                color: 'white',
              },
            }}
          >
            {!isLoading && 'Create Sites URL'}
            {isLoading && 'Creating URL...'}
          </Button>
        ) : null}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        width="25rem"
        appElement={document.getElementById('root') as HTMLElement}
      >
        <div
          sx={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <div
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row',
              marginBottom: '1rem',
              justifyContent: 'start',
            }}
          >
            <div
              sx={{
                width: '1rem',
                height: '1rem',
                marginRight: '0.3rem',
                marginTop: '2px',
              }}
            >
              {/* <DangerIcon /> */}
            </div>
            <div
              sx={{
                fontSize: '1.125rem',
                color: '#21292e',
                lineHeight: 1.44,
                fontWeight: 500,
              }}
            >
              Link token could not be created
            </div>
          </div>
          <div
            sx={{
              fontSize: '1.125rem',
              lineHeight: 1.44,
              color: '#727d8b',
              marginBottom: '1.5rem',
            }}
          >
            {errorMessage}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default CreateLinkTokenButton;
