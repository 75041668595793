/** @jsxImportSource theme-ui */
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ThemeUIStyleObject } from 'theme-ui';
import arrows from '../images/arrows.svg';
import Icon from './Icon';
import theme from './theme';

interface IDropdownState {
  isOpen: boolean;
  toggle: () => void;
}

interface IToggleProps {
  showArrows?: boolean;
}

const DropdownCtx = React.createContext({
  isOpen: false,
} as IDropdownState);

export const Toggle: React.FC<IToggleProps> = ({ children, showArrows }) => {
  const { isOpen, toggle } = React.useContext(DropdownCtx);
  return (
    <div
      sx={{
        padding: '0.5rem 1rem',
        borderRadius: 0,
        cursor: 'pointer',
        transitionDuration: '200ms',
        backgroundColor: isOpen ? 'background' : 'white',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'background',
        },
      }}
      onClick={toggle}
    >
      <div
        sx={{
          flex: 1,
        }}
      >
        {children}
      </div>

      {showArrows && <img src={arrows} alt="arrows" />}
    </div>
  );
};

export const Body: React.FC<React.PropsWithChildren<{
  customSx?: ThemeUIStyleObject;
}>> = ({ children, customSx }) => {
  const { isOpen, toggle } = React.useContext(DropdownCtx);
  if (!isOpen) {
    return null;
  }

  return (
    <OutsideClickHandler sx={{ position: 'relative' }} onOutsideClick={toggle}>
      <div
        sx={{
          backgroundColor: 'white',
          zIndex: 100,
          position: 'absolute',
          top: 'calc(100% + 0.25rem)',
          padding: '0.5rem 0rem',
          borderRadius: 0,
          border: 'gray',
          minWidth: '17.5rem',
          left: 0,
          ...(customSx || {}),
        }}
      >
        {children}
      </div>
    </OutsideClickHandler>
  );
};

interface IItemProps {
  onClick?: () => any;
  selected?: boolean;
}

export const Item: React.FC<IItemProps> = ({ selected, children, onClick }) => {
  const { toggle } = React.useContext(DropdownCtx);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    toggle();
  };

  return (
    <div
      onClick={handleClick}
      sx={{
        padding: '0.5rem 1rem',
        height: '2.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        transitionDuration: '200ms',
        '&:hover': {
          backgroundColor: 'background',
        },
      }}
    >
      <div>{children}</div>

      <div>
        {selected && <Icon size="1.5rem" icon="checkmark" color={theme.colors.textSecondary} />}
      </div>
    </div>
  );
};

const Dropdown: React.FC = ({ children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <DropdownCtx.Provider value={{ isOpen, toggle: () => setOpen((open) => !open) }}>
      <div sx={{ position: 'relative' }}>{children}</div>
    </DropdownCtx.Provider>
  );
};

export default Dropdown;
