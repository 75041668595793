import { Card } from '../../../../designs-v2/components/card';
import { JobOutcomeResponse } from '../deposit-switch-type/query';
import { Tooltip, TooltipPopover } from '../../../../designs-v2/components/tooltip/index';
import { getEntries } from '../../../../types/entries';
import { objectEntries } from '@datadog/browser-core';
import { ChartColors } from '../chart-colors';
import { colorTokens } from '../../../../designs-v2/primitives/colors';

const JobsToShow = ['direct_deposit_switch', 'login'];

const JobOutcomeNameMapping: Record<typeof JobsToShow[number], string> = {
  direct_deposit_switch: 'Direct Deposit Switch',
  direct_deposit_allocations: 'Direct Deposit Allocations',
  login: 'Login',
  paystubs: 'Paystubs',
  income: 'Income',
};

function getJobOutcomesAnalytics(jobOutcomes: JobOutcomeResponse) {
  const outcomesByProduct: Record<string, [string, number][]> = {};
  jobOutcomes.forEach((jobOutcome) => {
    if (!JobsToShow.includes(jobOutcome.product)) {
      return;
    }
    const productName = JobOutcomeNameMapping[jobOutcome.product];

    if (!outcomesByProduct[productName]) {
      outcomesByProduct[productName] = [];
    }
    outcomesByProduct[productName].push([jobOutcome.job_outcome, jobOutcome.total_user_count]);

    outcomesByProduct[productName].sort((a, b) => b[0].localeCompare(a[0]));
  });

  const result = getEntries(outcomesByProduct)
    .map(([jobName, outcomes]) => {
      return { jobName, outcomes };
    })
    .sort((a, b) => a.jobName.localeCompare(b.jobName));

  return {
    outcomesByProduct: result,
  };
}

export const OutcomeByJob: React.FC<{ jobOutcomes: JobOutcomeResponse }> = ({ jobOutcomes }) => {
  const { outcomesByProduct } = getJobOutcomesAnalytics(jobOutcomes);

  return (
    <Card
      noBorder
      className={`flex-1 bg-card-bg bg-cover h-full text-content-on-inverse`}
      heading={
        <div className="flex flex-row gap-2 items-center">
          <div className="font-bold text-preset-3">Job Outcomes</div>
          <Tooltip
            tooltipText="Breakdown of results of Pinwheel jobs ran"
            color={colorTokens['content-on-inverse']}
          />
        </div>
      }
      body={<JobOutcomeDisplay outcomesByProduct={outcomesByProduct} />}
    />
  );
};

const ColorByOutcome = {
  success: ChartColors[1],
  failure: ChartColors[0],
  error: ChartColors[3],
  abandoned: ChartColors[4],
};

function capitalizeFirstLetter(str?: string) {
  if (!str || str.length === 0) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const JobOutcomeDisplay: React.FC<{
  outcomesByProduct: { jobName: string; outcomes: [string, number][] }[];
}> = ({ outcomesByProduct }) => {
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-row gap-4 justify-center">
        {getEntries(ColorByOutcome).map(([outcome, color]) => {
          return (
            <div key={outcome} className="flex flex-row gap-2 items-center">
              <div className={`w-3 h-3 rounded`} style={{ backgroundColor: color }} />
              <div>{capitalizeFirstLetter(outcome)}</div>
            </div>
          );
        })}
      </div>
      {outcomesByProduct.map(({ jobName, outcomes }) => {
        const total = outcomes.reduce((acc, [_, count]) => acc + count, 0);

        return (
          <div className="flex flex-col gap-6" key={jobName}>
            <h4 className="text-preset-3">{jobName}</h4>

            <div className="flex flex-row gap-1">
              {outcomes.map(([outcome, count]) => {
                const percentage = Math.round((count / total) * 100);
                const color = ColorByOutcome[outcome];

                return (
                  <div
                    key={`${jobName} - ${outcome}`}
                    className="cursor-pointer has-tooltip relative"
                    style={{
                      flexBasis: `${percentage}%`,
                    }}
                  >
                    <div
                      className={`flex h-12 rounded before:rounded `}
                      style={{
                        backgroundColor: color,
                        filter: `drop-shadow(0 2px 4px ${color})`,
                      }}
                      key={outcome}
                    />
                    <TooltipPopover
                      className="-top-10"
                      tooltipText={`${capitalizeFirstLetter(outcome)}: ${percentage}% (${count})`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
