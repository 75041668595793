import gql from 'graphql-tag';

export const AUTHENTICATE = gql`
  mutation($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      accessToken
      error {
        message
        code
      }
    }
  }
`;
