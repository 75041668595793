/** @jsxImportSource theme-ui */
import RadioGroup, { RadioOption } from '../../uikit/RadioGroup';
import { PrimaryButton, Button } from '../../uikit/buttons';
import Input from '../../uikit/Input';
import Modal from '../../uikit/Modal';
import { get } from 'lodash';
import React, { FormEvent } from 'react';
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { DevDashboardError } from '../../consts/DevDashboardError';
import { INVITE_MEMBER } from '../../graphql/mutations/invite-member';
import { WORKSPACE_MEMBERS } from './TeamMembers';
import { TeamsRadioGroup } from './roles';
import { MemberRole } from '../../consts/models';

const AddWorkspaceMemberModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
}> = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = React.useState('');
  const [inviteMember] = useMutation(INVITE_MEMBER);
  const [role, setRole] = React.useState<MemberRole | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!isOpen) {
      setRole(undefined);
      setEmail('');
      setErrorMessage(null);
    }
  }, [isOpen]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (errorMessage) {
      setErrorMessage(null);
    }
    setLoading(true);
    let hasError = false;
    try {
      const result = await inviteMember({
        variables: {
          email,
          role,
        },
        refetchQueries: [
          {
            variables: {},
            query: WORKSPACE_MEMBERS,
          },
        ],
        awaitRefetchQueries: true,
      });
      const errorMsg = get(result, 'data.inviteMember.error.message', null);
      if (errorMsg) {
        hasError = true;
        const code = get(result, 'data.inviteMember.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }
    } catch (error) {
      const errorMsg = (error as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', { operation: 'inviteMember', errorMsg });
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
      if (!hasError) {
        onRequestClose();
      }
    }
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="30rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        Invite new user
      </div>

      <div
        sx={{
          color: 'textSecondary',
          fontSize: 1,
          marginBottom: '1rem',
        }}
      >
        Enter the email address of the user you’d like to invite, and choose the role they should
        have.
      </div>

      <form onSubmit={handleSubmit}>
        <Input
          id="invite-user-email"
          type="email"
          placeholder="richard.hendricks@piedpiper.com"
          onChange={(submittedEmail) => setEmail(submittedEmail)}
          value={email}
          required
          autoFocus
        />

        <TeamsRadioGroup role={role} setRole={setRole} />

        {errorMessage && (
          <div
            sx={{
              marginTop: '-1rem',
              marginBottom: '1rem',
              color: 'danger',
            }}
          >
            {errorMessage ||
              'An uknown error has occured, please try again later or reach out to us if the problem persists.'}
          </div>
        )}

        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type="button" onClick={onRequestClose}>
            Cancel
          </Button>
          <PrimaryButton type="submit" sx={{ marginLeft: '1rem' }} disabled={loading}>
            {loading ? 'Inviting...' : 'Invite'}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};

export default AddWorkspaceMemberModal;
