/** @jsxImportSource theme-ui */
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

interface IDatePickerProps {
  onChange?: (date: string) => any;
  defaultDate?: string | undefined;
  min?: string | undefined;
  max?: string | undefined;
  customSx?: ThemeUIStyleObject;
}

const DatePicker: React.FC<IDatePickerProps> = ({ defaultDate, onChange, min, max, customSx }) => (
  <div
    sx={{
      ...(customSx != null ? customSx : {}),
    }}
  >
    <input
      type="date"
      onChange={(e) => onChange && onChange(e.target.value)}
      defaultValue={defaultDate}
      min={min}
      max={max}
    />
  </div>
);

export default DatePicker;
