import gql from 'graphql-tag';

export const JOIN_WORKSPACE = gql`
  mutation($invitationKey: String!, $email: String!, $fullName: String!, $password: String!) {
    joinWorkspace(
      email: $email
      fullName: $fullName
      invitationKey: $invitationKey
      password: $password
    ) {
      accessToken
      error {
        message
        code
      }
    }
  }
`;
