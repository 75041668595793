/** @jsxImportSource theme-ui */
import React, { ReactNode } from 'react';

interface ListWithHeaderProps {
  title: string;
  description?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
}

export const ListWithHeader: React.FC<ListWithHeaderProps> = ({
  title,
  description,
  actions,
  children,
}) => {
  return (
    <div
      sx={{
        marginBottom: '3em',
      }}
    >
      <div
        sx={{
          paddingBottom: '0.25em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '.5em',
        }}
      >
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <div
            sx={{
              fontSize: '1.5em',
              fontWeight: 500,
            }}
          >
            {title}
          </div>
          <div
            sx={{
              marginLeft: '1em',
              color: '#828E9D',
              fontSize: '1em',
              position: 'relative',
              top: '3px',
            }}
          >
            {description}
          </div>
        </div>

        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            button: {
              textAlign: 'end',
              cursor: 'pointer',
              float: 'right',
              borderRadius: '4px',
              fontSize: '.9rem',
              fontWeight: 500,
              color: '#515F6E',
              padding: '.5em .7em',
            },
          }}
        >
          {actions}
        </div>
      </div>

      <div
        sx={{
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          backgroundColor: 'white',
          borderRadius: '4px',
        }}
      >
        {children}
      </div>
    </div>
  );
};
