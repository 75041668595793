import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import LoginAuth0 from './pages/LoginAuth0';
import JoinWorkspaceAuth0 from './pages/JoinWorkspaceAuth0';
import VerifyEmail from './pages/VerifyEmail';

const UnauthenticatedAppAuth0 = () => {
  // TODO: join workspace path
  return (
    <Switch>
      <Route exact path="/join/:token" component={JoinWorkspaceAuth0} />
      <Route exact path="/login-auth0" component={LoginAuth0} />
      <Route exact path="/verify-email" component={VerifyEmail} />

      <Redirect to="/login-auth0" />
    </Switch>
  );
};

export default UnauthenticatedAppAuth0;
