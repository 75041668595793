import { time } from 'console';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { InternalFiltersState } from '../filters/state';

type SavedView = {
  name: string;
  filters: InternalFiltersState;
};

const RecentlyUpdatedTimeout = 1000;

type SavedViewsState = {
  views: SavedView[];
  recentlyUpdated: boolean;
};

type Action =
  | {
      type: 'add-view';
      view: SavedView;
    }
  | {
      type: 'reset-recently-updated';
    };

function reducer(state: SavedViewsState, action: Action): SavedViewsState {
  if (action.type === 'add-view') {
    return {
      views: [...state.views, action.view],
      recentlyUpdated: true,
    };
  }

  if (action.type === 'reset-recently-updated') {
    return {
      ...state,
      recentlyUpdated: false,
    };
  }

  return state;
}

const InitialState: SavedViewsState = {
  views: [],
  recentlyUpdated: false,
};

export function useSavedViews() {
  const [state, dispatch] = useReducer(reducer, InitialState);

  return {
    createView: (viewName: string, filters: InternalFiltersState) => {
      dispatch({
        type: 'add-view',
        view: {
          name: viewName,
          filters,
        },
      });

      // setTimeout(() => dispatch({ type: 'reset-recently-updated' }), RecentlyUpdatedTimeout);
    },
    resetNotification: () => dispatch({ type: 'reset-recently-updated' }),
    savedViews: state.views,
    recentlyUpdated: state.recentlyUpdated,
  };
}
