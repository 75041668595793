import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorBoundary from './utils/ErrorBoundary';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { initializeRum } from './tracking/datadogRum';

datadogLogs.init({
  clientToken: 'pub74a772c2299db4bd044b9c721f313d37',
  site: 'datadoghq.com',
  env: process.env.REACT_APP_PINWHEEL_ENVIRONMENT,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});
datadogLogs.addLoggerGlobalContext('hostname', window.location.href);
datadogLogs.addLoggerGlobalContext('service', 'dev-dashboard');
if (process.env.REACT_APP_SENTRY_DSN != null) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_PINWHEEL_ENVIRONMENT,
  });
}
datadogLogs.logger.info('Landed on index');

initializeRum();

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
