import gql from 'graphql-tag';

export const SELF = gql`
  query {
    self {
      id
      firstName
      lastName
      email
      workspaces {
        id
        name
        ownerId
        sandboxAPIKeys {
          id
          apiKey
          apiSecret
          mode
          createdAt
          createdByName
          createdByEmail
          inactiveAt
          lastUsedAt
          isActive
          revokedAt
          revokedByName
          revokedByEmail
        }
        developmentAPIKeys {
          id
          apiKey
          apiSecret
          mode
          createdAt
          createdByName
          createdByEmail
          inactiveAt
          lastUsedAt
          isActive
          revokedAt
          revokedByName
          revokedByEmail
        }
        productionAPIKeys {
          id
          apiKey
          apiSecret
          mode
          createdAt
          createdByName
          createdByEmail
          inactiveAt
          lastUsedAt
          isActive
          revokedAt
          revokedByName
          revokedByEmail
        }
        sandboxEnabledAt
        developmentEnabledAt
        productionEnabledAt
        isDemandPartnerProxy
        demandPartnerProxy
      }
      memberships {
        role
        workspaceId
      }
    }
  }
`;
