import gql from 'graphql-tag';

export const GetReports = gql`
  query {
    reports {
      id
      reportType
      name
      startTime
      endTime
    }
  }
`;
