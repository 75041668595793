import gql from 'graphql-tag';

export const GetSmartBranchUrl = gql`
  query GetSmartBranchUrl($mode: String!, $workspaceId: String!) {
    getWorkspaceSmartBranchUrl(mode: $mode, workspaceId: $workspaceId) {
      id
      smartBranchBaseUrl
    }
  }
`;
