import React, { MouseEventHandler } from 'react';
import { Icon } from '../../primitives/icons';
import { IconType } from '../../primitives/icons/types';
import { Checkbox } from '../form/checkbox';

export type ActionListItemProps = {
  icon?: IconType;
  text: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  active?: boolean;
  selected?: boolean;
  withCheckbox?: boolean;
};

export const ActionListItem = React.forwardRef<HTMLDivElement, ActionListItemProps>(
  ({ text, onClick, icon, active, selected, withCheckbox }, ref) => {
    return (
      <div
        ref={ref}
        className={`
        w-full flex flex-row px-3 py-4 gap-2 text-preset-3 
        cursor-pointer rounded-2xl bg-white hover:bg-neutral-50 focus:bg-gray-50 z-10
        ${active ? 'bg-neutral-50' : ''}`}
        onClick={onClick}
      >
        {withCheckbox && <Checkbox checked={selected} size="sm" />}
        {icon && (
          <div className="my-1 w-4 h-4">
            <Icon type={icon} className="w-4 h-4" />
          </div>
        )}
        {text}
      </div>
    );
  }
);
