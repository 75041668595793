import React from 'react';
import { useIsAuth0Mode } from './auth0';

interface IAuthCtx {
  accessToken: string | null;
  setAccessToken: null | ((accessToken: string | null) => void);
  currentWorkspaceId?: string | null;
  setCurrentWorkspaceId: (workspaceId: string | null) => void;
  setGlobalAuthMessage: (message?: string) => void;
  globalAuthMessage?: string;
}

const AuthCtx = React.createContext<IAuthCtx>({
  accessToken: null,
  setAccessToken: null,
  currentWorkspaceId: null,
  setCurrentWorkspaceId: () => {},
  setGlobalAuthMessage: () => {},
});

const Provider: React.FC = ({ children }) => {
  const {
    REACT_APP_LOCALSTORAGE_ACCESS_TOKEN_ID = 'pinwheelAccessToken',
    REACT_APP_LOCALSTORAGE_WORKSPACE_ID = 'pinwheelWorkspaceId',
  } = process.env;
  const existingAccessToken =
    window.localStorage && window.localStorage.getItem(REACT_APP_LOCALSTORAGE_ACCESS_TOKEN_ID);
  const existingWorkspaceId =
    window.localStorage && window.localStorage.getItem(REACT_APP_LOCALSTORAGE_WORKSPACE_ID);
  const [accessToken, setAccessToken] = React.useState<string | null>(existingAccessToken || null);
  const [workspaceId, setWorkspaceId] = React.useState<string | null>(existingWorkspaceId || null);
  const [globalAuthMessage, setGlobalAuthMessage] = React.useState<string | undefined>(undefined);
  const isAuth0Mode = useIsAuth0Mode();

  React.useEffect(() => {
    if (accessToken) {
      localStorage.setItem(REACT_APP_LOCALSTORAGE_ACCESS_TOKEN_ID, accessToken);
    } else {
      localStorage.removeItem(REACT_APP_LOCALSTORAGE_ACCESS_TOKEN_ID);
      if (!isAuth0Mode) {
        localStorage.removeItem(REACT_APP_LOCALSTORAGE_WORKSPACE_ID);
        setWorkspaceId(null);
      }
    }
  }, [
    REACT_APP_LOCALSTORAGE_ACCESS_TOKEN_ID,
    REACT_APP_LOCALSTORAGE_WORKSPACE_ID,
    accessToken,
    isAuth0Mode,
  ]);

  React.useEffect(() => {
    if (workspaceId) {
      localStorage.setItem(REACT_APP_LOCALSTORAGE_WORKSPACE_ID, workspaceId);
    } else {
      localStorage.removeItem(REACT_APP_LOCALSTORAGE_WORKSPACE_ID);
    }
  }, [REACT_APP_LOCALSTORAGE_WORKSPACE_ID, workspaceId]);

  return (
    <AuthCtx.Provider
      value={{
        accessToken,
        setAccessToken,
        currentWorkspaceId: workspaceId,
        setCurrentWorkspaceId: setWorkspaceId,
        setGlobalAuthMessage,
        globalAuthMessage,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export const useAuth = () => React.useContext(AuthCtx);

export default Provider;
