import { useMemo } from 'react';
import { colorTokens } from '../../primitives/colors';

function getBackground(mode: 'error' | 'success') {
  if (mode === 'success') {
    return 'linear-gradient(270deg, #83C4FF 0%, #055DE1 100%)';
  } else {
    return colorTokens['content-critical'];
  }
}

export const ProgressBar: React.FC<{
  percentage: number;
  mode: 'error' | 'success';
}> = ({ mode, percentage }) => {
  const percentageToShow = useMemo(() => Math.min(100, percentage), [percentage]);

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className={`text-preset-1 text-content-muted ${percentageToShow < 10 ? 'pl-2' : ''}`}>
        {percentageToShow}%
      </div>

      <div className="relative h-2 flex-1 bg-bg-dim rounded max-w-[4rem]">
        <div
          className="absolute left-0 h-full rounded"
          style={{
            width: `${percentageToShow}%`,
            background: getBackground(mode),
          }}
        ></div>
      </div>
    </div>
  );
};
