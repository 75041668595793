/** @jsxImportSource theme-ui */
import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { DevDashboardError, errorMessageForCode } from '../consts/DevDashboardError';
import Modal from '../uikit/Modal';
import { PrimaryButton } from '../uikit/buttons';

const ErrorModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
  error: DevDashboardError | null;
}> = ({ isOpen, onRequestClose, error }) => {
  const [errorMsg, setErrorMsg] = React.useState<string | null>();

  React.useEffect(() => {
    if (!error) {
      setErrorMsg(null);
      return;
    }
    let msg = errorMessageForCode(error.code);
    if (!msg) {
      msg = error.message;
    }
    datadogLogs.logger.info('displayed error to user', {
      operation: error.operation,
      errorMsg: msg,
    });
    setErrorMsg(msg);
  }, [error]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="30rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        Error creating API key
      </div>

      <div
        sx={{
          marginBottom: '1rem',
        }}
      >
        {errorMsg}
      </div>

      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <PrimaryButton type="button" onClick={onRequestClose}>
          Dismiss
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ErrorModal;
