import React from 'react';
import { ErrorMessage, getDisabledClass, getLabelText } from './utils';

interface InputTextProps {
  name: string;
  required?: boolean;
  error?: string;
  label: string;
  value: string;
  type: 'number' | 'text' | 'password' | 'email';
  onChange: (value: string) => void;
  disabled?: boolean;
  subtitleAction?: () => never;
  subtitleText?: string;
  placeholder?: string;
}

export const InputText = (props: InputTextProps) => {
  const {
    name,
    label,
    type,
    onChange,
    value,
    required,
    error,
    disabled,
    subtitleAction,
    subtitleText,
    placeholder
  } = props;
  const spanElement = React.useMemo(() => 
    {
      if (!subtitleText) {
        return null;
      }
      if (subtitleAction) {
        return (
          <span className="subtitle" onClick={() => subtitleAction && subtitleAction()}>
            {subtitleText}
          </span>
        )
      }
      return (
        <span className="subtitle-no-action">
          {subtitleText}
        </span>
      )
    }, [subtitleText, subtitleAction]
  )
  return (
    <div className={`form-field ${getDisabledClass(disabled)}`}>
      <label>
        {getLabelText(label, required)}
        {spanElement}
      </label>
      <input
        data-testid={`input-${name}`}
        id={name}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      <ErrorMessage error={error} />
    </div>
  );
};
