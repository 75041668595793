export const Gradients = {
  Primary: {
    '90Deg': 'bg-gradient-to-r from-[#4D7EFF] to-[#0F52FF]',
    '45Deg': 'bg-gradient-to-br from-[#4D7EFF] to-[#0F52FF]',
    '0Deg': 'bg-gradient-to-t from-[#4D7EFF] to-[#0F52FF]',
  },
  Neutral: {
    '90Deg': 'bg-gradient-to-r from-slate-500 to-slate-600',
    '45Deg': 'bg-gradient-to-br from-slate-500 to-slate-600',
    '0Deg': 'bg-gradient-to-t from-slate-500 to-slate-600',
  },
};
