/** @jsxImportSource theme-ui */
import React from 'react';
import Modal from '../uikit/Modal';
import { PrimaryButton } from '../uikit/buttons';
import { SmartBranchUrl } from '../pages/smart_branch/SmartBranchUrl';

const SmartBranchUrlModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => any;
  url: string
}> = ({ isOpen, onRequestClose, url }) => {


  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      width="75rem"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        {url}
      </div>

      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div sx={{
          padding: '0 1rem',
        }}>
        <SmartBranchUrl url={url} />
        </div>
        <PrimaryButton type="button" onClick={onRequestClose}>
          Close
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default SmartBranchUrlModal;
