import { FetchResult, fromPromise, NextLink, Observable, Operation } from '@apollo/client';
import { ExecutionResult, GraphQLError } from 'graphql';
import { get } from 'lodash';
import { getRedirectReasonMessage, RedirectReasons } from '../consts/redirect-reasons';
import { getNewToken } from '../utils/getNewToken';

interface ErrorResponse {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  networkError?: any;
  response?: ExecutionResult;
  operation: Operation;
  forward: NextLink;
}

export default (
  { operation, forward, response, networkError }: ErrorResponse,
  setAccessToken: null | ((acessToken: string | null) => void),
  setGlobalAuthMessage: (message?: string) => void,
  accessToken: string | null
) => {
  let isRefreshing = false;
  let pendingRequests: Array<() => void> = [];

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
  };

  return (): Observable<FetchResult> | void => {
    const error = get(response, 'errors[0]', null);
    const errorCode = get(error, 'extensions.code', null);
    const isDuplicateSessionError = errorCode === 'DUPLICATE_SESSION';
    if (networkError != null && networkError.message) {
      setGlobalAuthMessage!(getRedirectReasonMessage(RedirectReasons.NetworkError));
      setAccessToken!(null);
      return;
    }
    if (isDuplicateSessionError) {
      setAccessToken!(null);
      setGlobalAuthMessage!(getRedirectReasonMessage(RedirectReasons.DuplicateSession));
      return;
    }
    const isUnauthorizedError = errorCode === 'UNAUTHENTICATED';
    const isPublicRequest: boolean = get(error, 'path', []).some((path: string) => {
      const pathAsString = String(path);
      return (
        pathAsString.match(/authenticate/) ||
        pathAsString.match(/setNewPassword/) ||
        pathAsString.match(/requestPasswordResetEmail/) ||
        pathAsString.match(/signout/) ||
        pathAsString.match(/joinWorkspace/)
      );
    });

    const isUnauthorized = isUnauthorizedError && !isPublicRequest;
    if (isUnauthorized && accessToken != null) {
      let forward$;
      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          getNewToken(accessToken)
            .then((nextAccessToken) => {
              setAccessToken!(nextAccessToken);
              // Store the new tokens for your auth link
              resolvePendingRequests();
              return nextAccessToken;
            })
            .catch(() => {
              pendingRequests = [];
              window.location.href = '/';
              setAccessToken!(null);
            })
            .finally(() => {
              isRefreshing = false;
            })
        ).filter((value) => Boolean(value));
      } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
          new Promise<void>((resolve) => {
            pendingRequests.push(() => resolve());
          })
        );
      }

      return forward$.flatMap(() => forward(operation));
    }
    if (isUnauthorized) {
      window.location.href = '/';
      setAccessToken!(null);
    }
  };
};
