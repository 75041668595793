import gql from 'graphql-tag';

export const REQUEST_PASSWORD_RESET_EMAIL = gql`
  mutation($email: String!) {
    requestPasswordResetEmail(email: $email) {
      success
      error {
        message
        code
      }
    }
  }
`;
