import { useMemo, useState } from 'react';
import { FormField } from '../../../designs-v2/components/form/form-field/index';
import { Select, SelectItemProps } from '../../../designs-v2/components/select/index';
import { colorTokens } from '../../../designs-v2/primitives/colors';
import { FilterType } from '../types';
import { useAnalyticsConfigs } from './getFilterOptionsQuery';
import { FiltersMetadata, InternalFiltersState } from './state';

function getLabelForPlatform(currentValue: string[], options: readonly SelectItemProps[]) {
  if (currentValue.length === 1) {
    return options.find((option) => option.value === currentValue[0])?.label || '';
  }
  return `${currentValue.length} platforms`;
}

export function useOptionLabel<T extends FilterType>(
  type: T,
  currentValue: InternalFiltersState[T],
  options: readonly SelectItemProps[]
) {
  const { label } = FiltersMetadata[type];

  function isEmpty(): boolean {
    if (Array.isArray(currentValue)) return currentValue.length === 0;

    return !currentValue;
  }

  if (isEmpty()) {
    return {
      label: (
        <>
          All <span className="font-bold">{label}s</span>
        </>
      ),
    };
  }

  return {
    label: (
      <div className="flex flex-row gap-1 text-content-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={colorTokens['content-primary']}
            fillRule="evenodd"
            d="M20.707 5.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 15.586 19.293 5.293a1 1 0 0 1 1.414 0Z"
            clipRule="evenodd"
          />
        </svg>

        <span className="font-bold">
          {type === 'platform' && currentValue && getLabelForPlatform(currentValue as any, options)}
        </span>
      </div>
    ),
  };
}

function usePlatformFilterMetadata(currentValue: string[], searchText: string) {
  const { loading, configs } = useAnalyticsConfigs();
  const allOptions = useMemo(() => {
    if (loading) {
      return [];
    }
    return (
      configs?.platforms.map((platform) => ({
        value: platform.platform_id,
        label: platform.platform_name,
      })) ?? []
    );
  }, [loading, configs]);

  const selected = useMemo(() => {
    return currentValue.map((value) => {
      return allOptions.find((option) => option.value === value)!;
    });
  }, [currentValue]);

  // TODO: this is N^2 where N == 1000, potentially slow. Can speed up by using a dict
  const optionsToShow = useMemo(() => {
    const otherOptions = allOptions
      .filter((option) => {
        // fitler out selected
        if (selected.some((selectedOption) => selectedOption.value === option.value)) {
          return false;
        }

        // respect search
        if (option.label.toLocaleLowerCase().includes(searchText)) {
          return true;
        }
      })
      .slice(0, 10 - selected.length);

    return selected.concat(otherOptions);
  }, [allOptions, selected, searchText]);

  const { label } = useOptionLabel('platform', currentValue, allOptions);

  return { label, selected, options: optionsToShow };
}

export const PlatformFilter = ({
  currentValue,
  updateFilter,
}: {
  currentValue: InternalFiltersState['platform'];
  updateFilter: (filterValue: string[]) => void;
}) => {
  const [searchText, setSearchText] = useState('');

  const { label, selected, options } = usePlatformFilterMetadata(
    currentValue,
    searchText.toLocaleLowerCase()
  );

  return (
    <Select
      isMulti
      heading={
        <div className="flex flex-col gap-3 mb-3">
          <div className="text-preset-2 font-bold">Platforms</div>
          <FormField placeholder="Search" onChange={setSearchText} value={searchText} />
        </div>
      }
      label={label}
      items={options}
      selected={selected}
      onSelected={(selected) => {
        updateFilter(selected.map((platform) => platform.value));
        // Reset search text when filter changes
        setSearchText('');
      }}
      className={`${
        currentValue.length === 0 ? '' : 'bg-bg-primary--subtle'
      } hover:bg-bg-primary--muted/50 whitespace-nowrap`}
    />
  );
};
