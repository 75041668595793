/** @jsxImportSource theme-ui */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Base64 } from 'js-base64';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { WORKSPACE_INVITE_INFO } from '../graphql/queries/workspace-invite-info';
import { Loader } from '@pinwheel/origami';
import { datadogLogs } from '@datadog/browser-logs';

type InvitationPayload = {
  workspaceName: string;
  invitationKey: string;
  email: string;
};

// Parses the URL invitation token. Returns null if the token is malformed/invalid.
const useInvitationPayload = (invitationUrlToken): InvitationPayload | null => {
  const invitationPayload = React.useMemo<InvitationPayload>(() => {
    try {
      return JSON.parse(Base64.decode(invitationUrlToken));
    } catch (error) {
      // Parsing error
      datadogLogs.logger.warn('Error when parsing invitation URL token', {
        error: (error as Error).message,
      });
      return null;
    }
  }, [invitationUrlToken]);

  if (
    !invitationPayload?.invitationKey ||
    !invitationPayload?.workspaceName ||
    !invitationPayload?.email
  ) {
    return null;
  }
  return invitationPayload;
};

const JoinWorkspaceAuth0: React.FC<RouteComponentProps<{
  token: string;
}>> = ({ match }) => {
  const invitationPayload = useInvitationPayload(match.params.token);
  const isMalformedInvitationUrl = !invitationPayload;
  const { workspaceName: invitationWorkspaceName, invitationKey, email: invitationEmail } =
    invitationPayload || {};

  const auth0 = useAuth0();
  const { isAuthenticated, isLoading: auth0Loading, user, logout, loginWithRedirect } = auth0;

  const {
    data: workspaceInviteInfoData,
    loading: workspaceInviteInfoLoading,
    error: workspaceInviteInfoError,
  } = useQuery(WORKSPACE_INVITE_INFO, {
    variables: {
      invitationKey,
    },
  });

  const history = useHistory();

  React.useEffect(() => {
    // HACK: An Auth0 error will occur if the domain changes in between redirecting to and from the Auth0 login page.
    if (window.location.href.includes('developer.')) {
      window.location.href = window.location.href.replace('developer.', 'dashboard.');
      // Once the URL is updated, the page will reload, causing useEffect to run again.
      return;
    }
    if (
      auth0Loading ||
      workspaceInviteInfoLoading ||
      isMalformedInvitationUrl ||
      workspaceInviteInfoError
    )
      return;
    if (isAuthenticated) {
      if (user?.email === invitationEmail) {
        // Invite corresponds to the current authenticated user. Since the user is already added to the workspace,
        // redirect to the homepage.
        datadogLogs.logger.info('Join workspace invite matches currently authenticated user', {
          invitationWorkspaceName,
          invitationKey,
          invitationEmail,
        });
        // TODO: should this be a `replace` or `push?
        history.replace('/');
      } else {
        // Current authenticated user is different from the invite's user. In this case, log out and redirect back to
        // the same invitation URL, which should show the Auth0 signup page.
        datadogLogs.logger.info('Join workspace invite mismatches currently authenticated user', {
          invitationWorkspaceName,
          invitationKey,
          invitationEmail,
          email: user?.email,
        });
        logout({
          logoutParams: {
            // Prevents Auth0 from redirecting after logging out the current user. Note: we can't use `returnTo` here
            // because it would fail Auth0's callback URLs check due to the invite token portion of the URL path.
            openUrl: false,
          },
        }).then(() => {
          // Reload the invite to show the correct Auth0 signup/login page.
          window.location.reload();
        });
      }
    } else {
      const { auth0UserExists } = workspaceInviteInfoData?.workspaceInviteInfo || {};
      datadogLogs.logger.info('Join workspace invite with currently unauthenticated session', {
        invitationWorkspaceName,
        invitationKey,
        invitationEmail,
        auth0UserExists,
      });
      void loginWithRedirect({
        authorizationParams: {
          login_hint: invitationEmail,
          redirect_uri: window.location.origin.replace('developer', 'dashboard'),
          // Show either the Auth0 login or signup page depending on whether the invited user has already created an
          // account or not.
          screen_hint: auth0UserExists ? 'login' : 'signup',
        },
      });
    }
  }, [
    isAuthenticated,
    user?.email,
    invitationEmail,
    auth0Loading,
    workspaceInviteInfoLoading,
    isMalformedInvitationUrl,
    workspaceInviteInfoError,
  ]);

  if (isMalformedInvitationUrl) {
    return <div>JoinWorkspaceAuth0 MALFORMED URL PLACEHOLDER</div>;
  }

  if (workspaceInviteInfoError) {
    return <div>JoinWorkspaceAuth0 ERROR PLACEHOLDER {workspaceInviteInfoError.message}</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen h-screen">
      <Loader type="logo" />
    </div>
  );
};

export default JoinWorkspaceAuth0;
