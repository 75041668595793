import React from 'react';
import { get } from 'lodash';

import Workspace from '../uikit/Workspace';

import Dropdown, {
  Body as DropdownBody,
  Item as DropdownItem,
  Toggle as DropdownToggle,
} from '../uikit/Dropdown';
import { useAuth } from '../utils/authentication';
import { useSession } from '../utils/session';
import { User } from '../consts/models';

export interface IWorkspace {
  id: string;
  name: string;
}
interface WorkspaceSwitcherProps {}

const WorkspaceSwitcher: React.FC<WorkspaceSwitcherProps> = () => {
  const session = useSession();

  if (session.loading || !session.data) {
    return null;
  }

  const user = session.data as User;
  const workspaces = get(user, 'workspaces', []);
  const { currentWorkspaceId, setCurrentWorkspaceId } = useAuth();

  React.useEffect(() => {
    if (!currentWorkspaceId && workspaces.length > 0) {
      setCurrentWorkspaceId(workspaces[0].id);
    }
  }, [currentWorkspaceId, workspaces, setCurrentWorkspaceId]);

  if (!currentWorkspaceId) {
    return null;
  }

  const currentWorkspace = workspaces.find((w) => w.id === currentWorkspaceId);
  return (
    <Dropdown>
      <DropdownToggle showArrows>
        {currentWorkspace && <Workspace name={currentWorkspace.name} />}
      </DropdownToggle>

      <DropdownBody>
        {workspaces.map((workspace: IWorkspace) => (
          <DropdownItem
            key={workspace.id}
            onClick={() => setCurrentWorkspaceId(workspace.id)}
            selected={workspace.id === currentWorkspaceId}
          >
            <Workspace name={workspace.name} />
          </DropdownItem>
        ))}
      </DropdownBody>
    </Dropdown>
  );
};

export default WorkspaceSwitcher;
