export const colors = {
  blue: {
    25: '#f5f9ff',
    50: '#e5f0ff',
    100: '#cce0ff',
    200: '#a0c6ff',
    300: '#619efa',
    400: '#1170ff',
    500: '#055de1',
    600: '#0c4eb3',
    700: '#003d99',
    800: '#072d66',
    900: '#041939',
  },
  green: {
    25: '#f8fefc',
    50: '#f3fdfa',
    100: '#d6faf0',
    200: '#aff4e1',
    300: '#86e6d1',
    400: '#68d0b8',
    500: '#5cb8a3',
    600: '#4aa092',
    700: '#408c80',
    800: '#244c47',
    900: '#132826',
  },
  neutral: {
    '00': '#ffffff',
    25: '#fcfcfd',
    50: '#f8f8fa',
    100: '#f0f0f5',
    200: '#dadae7',
    300: '#b6b6ce',
    400: '#8b8bb1',
    500: '#626293',
    600: '#626293',
    700: '#313149',
    800: '#212131',
    900: '#101019',
  },
  purple: {
    25: '#f9f9ff',
    50: '#f2f1ff',
    100: '#e8e6fd',
    200: '#d4d0fd',
    300: '#b6abfc',
    400: '#927ef8',
    500: '#714ef4',
    600: '#602fea',
    700: '#431bad',
    800: '#38198a',
    900: '#241154',
  },
  red: {
    25: '#fffbfb',
    50: '#fcf4f3',
    100: '#fae5e3',
    200: '#f7cfcb',
    300: '#f0a69e',
    400: '#e9786c',
    500: '#de5143',
    600: '#cf4030',
    700: '#a83224',
    800: '#6f231b',
    900: '#33120a',
  },
  yellow: {
    25: '#fefdf1',
    50: '#fefbea',
    100: '#fdf8c9',
    200: '#fcefa0',
    300: '#f9e283',
    400: '#f2c748',
    500: '#e1ad3c',
    600: '#c0882f',
    700: '#b77429',
    800: '#633d18',
    900: '#412610',
  },
};

export const colorTokens = {
  'bg-critical': colors.red['500'],
  'bg-critical--subtle': colors.red['100'],
  'bg-default': colors.neutral['00'],
  'bg-dim': colors.neutral['100'],
  'bg-muted': colors.neutral['50'],
  'bg-primary': colors.blue['500'],
  'bg-primary--muted': colors.blue['50'],
  'bg-primary--subtle': colors.blue['50'],
  'bg-subtle': colors.neutral['25'],
  'bg-success': colors.green['500'],
  'bg-success--subtle': colors.green['100'],
  'bg-warning': colors.yellow['500'],
  'bg-warning--subtle': colors.yellow['100'],
  'bg-inverse': colors.neutral['700'],
  'bg-inverse--subtle': colors.neutral['500'],
  'bg-on-default': colors.neutral['50'],
  'transparent-white': 'rgba(255, 255, 255, 0.12)',

  'border-default': colors.neutral['100'],
  'border-dim': colors.neutral['200'],
  'border-on-inverse': colors.neutral['00'],
  'border-primary': colors.blue['500'],
  'border-subtle': colors.neutral['50'],

  'content-critical': colors.red['600'],
  'content-default': colors.neutral['800'],
  'content-dim': colors.neutral['700'],
  'content-disabled': colors.neutral['300'],
  'content-muted': colors.neutral['600'],
  'content-on-inverse': colors.neutral['00'],
  'content-primary': colors.blue['500'],
  'content-subtle': colors.neutral['400'],
  'content-success': colors.green['700'],
  'content-warning': colors.yellow['700'],
};
