import gql from 'graphql-tag';

export const GetFunnelData = gql`
  query GetFunnnelData(
    $platform: [String!]
    $product: [String!]
    $channel: [String!]
    $start_date: String!
    $end_date: String!
  ) {
    getFunnelAnalytics(
      start_date: $start_date
      end_date: $end_date
      timezone: "America/New_York"
      breakdowns: [TRAFFIC_BY_PLATFORM, TRAFFIC_BY_PRODUCT, TRAFFIC_BY_CHANNEL]
      time_period: null
      filters: { platform_id: $platform, product: $product, channel: $channel }
      ordered_metrics: {
        is_dds_tof_user_count: { index: 0 }
        has_intro_user_count: { index: 1 }
        has_search_impression_user_count: { index: 2 }
        has_selected_platform_user_count: { index: 3 }
        has_login_impression_user_count: { index: 4 }
        has_attempted_login_user_count: { index: 5 }
        has_viewed_mfa_user_count: { index: 6 }
        login_success_user_count: { index: 7 }
        dds_job_attempt_user_count: { index: 9 }
        dds_job_success_user_count: { index: 10 }
        dds_job_error_user_count: { index: 11 }
      }
    ) {
      funnel {
        is_dds_tof_user_count
        has_intro_user_count
        has_search_impression_user_count
        has_selected_platform_user_count
        has_login_impression_user_count
        has_attempted_login_user_count
        has_viewed_mfa_user_count
        login_success_user_count
        dds_job_attempt_user_count
        dds_job_success_user_count
        dds_job_error_user_count
      }
      request {
        start_date
        end_date
        time_period
        timezone
      }
    }
  }
`;

export const GetBreakdowns = gql`
  query GetBreakdowns(
    $platform: [String!]
    $product: [String!]
    $channel: [String!]
    $start_date: String!
    $end_date: String!
  ) {
    getFunnelAnalytics(
      start_date: $start_date
      end_date: $end_date
      timezone: "America/New_York"
      breakdowns: [TRAFFIC_BY_PLATFORM, TRAFFIC_BY_PRODUCT, TRAFFIC_BY_CHANNEL, TRAFFIC_BY_DATE]
      time_period: null
      filters: { platform_id: $platform, product: $product, channel: $channel }
      ordered_metrics: {
        is_dds_tof_user_count: { index: 0 }
        has_intro_user_count: { index: 1 }
        has_search_impression_user_count: { index: 2 }
        has_selected_platform_user_count: { index: 3 }
        has_login_impression_user_count: { index: 4 }
        has_attempted_login_user_count: { index: 5 }
        has_viewed_mfa_user_count: { index: 6 }
        login_success_user_count: { index: 7 }
        dds_job_attempt_user_count: { index: 9 }
        dds_job_success_user_count: { index: 10 }
        dds_job_error_user_count: { index: 11 }
      }
    ) {
      breakdowns {
        breakdown {
          by_platform {
            is_dds_tof_user_count
            has_intro_user_count
            has_search_impression_user_count
            has_selected_platform_user_count
            has_login_impression_user_count
            has_attempted_login_user_count
            has_viewed_mfa_user_count
            login_success_user_count
            has_viewed_dda_page
            dds_job_attempt_user_count
            dds_job_success_user_count
            dds_job_error_user_count
            platform
            platform_logo_url
            platform_picker_logo_url
            platform_id
            platform_status
            platform_status_tooltip
            tooltip
          }
          by_product {
            is_dds_tof_user_count
            has_intro_user_count
            has_search_impression_user_count
            has_selected_platform_user_count
            has_login_impression_user_count
            has_attempted_login_user_count
            has_viewed_mfa_user_count
            login_success_user_count
            has_viewed_dda_page
            dds_job_attempt_user_count
            dds_job_success_user_count
            dds_job_error_user_count
            product
            tooltip
          }
          by_channel {
            is_dds_tof_user_count
            has_intro_user_count
            has_search_impression_user_count
            has_selected_platform_user_count
            has_login_impression_user_count
            has_attempted_login_user_count
            has_viewed_mfa_user_count
            login_success_user_count
            has_viewed_dda_page
            dds_job_attempt_user_count
            dds_job_success_user_count
            dds_job_error_user_count
            channel
            tooltip
          }
          by_date {
            is_dds_tof_user_count
            has_intro_user_count
            has_search_impression_user_count
            has_selected_platform_user_count
            has_login_impression_user_count
            has_attempted_login_user_count
            has_viewed_mfa_user_count
            login_success_user_count
            has_viewed_dda_page
            dds_job_attempt_user_count
            dds_job_success_user_count
            dds_job_error_user_count
            date
            tooltip
          }
        }
      }
      request {
        start_date
        end_date
        time_period
        timezone
      }
    }
  }
`;
