import gql from 'graphql-tag';

export const GetDdsAnalytics = gql`
  query GetDdsAnalyticsBreakdowns(
    $platform: [String!]
    $product: [String!]
    $channel: [String!]
    $start_date: String!
    $end_date: String!
  ) {
    getDdsAnalytics(
      start_date: $start_date
      end_date: $end_date
      timezone: "America/New_York"
      time_period: null
      dds_types: [OVERVIEW, SWITCH_TYPES, JOB_OUTCOMES, JOB_ERRORS]
      filters: { platform_id: $platform, product: $product, channel: $channel }
    ) {
      job_outcomes {
        product
        job_outcome
        total_user_count
      }
      switch_types {
        switch_type
        total_switch_user_count
      }
      job_errors {
        job_error_code
        total_user_count
      }
      request {
        start_date
        end_date
        time_period
        timezone
        dds_types
        filters {
          platform_id
          product
          channel
        }
      }
    }
  }
`;
