import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from 'recharts';
import { colorTokens } from '../../../../designs-v2/primitives/colors';
import { BreakdownByDate } from '../../funnel/query';

// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { Loader } from '@pinwheel/origami';

const CustomTooltip = (props) => {
  // payload[0] doesn't exist when tooltip isn't visible
  if (props.payload[0] != null) {
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload = [
      ...props.payload,
      {
        name: 'Total Users',
        // all your data which created the tooltip is located in the .payload property
        value: props.payload[0].payload['Total Users'],
        // you can also add "unit" here if you need it
        color: colorTokens['content-success'],
      },
    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};

function mapToRechartsFormat(
  row: BreakdownByDate
): {
  date: string;
  'Successful Switches': number;
  'Conversion Rate': number;
  'Total Users': number;
} {
  return {
    date: new Date(row.date).toLocaleDateString(),
    'Successful Switches': row.dds_job_success_user_count,
    'Conversion Rate': Math.round(
      (row.dds_job_success_user_count / row.is_dds_tof_user_count) * 100
    ),
    'Total Users': row.is_dds_tof_user_count,
  };
}

const PageHeight = 484;

export const UsageOverTime: React.FC<{
  data: BreakdownByDate[];
  loading: boolean;
}> = ({ data, loading }) => {
  const dataToRender = React.useMemo(() => {
    const sorted = [...data].sort((row1, row2) => row1.date.localeCompare(row2.date));

    return sorted.map(mapToRechartsFormat);
  }, [data]);

  if (loading) {
    return <Loader type="logo" />;
  }

  return (
    <ResponsiveContainer height={PageHeight}>
      <LineChart
        width={1000}
        height={PageHeight}
        data={dataToRender}
        margin={{
          top: 5,
          bottom: 5,
        }}
        // @ts-expect-error secret prop
        overflow="visible"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          yAxisId="left"
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          label={{
            value: 'Conversion Rate',
            angle: -90,
            position: 'insideLeft',
            className: 'font-bold',
            fill: colorTokens['content-primary'],
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: 'User Count',
            angle: 90,
            position: 'insideRight',
            className: 'font-bold',
            fill: 'black',
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />

        <Line
          yAxisId="left"
          type="monotone"
          dataKey="Conversion Rate"
          stroke={colorTokens['content-primary']}
          dot={false}
          isAnimationActive={false}
          strokeWidth={2}
          unit="%"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="Successful Switches"
          stroke="black"
          strokeWidth={2}
          isAnimationActive={false}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
