import React from 'react';
import { ErrorMessage, getDisabledClass, getLabelText } from './utils';

interface InputTextAreaProps {
  name: string;
  required?: boolean;
  error?: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  subtitleAction?: () => never;
  subtitleText?: string;
}

export const InputTextArea = (props: InputTextAreaProps) => {
  const {
    name,
    label,
    onChange,
    value,
    required,
    error,
    disabled,
    subtitleAction,
    subtitleText,
  } = props;
  return (
    <div className={`form-field ${getDisabledClass(disabled)}`}>
      <label>
        {getLabelText(label, required)}
        {!!subtitleText && (
          <span className="subtitle" onClick={() => subtitleAction && subtitleAction()}>
            {subtitleText}
          </span>
        )}
      </label>
      <textarea
        data-testid={`input-${name}`}
        id={name}
        rows={5}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
        style={{
          resize: 'vertical',
          height: '100%',
          padding: '0.5rem'
        }}
      />
      <ErrorMessage error={error} />
    </div>
  );
};
