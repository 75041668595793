import React from 'react';
import { Card } from '../../../../designs-v2/components/card';
import { Tooltip } from '../../../../designs-v2/components/tooltip';
import { colorTokens } from '../../../../designs-v2/primitives/colors';
import { PieChart } from '../pie-chart';
import { SwitchTypeResponse } from './query';

function capitalizeFirstLetter(str?: string) {
  if (!str || str.length === 0) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const DepositSwitchType: React.FC<{ switchTypes: SwitchTypeResponse }> = ({
  switchTypes,
}) => {
  const total = switchTypes.reduce((acc, curr) => acc + curr.total_switch_user_count, 0);
  const switchTypePieChartValues = switchTypes.map((switchType) => ({
    value: switchType.total_switch_user_count,
    name: capitalizeFirstLetter(switchType.switch_type),
  }));
  switchTypePieChartValues.sort((a, b) => b.value - a.value);

  if (switchTypePieChartValues.length === 0 || switchTypePieChartValues[0].value === 0) {
    return null;
  }

  return (
    <Card
      noBorder
      className={`bg-card-bg bg-cover text-content-on-inverse h-full`}
      heading={
        <div className="flex flex-row gap-2 items-center">
          <div className="font-bold text-preset-3">Deposit Switch Type</div>
          <Tooltip
            tooltipText="Breakdown of switch option selected by users"
            color={colorTokens['content-on-inverse']}
          />
        </div>
      }
      body={<PieChart data={switchTypePieChartValues} total={total} width={400} height={400} />}
    />
  );
};
