/** @jsxImportSource theme-ui */
import React from 'react';
import QRCode from 'react-qr-code';
import { ListRow } from '../../components/layouts/ListRow';
import { SmartBranchUrl } from './SmartBranchUrl';
import { Button } from '../../uikit/buttons';
import { DeleteSmartBranchUrl } from '../../graphql/mutations/smartBranch/delete-smart-branch-url';
import { useMutation } from '@apollo/client';

import SmartBranchUrlModal from '../../components/SmartBranchUrlModal';


type SmartBranchRowProps = {
  name: string;
  url: string;
  urlId: string;
  createdAt: Date;
  refreshList: () => void;
};


export const SmartBranchRow: React.FC<SmartBranchRowProps> = ({ name, url, urlId,refreshList, createdAt }) => {
  const [deleteUrl] = useMutation(DeleteSmartBranchUrl);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleDelete = async (urlId) => {
    try {
      await deleteUrl({ variables: { smartBranchUrlId: urlId } });
      refreshList();
    } catch (err) {
      console.log(err);
    }
  };
  return (<>
    <SmartBranchUrlModal url={url} isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}/>
    <ListRow
      icon="statusActive"
      title={name}
      subtitle={`Created ${createdAt.toLocaleDateString()}`}
      actionButtons={
        <>
          <SmartBranchUrl url={url} />
          <Button icon="magnifyingGlass" onClick={() => setIsModalOpen(true)}>View URL</Button>
          <Button icon="revoke" onClick={() => handleDelete(urlId)}>Delete URL</Button>
          <div sx={{ height: 'auto', marginLeft: '1rem', maxWidth: 50, width: '100%' }}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={url}
              viewBox={`0 0 256 256`}
            />
            
          </div>
        </>
      }
    />
    </>
  );
};
