import React from 'react';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth } from './utils/authentication';
import onErrorHandler from './graphql/onErrorHandler';
import { useIsAuth0Mode } from './utils/auth0';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@pinwheel/origami';

const Provider: React.FC = ({ children }) => {
  const auth = useAuth();
  const accessTokenRef = React.useRef(auth.accessToken);

  if (accessTokenRef.current !== auth.accessToken) {
    accessTokenRef.current = auth.accessToken;
  }

  const setAuthorizationLink = setContext((request, previousContext) => ({
    headers: {
      Authorization: accessTokenRef.current,
      'x-workspace-id': auth.currentWorkspaceId,
      Origin: window.location.origin,
      ...('recaptchaToken' in previousContext ? { 'x-token': previousContext.recaptchaToken } : {}),
    },
  }));

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_SRV_URL}/graphql`,
    fetchOptions: {
      fetchOptions: {
        mode: 'cors',
      },
    },
  });

  const errorLink = onError((params) => {
    onErrorHandler(
      params,
      auth.setAccessToken,
      auth.setGlobalAuthMessage,
      accessTokenRef.current
    )();
  });

  const client = React.useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([setAuthorizationLink, httpLink, errorLink]),
    });
  }, [auth.currentWorkspaceId, auth.setAccessToken, auth.setGlobalAuthMessage]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const ApolloProviderAuth0: React.FC = ({ children }) => {
  const auth = useAuth();
  const accessTokenRef = React.useRef(auth.accessToken);

  if (accessTokenRef.current !== auth.accessToken) {
    accessTokenRef.current = auth.accessToken;
  }

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const setAuthorizationLink = setContext(async (request, previousContext) => ({
    headers: {
      ...(isAuthenticated && {
        'authorization-auth0': await getAccessTokenSilently(),
      }),
      'x-workspace-id': auth.currentWorkspaceId,
      Origin: window.location.origin,
      ...('recaptchaToken' in previousContext ? { 'x-token': previousContext.recaptchaToken } : {}),
    },
  }));

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_SRV_URL}/graphql`,
    fetchOptions: {
      fetchOptions: {
        mode: 'cors',
      },
    },
  });

  const errorLink = onError((params) => {
    onErrorHandler(
      params,
      auth.setAccessToken,
      auth.setGlobalAuthMessage,
      accessTokenRef.current
    )();
  });

  const client = React.useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([setAuthorizationLink, httpLink, errorLink]),
    });
  }, [auth.currentWorkspaceId, auth.setAccessToken, auth.setGlobalAuthMessage, isAuthenticated]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen h-screen">
        <Loader type="logo" />
      </div>
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
