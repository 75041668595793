import { datadogRum } from '@datadog/browser-rum';

export function initializeRum() {
  datadogRum.init({
    applicationId: '18d87cd8-2c8f-4c43-8387-3cbb52d60d69',
    clientToken: 'pub3ac4109c59a0c6bc36b5613ee9c8ba48',
    site: 'datadoghq.com',
    service: 'customer-dashboard',
    env: process.env.REACT_APP_PINWHEEL_ENVIRONMENT,

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
