/** @jsxImportSource theme-ui */
import React from 'react';
import { columnStyles, rowStyles } from '../../consts/styles';
import LaunchLinkButton, { Props as LaunchLinkButtonProps } from './LaunchLinkButton';
import { PrimaryButton } from '../../uikit/buttons';
import { LongTextWithCopyAction } from '../../uikit/LongTextWithCopyAction';

type Props = {
  type: 'url' | 'token';
  linkToken: string;
  sdk_onLogin: LaunchLinkButtonProps['sdk_onLogin'];
  demoModeEnabled?: boolean;
};

export default ({ linkToken, sdk_onLogin, type, demoModeEnabled }: Props) => {
  return (
    <div>
      <div sx={columnStyles}>
        <p
          sx={{
            fontSize: '1.125rem',
            fontWeight: 500,
            lineHeight: 1.44,
            color: '#21292e',
            marginTop: 0,
          }}
        >
          {type === 'token' ? 'Link Token' : 'Sites URL'}
        </p>
        <div
          sx={{
            height: '1px',
            backgroundColor: '#e7ebed',
            width: '100%',
          }}
        />
      </div>
      <div sx={rowStyles}>
        <LongTextWithCopyAction
          iconSize="1.5rem"
          value={linkToken}
          customSx={{
            value: {
              width: '70ch',
            },
          }}
        />
      </div>
      <div sx={rowStyles}>
        {type === 'token' ? (
          <LaunchLinkButton
            token={linkToken}
            sdk_onLogin={sdk_onLogin}
            demoModeEnabled={demoModeEnabled || false}
          />
        ) : (
          <React.Fragment>
            <div />
            <PrimaryButton
              onClick={() => {
                window.open(linkToken, '_blank');
              }}
            >
              Open Sites URL
            </PrimaryButton>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
