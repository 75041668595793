import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { copyTextToClipboard } from '../../utils/general';
import { Button } from '../../uikit/buttons';

type SmartBranchUrlProps = {
  url: string;
};

export const SmartBranchUrl: React.FC<SmartBranchUrlProps> = ({ url }) => {
  const { addToast } = useToasts();

  const handleCopyClick = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        addToast('Copied!', {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button icon="copy" onClick={() => handleCopyClick(url)}>
      Copy URL
    </Button>
  );
};
