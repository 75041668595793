import { CustomerInsightsCard } from './tools-card';

export const ToolsPage = () => {
  return (
    <div className="flex flex-col gap-8 flex-1">
      <div className="flex flex-row justify-center py-8 border-b">
        <div className="flex flex-col gap-2 items-start min-w-[1000px]">
          <h1 className="text-preset-7 font-bold">Growth Tools</h1>

          <h5 className="text-preset-2 text-content-dim">
            Maximize your profit and client retention with Pinwheel Growth Tools
          </h5>
        </div>
      </div>

      <div className="flex flex-row gap-8 min-w-[1000px] self-center">
        <CustomerInsightsCard type="CustomerSegmentation" />
        <CustomerInsightsCard type="CustomerList" />
      </div>

      <div className="flex flex-row gap-8 min-w-[1000px] self-center">
        <CustomerInsightsCard type="CreditWorthiness" />
        <CustomerInsightsCard type="CompetitiveAnalysis" />
      </div>

      <div className="flex flex-row gap-8 min-w-[1000px] self-center">
        <CustomerInsightsCard type="Incentives" />
        {/* <CustomerInsightsCard title="Competitive Analysis" /> */}
      </div>
    </div>
  );
};
