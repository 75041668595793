import { HTMLInputTypeAttribute } from 'react';

export type FormFieldProps = {
  isDisabled?: boolean;
  placeholder: string;
  value: string | undefined;
  onChange: (value: string) => void;
  type?: HTMLInputTypeAttribute;
};

export const FormField: React.FC<FormFieldProps> = ({
  value,
  onChange,
  placeholder,
  isDisabled,
  type = 'text',
}) => {
  return (
    <div className="flex flex-row w-full">
      <input
        className="flex-1 py-2.5 px-3.5 rounded-xl border border-border-default"
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
