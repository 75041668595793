import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { numberFormatter } from '../../funnel';
import { ChartColors } from '../chart-colors';
import { TooltipPopover } from '../../../../designs-v2/components/tooltip';

type PieChartProps = {
  width: number;
  height: number;
  total: number;
  data: { name: string; value: number }[];
};

const MARGIN = 10;

const colors = ChartColors;

type DataItem = {
  name: string;
  value: number;
};

export const PieChart = ({ width, height, data, total }: PieChartProps) => {
  const radius = Math.min(width, height) / 2 - MARGIN;

  const pie = React.useMemo(() => {
    const pieGenerator = d3
      .pie<any, DataItem>()
      .value((d) => d.value)
      .padAngle(0.05);
    return pieGenerator(data);
  }, [data]);

  const arcs = useMemo(() => {
    const arcPathGenerator = d3.arc();
    return pie.map((p) =>
      arcPathGenerator({
        innerRadius: radius * 0.6,
        outerRadius: radius,
        startAngle: p.startAngle,
        endAngle: p.endAngle,
        padAngle: p.padAngle,
      })
    );
  }, [radius, pie]);

  return (
    <div className="flex flex-row gap-4">
      <svg width={width} height={height} style={{ display: 'inline-block' }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {arcs.map((arc, i) => {
            return (
              <path
                className="has-tooltip cursor-pointer relative"
                key={i}
                d={arc || undefined}
                fill={colors[i]}
                style={{
                  filter: `drop-shadow(0 2px 4px ${colors[i]})`,
                }}
              >
                <TooltipPopover className="-top-8" tooltipText={`${data[i].name}`} />
              </path>
            );
          })}
        </g>
      </svg>

      <div className="flex flex-col gap-2 justify-center">
        {data.map((dataType, i) => (
          <div key={dataType.name} className="flex flex-row gap-2 items-baseline">
            <div className="w-3 h-3 rounded-xl" style={{ backgroundColor: colors[i] }} />
            <div>{dataType.name}</div>
            <div className="flex flex-row gap-0.5 items-baseline">
              <div className="text-preset-2 font-bold">
                {Math.round((dataType.value / total) * 100)}%
              </div>
              <div className="text-preset-1">({numberFormatter.format(dataType.value)})</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
