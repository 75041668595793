import { ButtonConfig, ButtonType } from './types';
import { Gradients } from '../../gradients';

const PrimaryConfig: ButtonConfig = {
  default: {
    background: Gradients.Primary['0Deg'],
    textColor: 'text-content-on-inverse',
    borderColor: 'border border-border-primary',
    elevation: 'shadow-elev-sm',
  },
  hover: {
    background: 'hover:bg-gradient-to-tr',
    textColor: 'hover:text-content-on-inverse',
    borderColor: 'hover:border border-primary',
    elevation: 'hover:shadow-elev-xs',
  },
  focus: {
    background: 'focus:ring',
    textColor: 'focus:text-content-on-inverse',
    borderColor: 'focus:border-primary',
    elevation: 'shadow-elev-sm',
  },
  active: {
    background: 'active:bg-gradient-to-tr',
    textColor: 'active:text-content-on-inverse',
    borderColor: '',
    elevation: 'active:shadow-elev-xs',
  },
  disabled: {
    background: 'disabled:bg-bg-dim',
    textColor: 'disabled:text-content-disabled',
    borderColor: '',
  },
};

const TonalConfig: ButtonConfig = {
  default: {
    background: 'bg-bg-muted',
    textColor: 'text-content-dim',
    borderColor: '',
  },
  hover: {
    background: 'hover:bg-bg-dim',
    textColor: 'hover:text-content-dim',
    borderColor: '',
  },
  focus: {
    background: 'focus:ring',
    textColor: 'focus:text-content-dim',
    borderColor: '',
  },
  active: {
    background: 'active:bg-bg-dim',
    textColor: 'active:text-content-dim',
    borderColor: '',
  },
  disabled: {
    background: 'disabled:bg-bg-dim',
    textColor: 'disabled:text-content-disabled',
    borderColor: '',
  },
};

const OutlineConfig: ButtonConfig = {
  default: {
    background: 'bg-bg-default',
    textColor: 'text-content-dim',
    borderColor: 'border border-default',
  },
  hover: {
    background: 'hover:bg-bg-muted',
    textColor: 'hover:text-content-dim',
    borderColor: '',
  },
  focus: {
    background: 'focus:ring',
    textColor: 'focus:text-content-dim',
    borderColor: '',
  },
  active: {
    background: 'active:bg-bg-muted',
    textColor: 'active:text-content-dim',
    borderColor: '',
  },
  disabled: {
    background: 'disabled:bg-bg-dim',
    textColor: 'disabled:text-content-disabled',
    borderColor: '',
  },
};

const TextPrimaryConfig: ButtonConfig = {
  default: {
    background: '',
    borderColor: '',
    textColor: 'text-content-primary',
  },
  hover: {
    background: 'hover:bg-bg-muted',
    borderColor: '',
    textColor: 'hover:text-content-primary',
  },
  focus: {
    background: 'focus:ring',
    borderColor: '',
    textColor: 'focus:text-content-primary',
  },
  active: {
    background: 'active:bg-bg-muted',
    borderColor: '',
    textColor: 'active:text-content-primary',
  },
  disabled: {
    background: 'disabled:bg-bg-dim',
    borderColor: '',
    textColor: 'disabled:text-content-disabled',
  },
};

const TextTonalConfig: ButtonConfig = {
  default: {
    background: '',
    borderColor: '',
    textColor: 'text-content-dim',
  },
  hover: {
    background: 'hover:bg-bg-muted',
    borderColor: '',
    textColor: 'hover:text-content-dim',
  },
  focus: {
    background: 'focus:ring',
    borderColor: '',
    textColor: 'focus:text-content-dim',
  },
  active: {
    background: 'active:bg-bg-muted',
    borderColor: '',
    textColor: 'active:text-content-dim',
  },
  disabled: {
    background: 'disabled:bg-bg-dim',
    borderColor: '',
    textColor: 'disabled:text-content-disabled',
  },
};

export const ButtonConfigs: Record<ButtonType, ButtonConfig> = {
  primary: PrimaryConfig,
  tonal: TonalConfig,
  outline: OutlineConfig,
  text_primary: TextPrimaryConfig,
  text_tonal: TextTonalConfig,
};
