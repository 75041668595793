/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import Link from '../uikit/Link';
import Card from '../uikit/Card';
import { PrimaryButton } from '../uikit/buttons';
import Input from '../uikit/Input';
import { Field } from '../uikit/forms';
import { get } from 'lodash';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from '../utils/authentication';
import { DevDashboardError } from '../consts/DevDashboardError';
import { AUTHENTICATE } from '../graphql/mutations/auth';
import logo from '../images/pinwheel-logo.svg';
import { isEmailValid } from '../utils/validations';

const LoginPage: React.FC = () => {
  const [error, setError] = React.useState<null | string>(null);
  const [authenticate] = useMutation(AUTHENTICATE);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [recaptchaToken, setRecaptchaToken] = React.useState<null | string>(null);
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.globalAuthMessage != null) {
      setError(auth.globalAuthMessage);
    }
  }, [auth, setError]);

  const onRecaptchaChangeHandler = React.useCallback(
    (token: string | null) => {
      setRecaptchaToken(!token ? '' : token);
    },
    [setRecaptchaToken]
  );

  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const handleSubmit = React.useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }
      setError(null);

      try {
        const result = await authenticate({
          variables: {
            email,
            password,
          },
          context: {
            recaptchaToken,
          },
        });
        const token = get(result, 'data.authenticate.accessToken', null);

        if (!token) {
          const errorMsg = get(
            result,
            'data.authenticate.error.message',
            'Authentication failed, make sure your credentials are correct.'
          );
          const code = get(result, 'data.authenticate.error.code', null);
          throw new DevDashboardError(errorMsg, code);
        }
        auth.setAccessToken!(token);

        datadogLogs.logger.info('Authentication succeeded', {
          trackingIds: { email },
        });
      } catch (caughtError) {
        onRecaptchaChangeHandler(null);
        (window as any).grecaptcha.reset(recaptchaRef.current?.getWidgetId());

        datadogLogs.logger.info('Authentication failed with error', {
          error: (caughtError as Error).message,
          trackingIds: { email },
        });

        const errorMsg =
          caughtError != null && (caughtError as Error).message != null
            ? (caughtError as Error).message.replace('GraphQL error: ', '')
            : 'Authentication failed due to the server connection, contact support.';
        datadogLogs.logger.info('displayed error to user', { operation: 'authenticate', errorMsg });
        return setError(errorMsg);
      }
    },
    [
      email,
      password,
      auth.setAccessToken,
      authenticate,
      recaptchaToken,
      onRecaptchaChangeHandler,
      recaptchaRef,
    ]
  );

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        <div sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <img alt="Pinwheel" src={logo} />
        </div>
        <Card
          sx={{
            width: '25rem',
          }}
        >
          <div
            sx={{
              fontSize: 1,
              fontWeight: '500',
              marginBottom: '2rem',
            }}
          >
            Sign in to your Pinwheel account
          </div>

          <form onSubmit={handleSubmit}>
            <Field label="Email" htmlFor="email">
              <Input
                id="email"
                onChange={(e) => {
                  setEmail(e);
                }}
                value={email}
                type="email"
                required
              />
            </Field>

            <Field label="Password" htmlFor="password" sx={{ marginBottom: '2rem' }}>
              <Input
                id="password"
                type="password"
                onChange={(p) => setPassword(p)}
                value={password}
                required
              />
            </Field>

            {error && (
              <div
                sx={{
                  marginTop: '-0.5rem',
                  color: 'danger',
                  marginBottom: '1rem',
                }}
              >
                {error}
              </div>
            )}

            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column',
                width: '100%',
                margin: error ? '1rem 0 2rem 0' : '2rem 0',
              }}
            >
              <div sx={{ width: 'auto', margin: 'auto' }}>
                <ReCAPTCHA
                  sitekey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
                  onChange={onRecaptchaChangeHandler}
                  onExpired={() => setRecaptchaToken(null)}
                  onErrored={() => setRecaptchaToken(null)}
                  ref={recaptchaRef}
                />
              </div>
            </div>

            <PrimaryButton
              block
              type="submit"
              disabled={!recaptchaToken || !isEmailValid(email) || !password.length}
            >
              Log in
            </PrimaryButton>

            <div sx={{ textAlign: 'center', paddingTop: '1rem' }}>
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
