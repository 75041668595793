import gql from 'graphql-tag';

export const UPDATE_USER_PROFILE = gql`
  mutation($firstName: String, $lastName: String) {
    updateUserProfile(firstName: $firstName, lastName: $lastName) {
      error {
        message
        code
      }
    }
  }
`;
