/** @jsxImportSource theme-ui */
import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import Icon, { SupportedIcons, iconColors } from '../../uikit/Icon';

const RowStyle: ThemeUIStyleObject = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  border: '1px solid #DBE5F0',
  borderRadius: '4px',
  padding: '1em 1.2em',
};

type ListRowProps = {
  icon: SupportedIcons;
  title: string;
  subtitle: string | React.ReactNode;
  actionButtons: React.ReactNode;
  strikethroughTitle?: boolean;
};

export const ListRow: React.FC<ListRowProps> = ({
  actionButtons,
  icon,
  title,
  strikethroughTitle,
  subtitle,
}) => {
  return (
    <div sx={RowStyle}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <Icon size=".6rem" icon={icon} color={iconColors[icon]} transitionDuration="0ms" />
        <div
          title={title}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            flex: 1,
            marginLeft: '.7rem',
          }}
        >
          <code
            sx={{
              fontFamily: "'Roboto Mono', monospace, 'Circular'",
              fontWeight: 700,
              textDecorationLine: strikethroughTitle ? 'line-through' : 'none',
            }}
          >
            {title}
          </code>
          <span
            sx={{
              fontSize: '.7em',
              color: '#8497AB',
            }}
          >
            {subtitle}
          </span>
        </div>
      </div>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          button: {
            fontWeight: 800,
            marginLeft: '.5rem',
            fontSize: '.8rem',
            color: '#8497AB',
            padding: '.2em .5em',
            height: '2em',
            div: {
              div: {
                svg: {
                  path: {
                    fill: '#8497AB',
                  },
                },
              },
            },
          },
        }}
      >
        {actionButtons}
      </div>
    </div>
  );
};
