import React from 'react';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';

const CardIcon: React.FC<{ color: string; bgColorClassName: string }> = ({
  color,
  bgColorClassName,
}) => {
  return (
    <div className="flex items-center justify-center p-1 border rounded-xl">
      <div className={`flex items-center justify-center p-3 rounded-xl ${bgColorClassName}`}>
        <svg
          className="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33357 3.33329C6.72273 3.33329 5.4169 4.63913 5.4169 6.24996C5.4169 7.86079 6.72273 9.16663 8.33357 9.16663C9.9444 9.16663 11.2502 7.86079 11.2502 6.24996C11.2502 4.63913 9.9444 3.33329 8.33357 3.33329ZM3.75023 6.24996C3.75023 3.71865 5.80226 1.66663 8.33357 1.66663C10.8649 1.66663 12.9169 3.71865 12.9169 6.24996C12.9169 8.78126 10.8649 10.8333 8.33357 10.8333C5.80226 10.8333 3.75023 8.78126 3.75023 6.24996ZM15.8336 11.6666C16.2938 11.6666 16.6669 12.0397 16.6669 12.5V14.1666H18.3336C18.7938 14.1666 19.1669 14.5397 19.1669 15C19.1669 15.4602 18.7938 15.8333 18.3336 15.8333H16.6669V17.5C16.6669 17.9602 16.2938 18.3333 15.8336 18.3333C15.3733 18.3333 15.0002 17.9602 15.0002 17.5V15.8333H13.3336C12.8733 15.8333 12.5002 15.4602 12.5002 15C12.5002 14.5397 12.8733 14.1666 13.3336 14.1666H15.0002V12.5C15.0002 12.0397 15.3733 11.6666 15.8336 11.6666ZM6.10545 12.0833C6.15301 12.0833 6.20126 12.0833 6.25024 12.0833H10.0002C10.4605 12.0833 10.8336 12.4564 10.8336 12.9166C10.8336 13.3769 10.4605 13.75 10.0002 13.75H6.25024C5.02664 13.75 4.59957 13.759 4.27452 13.8576C3.47552 14.1 2.85026 14.7252 2.60788 15.5242C2.50928 15.8493 2.50023 16.2764 2.50023 17.5C2.50023 17.9602 2.12714 18.3333 1.6669 18.3333C1.20666 18.3333 0.833565 17.9602 0.833565 17.5C0.833565 17.451 0.833548 17.4027 0.83353 17.3552C0.833152 16.3325 0.832893 15.6341 1.01298 15.0404C1.41694 13.7088 2.45904 12.6667 3.79071 12.2627C4.38438 12.0826 5.08273 12.0829 6.10545 12.0833Z"
            fill={color}
          />
        </svg>
      </div>
    </div>
  );
};

const ToolsCard: React.FC<{
  icon: ReactNode;
  content: ReactNode;
  route: string;
  disabled: boolean;
}> = ({ icon, content, route, disabled }) => {
  const history = useHistory();

  return (
    <div className="flex flex-col border rounded-xl basis-1/2">
      <div className="flex flex-row gap-6 p-6">
        <div>
         {icon}
        </div>
        <div className="flex flex-col gap-2">{content}</div>
      </div>

      <div className="flex flex-row px-6 py-4 border-t justify-start bg-bg-subtle rounded-b-xl">
        <button
          disabled={disabled}
          className={` rounded-xl p-2 border font-bold ${
            disabled ? 'cursor-not-allowed bg-bg-muted' : 'bg-white'
          }`}
          onClick={() => history.push(route)}
        >
          View
        </button>
      </div>
    </div>
  );
};
type CardProps = {
  title: string;
  description: string;
  color: string;
  bgColorClassName: string;
  route: string;
  disabled?: boolean;
};

export const CustomerInsightsCard = ({ type }: { type: keyof typeof Cards }) => {
  const { title, description, color, bgColorClassName, route, disabled } = Cards[type];

  return (
    <ToolsCard
      icon={<CardIcon color={color} bgColorClassName={bgColorClassName} />}
      content={
        <>
          <h3 className="text-preset-3 font-bold text-content-default">{title}</h3>
          <p className="text-preset-2 text-content-subtle">
            {disabled ? 'Coming Soon' : description}
          </p>
        </>
      }
      route={route}
      disabled={disabled || false}
    />
  );
};

const Cards = {
  CustomerSegmentation: {
    color: '#055DE1',
    bgColorClassName: 'bg-bg-primary--muted',
    title: 'Customer Segmentation',
    route: '/tools/segments',
    description: 'View your latest Customer Insights',
    disabled: false,
  },
  CreditWorthiness: {
    color: '#04A5C8',
    bgColorClassName: 'bg-[#CEF4FD]',
    title: 'Credit Worthiness',
    route: '/tools/insights',
    description: 'View your latest Customer Insights',
    disabled: true,
  },
  CustomerList: {
    color: '#330DF2',
    bgColorClassName: 'bg-[#E6E2FD]',
    title: 'Customer List',
    route: '/tools/users',
    description: 'View your latest Customer Insights',
    disabled: false,
  },
  CompetitiveAnalysis: {
    color: '#676798',
    bgColorClassName: 'bg-[#EDEDF3]',
    title: 'Competitive Analysis',
    route: '/tools/insights',
    description: 'View your latest Customer Insights',
    disabled: true,
  },
  Incentives: {
    color: '#330DF2',
    bgColorClassName: 'bg-[#E6E2FD]',
    title: 'Incentives',
    route: '/tools/insights',
    description: 'View your latest Customer Insights',
    disabled: true,
  },

  UpsellSegment: {
    color: '#055DE1',
    bgColorClassName: 'bg-bg-primary--muted',
    title: 'Upsell',
    route: '/tools/users?segment=Upselling',
    description: 'This segment of users is likely to buy more products',
    disabled: false,
  },
  CrossSellSegment: {
    color: '#04A5C8',
    bgColorClassName: 'bg-[#CEF4FD]',
    title: 'Cross-Sell',
    route: '/tools/users?segment=Cross-Sell',
    description: 'Improve retention and loyalty by targeting this segment of users',
    disabled: false,
  },
  RetainSegment: {
    color: '#330DF2',
    bgColorClassName: 'bg-[#E6E2FD]',
    title: 'Retain',
    route: '/tools/users?segment=Retain',
    description: 'Improve retention and loyalty by targeting this segment of users',
    disabled: false,
  },

} as const satisfies Record<string, CardProps>;

