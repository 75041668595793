import gql from 'graphql-tag';

export const ADD_EMPLOYER_MATCHING_JOB = gql`
  mutation($key: String!) {
    addEmployerMatchingJob(key: $key) {
      success
      error {
        message
        code
      }
    }
  }
`;
