export const FunnelSeparator = () => (
  <div
    style={{
      opacity: 0.5,
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 57.29%, rgba(255, 255, 255, 0.00) 100%)',
    }}
    className="min-w-[2px] h-100"
  />
);
