import gql from 'graphql-tag';

export const GET_UPLOAD_EMPLOYERS_PARAMS = gql`
  query($filename: String!) {
    uploadParams(filename: $filename) {
      success
      url
      fields
    }
  }
`;
