/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { InputText } from '../../../components/Form/InputText';
import { InputSelect } from '../../../components/Form/InputSelect';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ModeKey } from '../../../components/TestConsole/form-helpers';
import { PrimaryButton } from '../../../uikit/buttons';
import { useAvailableModes, useRequiredWorkspace } from '../../../hooks/useWorkspace';

const GET_PROXY_CONFIG_QUERY = gql`
  query DemandPartnerProxyConfig {
    demandPartnerProxyConfig {
      id
      mode
      clientId
      clientSecret
      onlineAppUrl
      linkDisplayName
    }
  }
`;

const UPDATE_PROXY_CONFIG_MUTATION = gql`
  mutation(
    $demandPartnerProxyId: String!
    $mode: String
    $clientId: String
    $clientSecret: String
    $onlineAppUrl: String
    $linkDisplayName: String
  ) {
    updateDemandPartnerProxyConfig(
      demandPartnerProxyId: $demandPartnerProxyId
      mode: $mode
      clientId: $clientId
      clientSecret: $clientSecret
      onlineAppUrl: $onlineAppUrl
      linkDisplayName: $linkDisplayName
    ) {
      success
      error {
        message
        code
      }
    }
  }
`;

const BannoConfigForm = ({
  demandPartnerProxyId,
  initialClientId,
  initialClientSecret,
  initialOnlineAppUrl,
  initialLinkDisplayName,
  initialMode,
  availableModes,
  workspaceName,
  refetchData,
}) => {
  const [clientId, setClientId] = useState(initialClientId);
  const [clientSecret, setClientSecret] = useState(initialClientSecret);
  const [onlineAppUrl, setOnlineAppUrl] = useState(initialOnlineAppUrl);
  const [linkDisplayName, setLinkDisplayName] = useState(initialLinkDisplayName);
  // Default mode to `sandbox` if no value is set
  const [mode, setMode] = useState<ModeKey>(initialMode || 'sandbox');

  const [updateProxyConfig] = useMutation(UPDATE_PROXY_CONFIG_MUTATION);
  const [isUpdateRequestLoading, setIsUpdateRequestLoading] = useState(false);
  const [updateRequestErrorMessage, setUpdateRequestErrorMessage] = useState(null);

  return (
    <div
      sx={{
        backgroundColor: 'white',
        padding: '3rem 4rem',
        borderRadius: 0,
        boxShadow: 'main',
        // TODO: allow width to go below 500 on smaller screens
        width: 500,
        marginTop: '2rem',
        height: 'fit-content',
      }}
    >
      <div
        sx={{
          fontSize: 2,
          fontWeight: 500,
          marginBottom: '2.5rem',
        }}
      >
        Banno Config
      </div>

      <InputText
        name={'client_id'}
        label={'client_id'}
        value={clientId}
        type={'text'}
        required={true}
        onChange={setClientId}
      />
      <InputText
        name={'client_secret'}
        label={'client_secret'}
        value={clientSecret}
        type={'text'}
        required={true}
        onChange={setClientSecret}
      />
      <InputText
        name={'online_app_url'}
        label={'online_app_url'}
        value={onlineAppUrl}
        type={'text'}
        required={true}
        onChange={setOnlineAppUrl}
      />
      <div sx={{ marginBottom: '2rem' }}>
        <InputSelect
          name={'mode'}
          label={'mode'}
          value={{ value: mode, label: mode }}
          options={availableModes.map((value) => ({ value, label: value }))}
          required={true}
          onChangeHandler={(newMode) => {
            setMode(newMode as ModeKey);
          }}
          error={
            initialMode === 'production' && mode !== 'production'
              ? 'This will break the plugin; please make sure to remove the plugin from your platform before you change this.'
              : undefined
          }
        />
      </div>

      <InputText
        name={'link_display_name'}
        label={'link_display_name'}
        placeholder={workspaceName}
        value={linkDisplayName}
        type={'text'}
        required={false}
        onChange={setLinkDisplayName}
      />

      <PrimaryButton
        disabled={isUpdateRequestLoading}
        onClick={async () => {
          setIsUpdateRequestLoading(true);
          setUpdateRequestErrorMessage(null);
          const updateResult = await updateProxyConfig({
            variables: {
              demandPartnerProxyId,
              mode,
              clientId,
              clientSecret,
              onlineAppUrl,
              // Set to `null` if an empty string is passed
              linkDisplayName: linkDisplayName || null,
            },
          });
          await refetchData();
          setIsUpdateRequestLoading(false);
          const errorMessage = updateResult.data?.updateDemandPartnerProxyConfig?.error;
          if (errorMessage) {
            setUpdateRequestErrorMessage(errorMessage);
          }
        }}
        sx={{ flex: '0 1 auto' }}
      >
        {!isUpdateRequestLoading && 'Update Config'}
        {isUpdateRequestLoading && 'Updating...'}
      </PrimaryButton>

      <div
        sx={{
          color: '#CC3D3D',
          marginBottom: '1.5rem',
        }}
      >
        {updateRequestErrorMessage}
      </div>
    </div>
  );
};

const BannoConfigPage = () => {
  const workspace = useRequiredWorkspace();
  const availableModes = useAvailableModes(workspace);
  const { data, refetch: refetchData } = useQuery(GET_PROXY_CONFIG_QUERY);
  const { demandPartnerProxyConfig } = data || {};

  useEffect(() => {
    // Redirect to homepage if user switches to a non-Banno workspace
    if (workspace.demandPartnerProxy !== 'Banno') {
      window.location.href = '/';
    }
  }, [workspace.demandPartnerProxy]);

  if (demandPartnerProxyConfig) {
    const {
      id,
      clientId,
      clientSecret,
      linkDisplayName,
      mode,
      onlineAppUrl,
    } = demandPartnerProxyConfig;
    return (
      <BannoConfigForm
        demandPartnerProxyId={id}
        initialClientId={clientId}
        initialClientSecret={clientSecret}
        initialLinkDisplayName={linkDisplayName}
        initialOnlineAppUrl={onlineAppUrl}
        initialMode={mode}
        availableModes={availableModes}
        workspaceName={workspace.name}
        refetchData={refetchData}
      />
    );
  }

  // TODO: update loading screen
  return <div>Loading...</div>;
};

export default BannoConfigPage;
