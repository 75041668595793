import gql from 'graphql-tag';

export const EMPLOYERS_NEW = gql`
  query($search: String) {
    employers(search: $search) {
      supportedJobs
      id
      name
      fractionalAmountSupported
      amountSupported
      minAmount
      maxAmount
      logo
    }
  }
`;
