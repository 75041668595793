/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { get } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { DevDashboardError } from '../consts/DevDashboardError';
import { APIKey, Mode } from '../consts/models';
import { REVOKE_KEY } from '../graphql/mutations/revoke-key';
import Modal from '../uikit/Modal';
import { Button, DangerButton } from '../uikit/buttons';
import { capitalize } from '../utils/general';
import { useSession } from '../utils/session';

const RevokeKeyModal: React.FC<{
  isOpen: boolean;
  mode: Mode;
  onRequestClose: () => any;
  setError: Dispatch<SetStateAction<DevDashboardError | null>>;
  keyToRevoke: APIKey | null;
}> = ({ isOpen, mode, onRequestClose, setError, keyToRevoke }) => {
  const [revokeKey] = useMutation(REVOKE_KEY);
  const [loading, setLoading] = React.useState(false);
  const { refreshSession } = useSession();

  const handleRevoke = async () => {
    setLoading(true);
    try {
      const result = await revokeKey({
        variables: {
          mode,
          key: keyToRevoke?.apiKey,
        },
      });
      const errorMsg = get(result, 'data.revokeAPIKey.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.revokeAPIKey.error.code', null);
        throw new DevDashboardError(errorMsg, code, 'revokeAPIKey');
      }
      const inactiveAt = get(result, 'data.revokeAPIKey.inactiveAt', null);
      refreshSession();

      datadogLogs.logger.info('API key revoked', {
        revokedKey: keyToRevoke?.apiKey,
        inactiveAt: inactiveAt,
        mode,
      });
    } catch (error) {
      if (error instanceof DevDashboardError) {
        setError(error);
      } else {
        setError(DevDashboardError.fromException(error as Error, 'revokeAPIKey'));
      }
    } finally {
      setLoading(false);
      onRequestClose();
    }
  };

  return (
    <Modal
      onRequestClose={() => {
        window.location.reload();
        onRequestClose();
      }}
      isOpen={isOpen}
      width="30rem"
      appElement={document.getElementById('root') as HTMLElement}
      customStyles={{
        content: {
          cursor: loading ? 'wait' : 'auto',
        },
      }}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1rem',
        }}
      >
        Revoke this API key?
      </div>

      <div
        sx={{
          fontSize: 1,
          color: '#627284',
          marginBottom: '1rem',
        }}
      >
        Are you sure you want to revoke this API key for {capitalize(mode)} mode?{' '}
        <strong>It will stop working immediately.</strong>
      </div>

      <div
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button sx={{ marginLeft: '1rem' }} onClick={onRequestClose}>
          Dismiss
        </Button>
        <DangerButton
          sx={{ marginLeft: '1rem' }}
          disabled={loading}
          onClick={() => {
            handleRevoke();
          }}
        >
          Revoke key
        </DangerButton>
      </div>
    </Modal>
  );
};

export default RevokeKeyModal;
