import gql from 'graphql-tag';

export const UPDATE_MEMBER = gql`
  mutation($userId: String!, $role: String!) {
    updateMember(userId: $userId, role: $role) {
      success
      error {
        message
        code
      }
    }
  }
`;
