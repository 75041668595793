
// given a timestamp expressed in the client browser's local time,
// convert that time to UTC.
export function localTimeToUTC(timestamp: Date): Date {
  const timezoneOffset = new Date().getTimezoneOffset();
  const date = new Date(timestamp.getTime())
  date.setTime(date.getTime() + timezoneOffset * 60 * 1000)
  return date
}

export function UTCToLocalTime(timestamp: Date): Date {
  const timezoneOffset = new Date().getTimezoneOffset();
  const date = new Date(timestamp.getTime())
  date.setTime(date.getTime() - timezoneOffset * 60 * 1000)
  return date
}

export function isToday(timestamp: Date): boolean {
  const today = new Date()
  return (
    timestamp.getDate() === today.getDate() &&
    timestamp.getMonth() === today.getMonth() &&
    timestamp.getFullYear() === today.getFullYear()
  )
}