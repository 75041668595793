import { User } from './types';

const ActivityEvents = [
  {
    copy: (
      <div className="text-preset-2 ">
        Deposit Amount set to <b>$1,250</b>
      </div>
    ),
    time: '10:00am',
  },
  {
    copy: (
      <div className="text-preset-2">
        Deposit Allocation set to <b>Full</b>
      </div>
    ),
    time: '10:00am',
  },
] as const;

const Activity = () => {
  return (
    <div className="flex flex-col gap-8">
      <h4 className="text-preset-3 font-bold">Activity</h4>

      <div className="flex flex-col text-content-subtle">
        {ActivityEvents.map(({ time, copy }, i) => (
          <>
            <div key={time} className="flex flex-row justify-between gap-8">
              <div className="flex flex-row gap-6 items-center">
                <div className="w-2 h-2 border rounded-full bg-bg-muted shrink-0"></div>
                {copy}
              </div>

              <div className="text-preset-1 text-content-muted">{time}</div>
            </div>

            <div className="basis-6 border-l ml-[3px]"></div>
          </>
        ))}
      </div>
    </div>
  );
};

export const UserSidebar: React.FC<{ user: User }> = ({ user }) => {
  return (
    <div className="fixed right-0 top-0 h-full w-1/3 lg:w-1/4 bg-white rounded-xl shadow-2xl py-9 px-6 flex flex-col">
      <div className="flex flex-row justify-between">
        <section>
          <h2 className="text-preset-4 font-bold">{user.name}</h2>
          <h6 className="text-preset-1 text-content-muted">0009bc9-11df-4d23-bd12-f82f6fb89cfe</h6>
          <div className="flex flex-row mt-1.5">
            <div className="text-content-primary bg-bg-primary--muted border border-content-primary px-1.5 py-0.5 rounded-xl text-preset-1">
              Full Allocation
            </div>
          </div>
        </section>

        <div className="text-preset-2 font-normal text-white bg-bg-primary w-8 h-8 p-2 rounded-full flex items-center justify-center">
          {user.primacyScore}
        </div>
      </div>

      <div className="flex flex-col gap-4 my-6">
        <h4 className="text-preset-3 font-bold">Customer Details</h4>

        <div>
          <h5 className="text-preset-1 text-content-muted">Primacy Score</h5>
          <p className="text-preset-2 font-bold">{user.primacyScore}</p>
        </div>

        <div>
          <h5 className="text-preset-1 text-content-muted">Annual Income</h5>
          <p className="text-preset-2 font-bold">$122,000</p>
        </div>

        <div>
          <h5 className="text-preset-1 text-content-muted">User Cohort</h5>
          <p className="text-preset-2 font-bold">Highly Affluent</p>
        </div>

        <div>
          <h5 className="text-preset-1 text-content-muted">User Segment</h5>
          <p className="text-preset-2 font-bold">Upsell</p>
        </div>
      </div>

      <Activity />
    </div>
  );
};
