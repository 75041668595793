import React from 'react';
import { FilterDefinition, FilterType } from '../types';
import { ChannelOptions, ProductOptions, TimeframeOptions } from './consts';
import { DateRange } from './timeframe';

// output state (API is in AnalyticsFilters)
export const FiltersMetadata: Record<FilterType, FilterDefinition> = {
  platform: {
    label: 'Platform',
    resolveOptions: () => {
      // TODO: Typeahead API with readthru caching
      //
      // Or: hardcoded list from an API or even retool
      return Promise.resolve([{ value: 'ADP', label: 'ADP' }]);
    },
  },
  timeframe: {
    label: 'Timeframe',
    // @ts-ignore
    resolveOptions: () => Promise.resolve(TimeframeOptions),
  },
  channel: {
    label: 'Channel',
    // @ts-ignore
    resolveOptions: () => Promise.resolve(ChannelOptions),
  },
  product: {
    label: 'Product',
    // @ts-ignore
    resolveOptions: () => Promise.resolve(ProductOptions),
  },
};
export interface FiltersInterface {
  filters: InternalFiltersState;
  setFilter: <T extends FilterType>(type: T, value: ParamsByFilterType[T]) => void;
  resetFilter: <T extends FilterType>(filterType: T) => void;
}

export type InternalFiltersState = {
  product: ProductFilterOption[];
  channel: ChannelOptionsType[];
  platform: string[];
  timeframe: TimeframeOptionsType;
};

export type ProductFilterOption = typeof ProductOptions[number]['value'];
export type TimeframeOptionsType = DateRange | null;
export type ChannelOptionsType = typeof ChannelOptions[number]['value'];

export type ParamsByFilterType = {
  product: ProductFilterOption[];
  timeframe: TimeframeOptionsType;
  channel: ChannelOptionsType[];
  platform: string[];
};

export function getDateMinusDays(days: number): Date {
  var d = new Date();
  d.setDate(d.getDate() - days);
  return d;
}

function dateToString(date: Date): string {
  const result = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  console.log('Date', date, result);
  return result;
}

const InitialState: InternalFiltersState = {
  product: [],
  channel: [],
  platform: [],
  timeframe: {
    startDate: dateToString(getDateMinusDays(90)),
    endDate: dateToString(new Date()),
  },
};

export function useFilters(): FiltersInterface {
  const [filters, setFilters] = React.useState<InternalFiltersState>(InitialState);

  return {
    filters,
    setFilter: (type, value) => {
      setFilters({
        ...filters,
        [type]: value,
      });
    },
    resetFilter: (type) =>
      setFilters({
        ...filters,
        [type]: InitialState[type],
      }),
  };
}
