import { CustomerInsightsCard } from './tools-card';

export const SegmentsPage = () => {
  return (
    <div className="flex flex-col gap-8 flex-1">
      <div className="flex flex-row justify-center py-8 border-b">
        <div className="flex flex-col gap-2 items-start min-w-[1000px]">
          <h1 className="text-preset-7 font-bold">Customer Segments</h1>

          <h5 className="text-preset-2 text-content-dim">
            Maximize your profit and client retention with Pinwheel Growth Tools
          </h5>
        </div>
      </div>

      <div className="flex flex-row gap-8 min-w-[1000px] max-w-[80%] self-center">
        <CustomerInsightsCard type="UpsellSegment" />
        <CustomerInsightsCard type="CrossSellSegment" />
        <CustomerInsightsCard type="RetainSegment" />
      </div>
    </div>
  );
};
