import gql from 'graphql-tag';

export const ListSmartBranchUrls = gql`
  query ListSmartBranchUrls {
    getSmartBranchUrls {
      id
      name
      createdAt
      smartBranchUrl
    }
  }
`;
