/** @jsxImportSource theme-ui */
import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/client';
import { Button } from '../../uikit/buttons';
import { capitalize } from '../../utils/general';
import AddWorkspaceMemberModal from './AddWorkspaceMemberModal';
import RemoveWorkspaceMemberModal from '../../components/RemoveWorkspaceMemberModal';
import EditWorkspaceMemberRoleModal from './EditWorkspaceMemberRoleModal';
import { LongTextWithTooltip } from '../../uikit/LongTextWithTooltip';
import { DescriptionList } from '../../uikit/DescriptionList';

export const WORKSPACE_MEMBERS = gql`
  query {
    workspaceOwner {
      id
      email
    }
    workspaceMembers {
      id
      role
      user {
        id
        email
      }
    }
  }
`;

const TeamMembers: React.FC = () => {
  const { data, loading, refetch } = useQuery(WORKSPACE_MEMBERS);
  const [addMemberModalOpen, setAddMemberModalOpen] = React.useState(false);
  const [memberToRemove, setMemberToRemove] = React.useState(null);
  const [memberToEditRole, setMemberToEditRole] = React.useState(null);

  if (loading) {
    return null;
  }

  const allMembers = get(data, 'workspaceMembers', []).map((member: any) => {
    return {
      email: member.user.email,
      role: capitalize(member.role),
      member,
    };
  });

  const ownerEmail = get(data, 'workspaceOwner.email', null);
  if (ownerEmail) {
    allMembers.unshift({
      email: ownerEmail,
      role: 'Administrator (Owner)',
    });
  }

  return (
    <React.Fragment>
      <AddWorkspaceMemberModal
        isOpen={addMemberModalOpen}
        onRequestClose={() => setAddMemberModalOpen(false)}
      />
      <RemoveWorkspaceMemberModal
        memberToRemove={memberToRemove}
        onRequestClose={() => setMemberToRemove(null)}
      />
      <EditWorkspaceMemberRoleModal
        memberToEditRole={memberToEditRole}
        onRequestClose={async () => {
          await refetch();
          setMemberToEditRole(null);
        }}
      />
      <div
        sx={{
          backgroundColor: 'white',
          padding: '3rem 4rem',
          borderRadius: 0,
          boxShadow: 'main',
        }}
      >
        <div
          sx={{
            fontSize: 2,
            fontWeight: 500,
            marginBottom: '2.5rem',
          }}
        >
          Team
        </div>

        <DescriptionList
          title="Members"
          actions={
            <Button size="small" icon="add" onClick={() => setAddMemberModalOpen(true)}>
              Add New User
            </Button>
          }
          rows={allMembers.map((member: any) => ({
            columns: [
              {
                label: 'User',
                value: <LongTextWithTooltip value={member.email} />,
                customSx: {
                  container: {
                    width: '30%',
                    flex: 'unset',
                  },
                  value: {
                    lineHeight: '1.5rem',
                    height: '1.5rem',
                  },
                },
              },
              {
                label: 'Role',
                value: member.role,
                customSx: {
                  container: {
                    width: '30%',
                    flex: 'unset',
                  },
                  value: {
                    lineHeight: '1.5rem',
                    height: '1.5rem',
                  },
                },
              },
              {
                label: '',
                value: member.member && (
                  <div
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button size="small" onClick={() => setMemberToEditRole(member.member)}>
                      Edit
                    </Button>
                    <Button
                      size="small"
                      sx={{ marginLeft: '0.5rem' }}
                      onClick={() => setMemberToRemove(member.member)}
                    >
                      Remove
                    </Button>
                  </div>
                ),
                customSx: {
                  container: {
                    width: '40%',
                    flex: 'unset',
                  },
                  value: {
                    lineHeight: '1.5rem',
                    height: '1.5rem',
                  },
                },
              },
            ],
            customSx: {
              flexFlow: 'row',
              alignItems: 'center',
            },
          }))}
        />
      </div>
    </React.Fragment>
  );
};

export default TeamMembers;
