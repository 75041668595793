/** @jsxImportSource theme-ui */
import React from 'react';
import QRCode from 'react-qr-code';
import { columnStyles, rowStyles } from '../../consts/styles';
import { LongTextWithCopyAction } from '../../uikit/LongTextWithCopyAction';

type Props = {
  url: string;
};

export default ({ url }: Props) => {
  return (
    <div>
      <div sx={columnStyles}>
        <p
          sx={{
            fontSize: '1.125rem',
            fontWeight: 500,
            lineHeight: 1.44,
            color: '#21292e',
            marginTop: 0,
          }}
        >
          Smart Branch URL
        </p>
        <div
          sx={{
            height: '1px',
            backgroundColor: '#e7ebed',
            width: '100%',
          }}
        />
      </div>
      <div sx={rowStyles}>
        <LongTextWithCopyAction
          iconSize="1.5rem"
          value={url}
          customSx={{
            value: {
              width: '70ch',
            },
          }}
        />
      </div>
      <div sx={{ height: 'auto', marginTop: '2.5rem', maxWidth: 100, width: '100%' }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={url}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
};
