/** @jsxImportSource theme-ui */
import React from 'react';
import { Icon } from '../designs-v2/primitives/icons';
import { Button } from '../designs-v2/components/button';

const VerifyEmail: React.FC = () => {
  return (
    <div className="flex flex-col gap-3 items-center justify-center min-h-screen">
      <Icon type="info-circle" className="h-16 w-16" />
      Please verify your email to continue.
      <Button
        buttonType="primary"
        label="Continue"
        onClick={() => {
          // Redirect to Auth0's /continue endpoint so that authentication resumes and verified email users will be
          // automatically logged in.
          // TODO: Auth0 doesn't support repeated /continue calls, so we should have an endpoint in dev-dash API which
          //       checks if the user has verified their email. Only when that check passes would we actually redirect
          //       the user to the /continue endpoint.
          window.location.href = `${
            process.env.REACT_APP_AUTH0_TENANT_DOMAIN
          }/continue?${new URLSearchParams(window.location.search).toString()}`;
        }}
      />
    </div>
  );
};

export default VerifyEmail;
