/** @jsxImportSource theme-ui */
import { Button } from '../../uikit/buttons';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGate } from 'statsig-react';
import { LinkTokenRequest, SendFormRequest, getLinkTokenRequest } from '../../utils/requests';
import CreateLinkTokenButton from '../TestConsole/CreateLinkTokenButton';
import { LinkTokenFormKey, TLinkTokenForm } from '../TestConsole/types';
import { InputSelect } from './InputSelect';
import { InputText } from './InputText';
import JsonFormatter from 'react-json-formatter';
import Modal from '../../uikit/Modal';
import { ModeKey } from '../TestConsole/form-helpers';

export type OnTokenCreation = (token: string, valueType: 'url' | 'token') => void;

export type SubmitButtonProps<T> = { form: T; disabled: boolean };

export type FieldConfig<Opts, Form> = {
  Component: React.FC<any>;
  generateProps?: (
    options: Opts & {
      form: Form;
      setFormFields: (fields: Partial<Record<keyof Form, any>>) => void;
    }
  ) => Record<string, any>;
  getError?: (form: Form) => string | null;
};

export type FieldConfigs<Opts, Form> = {
  [lkey: string]: FieldConfig<Opts, Form>;
};

function displayLinkTokenParams(params: any, mode: ModeKey) {
  const result = {
    ...params,
    headers: {
      ...params.headers,
      'X-API-SECRET': `<your ${mode} api secret>`,
    },
  };

  return JSON.stringify(result);
}

export default function <P extends object, F>({
  propGeneratorOptions: _propGenOpts,
  form,
  setForm,
  formInitialState,
  fieldConfigs,
  layout,
  onFormSuccess,
  getSubmitFormFn,
}: {
  propGeneratorOptions: P;
  form: F;
  setForm: (state: F) => void;
  formInitialState: F;
  fieldConfigs: FieldConfigs<P, F>;
  layout: (keyof F)[][];
  onFormSuccess: OnTokenCreation;
  getSubmitFormFn: (sendFormRequest: SendFormRequest) => (_form: F) => Promise<any>;
}) {
  const setFormFields = useCallback(
    (fields: Partial<Record<LinkTokenFormKey, any>>) => {
      setForm({ ...form, ...fields });
      if (fields && Object.keys(fields).length > 0) {
        setLinkTokenRequest(
          getLinkTokenRequest(form as TLinkTokenForm, 'apiSecret', '/v1/link_tokens')
        );
      }
    },
    [form, setForm]
  );
  const propGeneratorOptions = { ..._propGenOpts, form, setFormFields };
  const [errors, setErrors] = useState<Partial<Record<keyof F, string>>>({});
  const [isFormComplete, setIsFormComplete] = useState(false);
  const delayedCardInfoGate = useGate('ff-allow-delayed-card-info');

  const [linkTokenRequest, setLinkTokenRequest] = useState<LinkTokenRequest | undefined>();
  const [isModalOpen, setModalOpen] = useState(false);
  const mode = useMemo(() => {
    const linkTokenForm = form as TLinkTokenForm;
    return linkTokenForm.mode?.value || 'sandbox';
  }, [form]);

  const disabled = useMemo(() => !isFormComplete || Object.values(errors).some(Boolean), [
    isFormComplete,
    errors,
  ]);

  const getValue = useCallback(
    (key: string) => {
      if (fieldConfigs[key].Component === InputSelect) {
        return form[key]?.value;
      }

      return form[key];
    },
    [fieldConfigs, form]
  );

  useEffect(() => {
    const fieldsInLayout = layout.flat();
    setIsFormComplete(
      Object.entries(fieldConfigs).every(([key, config]) => {
        // return true if the field is not in the layout
        if (!fieldsInLayout.includes(key as keyof F)) return true;

        if (!config.generateProps) return true;
        if (!config.generateProps(propGeneratorOptions).required) return true;

        const value = getValue(key);
        if (Array.isArray(value)) {
          return value.length;
        }
        if (value instanceof Object && !delayedCardInfoGate.value) {
          return Object.keys(value).length;
        }
        return !!value;
      })
    );
  }, [form, propGeneratorOptions, fieldConfigs, getValue, layout, delayedCardInfoGate.value]);

  useEffect(() => {
    setErrors(
      Object.entries(fieldConfigs).reduce((acc, [key, { getError }]) => {
        const value = getValue(key);

        if (!value || !getError) {
          acc[key] = null;
        } else {
          acc[key] = getError(form);
        }
        return acc;
      }, {})
    );
  }, [fieldConfigs, getValue, form]);

  return (
    <div>
      <div sx={{ width: '100%', display: 'flex' }}>
        <div sx={{ fontSize: 2, fontWeight: 500, marginRight: 'auto' }}>Test Console</div>
        <Button
          data-testid="clearFormButton"
          onClick={() => setForm(formInitialState)}
          size="small"
        >
          <div>Reset all</div>
        </Button>
      </div>
      <div style={{ margin: '2.5rem 0' }}>
        {layout.map((row, i) => (
          <div key={`row-${i}`} className="form-row">
            {row.map((key: keyof F) => {
              const { Component, generateProps = _.stubObject } = fieldConfigs[key as string];
              return (
                <Component
                  key={key}
                  name={key}
                  label={_.snakeCase(key as string)}
                  value={form[key]}
                  form={form}
                  onChange={(val: string) => {
                    setFormFields({ [key]: val });
                  }}
                  error={errors[key]}
                  {...generateProps(propGeneratorOptions)}
                />
              );
            })}
          </div>
        ))}
      </div>
      {disabled && <div>Please fill out all required fields</div>}
      <div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CreateLinkTokenButton<F>
          disabled={disabled}
          form={form}
          onFormSuccess={onFormSuccess}
          getSubmitFormFn={getSubmitFormFn}
          sx={{ flex: '0 1 auto' }}
        />
        <Button onClick={() => setModalOpen(true)} sx={{ flex: '0 1 auto' }}>
          <div>Show Request JSON</div>
        </Button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        width="40rem"
        appElement={document.getElementById('root') as HTMLElement}
      >
        <div style={{ fontSize: '1.5rem', fontWeight: 500 }}>Link Token Request JSON</div>
        <div
          style={{
            backgroundColor: '#eee',
            padding: '5px',
            fontFamily: 'monospace',
            fontSize: '12px',
          }}
        >
          <JsonFormatter
            json={linkTokenRequest ? displayLinkTokenParams(linkTokenRequest, mode) : {}}
          />
        </div>
      </Modal>
    </div>
  );
}
