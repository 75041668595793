/** @jsxImportSource theme-ui */
import React from 'react';
import { Link as RRLink } from 'react-router-dom';

interface ILinkProps {
  to: string;
}

const Link: React.FC<ILinkProps> = ({ to, children }) => {
  return (
    <RRLink
      sx={{
        color: 'primary',
        fontWeight: '500',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      to={to}
    >
      {children}
    </RRLink>
  );
};

export default Link;
