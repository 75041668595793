export const BaseCard: React.FC<React.PropsWithChildren<{
  className?: string;
  noBorder: boolean;
}>> = ({ children, className, noBorder }) => {
  return (
    <div
      className={`rounded-3xl bg-white ${
        noBorder ? '' : 'border border-border-default'
      } ${className}`}
    >
      {children}
    </div>
  );
};

export const CardHeading: React.FC<{
  content: React.ReactNode;
  className?: string;
}> = ({ content, className }) => {
  return (
    <div className={`px-6 py-5 flex flex-row justify-between rounded-xl ${className}`}>
      {content}
    </div>
  );
};

export const Card: React.FC<{
  heading: React.ReactNode;
  body: React.ReactNode;
  className?: string;
  headingClassName?: string;
  noBorder?: boolean;
}> = ({ heading, body, className, headingClassName, noBorder }) => {
  return (
    <BaseCard className={className} noBorder={noBorder || false}>
      <CardHeading content={heading} className={headingClassName} />
      <div className="px-6 pb-5">{body}</div>
    </BaseCard>
  );
};
