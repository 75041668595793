import { colorTokens } from '../../primitives/colors';
import { Tooltip, TooltipPopover } from '../../../designs-v2/components/tooltip/index';

type BadgeType = 'default' | 'primary' | 'success' | 'warning' | 'critical';

const Indicator = ({ fill }: { fill: string }) => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_294_9109)">
      <rect width="6" height="6" rx="3" fill={fill} />
      <circle cx="3" cy="3" r="3" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_294_9109">
        <rect width="6" height="6" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

type BadgeConfig = {
  indicatorColor: string;
  className: string;
};

const BadgeConfigs: Record<BadgeType, BadgeConfig> = {
  default: {
    indicatorColor: colorTokens['content-muted'],
    className: 'bg-bg-subtle',
  },
  primary: {
    indicatorColor: colorTokens['content-primary'],
    className: 'bg-bg-subtle text-content-primary',
  },
  success: {
    indicatorColor: colorTokens['content-success'],
    className: 'bg-bg-success--subtle text-content-success',
  },
  warning: {
    indicatorColor: colorTokens['content-warning'],
    className: 'bg-bg-warning--subtle',
  },
  critical: {
    indicatorColor: colorTokens['content-critical'],
    className: 'bg-bg-critical--subtle',
  },
};

export type BadgeProps = {
  type: BadgeType;
  text: string;
  tooltipText?: string;
};

export const Badge: React.FC<BadgeProps> = ({ type, text, tooltipText }) => {
  const { indicatorColor, className } = BadgeConfigs[type];

  return (
    <div
      className={`
      inline-flex flex-row gap-1 h-6 items-center
      rounded-lg px-2 py-0.5
      has-tooltip relative
      ${className}
      `}
    >
      <Indicator fill={indicatorColor} />
      <div className="text-preset-1">{text}</div>
      {tooltipText && <TooltipPopover className="bottom-6" tooltipText={tooltipText} />}
    </div>
  );
};
