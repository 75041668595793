import React from 'react';
import { Popover, Tab } from '@headlessui/react';
import { UserSidebar } from './user-sidebar';
import { User } from './types';
import { Names } from './users';
import { numberFormatter } from '../../analytics/funnel';
import { Icon } from '../../../designs-v2/primitives/icons';

function randName() {
  const index = Math.floor(Math.random() * Names.length);

  return Names[index][0].toUpperCase() + Names[index].slice(1);
}

function fullName() {
  return randName() + ' ' + randName();
}

function randUser(): User {
  const totalPaycheck = 500 + Math.random() * 9000;
  const percent = 30 + Math.round(Math.random() * 70);

  const depositAmount = Math.round(totalPaycheck * (percent / 100));
  const opportunityRemaining = Math.max(0, Math.floor(totalPaycheck - depositAmount));

  return {
    name: fullName(),
    depositPercent: percent,
    depositAmount: depositAmount,
    primacyScore: Math.min(100, Math.round(percent + (10 - Math.random() * 20))),
    opportunityRemaining,
  };
}

const Users: User[] = [
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
  randUser(),
].sort((a, b) => b.opportunityRemaining - a.opportunityRemaining);

const TableHeader: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <th>
    <div className="flex flex-row justify-start font-normal text-content-muted text-preset-1 p-3">
      {children}
    </div>
  </th>
);

const UserRow: React.FC<{ user: User }> = ({ user }) => {
  return (
    <Popover as={React.Fragment}>
      {({ open }) => (
        <>
          <Popover.Button
            as="tr"
            tabIndex={0}
            className={`group border-t text-preset-2 text-content-dim hover:bg-bg-muted
                    group-focus:border group-focus:border-blue-400 rounded-xl
                    hover:cursor-pointer`}
          >
            <td>
              <div className="flex flex-row justify-start px-4 py-4 font-normal">{user.name}</div>
            </td>
            <td>
              <div className="flex flex-row justify-start px-4 font-normal">
                {user.depositPercent}%
              </div>
            </td>
            <td>
              <div className="flex flex-row justify-start px-4 font-normal">
                ${numberFormatter.format(user.depositAmount)}
              </div>
            </td>
            <td>
              <div className="flex flex-row justify-start px-4 font-bold">
                ${numberFormatter.format(user.opportunityRemaining)}
              </div>
            </td>
            <td>
              <div className="flex flex-row justify-start px-4 font-normal">
                <div className="p-2 rounded-full bg-bg-primary--muted text-content-primary w-9 h-9 flex items-center justify-center">
                  {user.primacyScore}
                </div>
              </div>
            </td>
          </Popover.Button>

          {open && (
            <Popover.Panel static>
              <UserSidebar user={user} />
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export const UserTable = () => {
  return (
    <div className="w-full">
      <table
        cellPadding={0}
        cellSpacing={0}
        className="table-auto w-full"
        style={{ borderCollapse: 'inherit' }}
      >
        <thead>
          <tr>
            <TableHeader>Customer Name</TableHeader>
            <TableHeader>Deposit %</TableHeader>
            <TableHeader>Deposit amount</TableHeader>
            <TableHeader>
              <div className="flex flex-row items-center">
                Uncaptured Deposits
                <Icon type="caret-down" />
              </div>
            </TableHeader>
            <TableHeader>Primacy Score</TableHeader>
          </tr>
        </thead>
        <tbody>
          {Users.map((user, i) => (
            <UserRow key={i} user={user} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
