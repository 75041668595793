const theme = {
  fonts: {
    body: 'Circular',
    heading: 'Circular',
  },
  colors: {
    primary: '#4F9ACA !important',
    text: '#21292E',
    white: '#FFFFFF',
    textSecondary: '#727D8B',
    border: '#E7EBED',
    background: '#F5F6F7',
    gray: '#ABB5BB',
    hoverGray: '#ECEEEF',
    danger: '#DF5657',
  },
  shadows: {
    main: '0px 32px 98px rgba(18, 18, 18, 0.11)',
  },
  fontSizes: [16, 18, 24, 12],
  radii: [4, 8],
  borders: {
    gray: 'solid 1px #E7EBED',
    danger: 'solid 1px #DF5657',
    grayDarker: 'solid 1px #ABB5BB',
    blue: 'solid 1px #4F9ACA',
  },
};

export default theme;
