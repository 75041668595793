import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { AnalyticsCTA } from './analytics-cta';
import { GetStartedSection } from './get-started';
import { TopResourcesSection } from './resources';
import { RightSideRail } from './right-side-rail';
import { GetAnalyticsConfigs } from '../analytics/filters/getFilterOptionsQuery';
import { useCurrentWorkspaceRole } from '../../utils/identity';

const Separator = () => <div className="flex-1 border-t border-border-default h-0 my-12" />;

export const HomePage = () => {
  const currentRole = useCurrentWorkspaceRole();

  const client = useApolloClient();
  // on first load, prefetch filter options
  useEffect(() => {
    client.query({
      query: GetAnalyticsConfigs,
    });
  });

  return (
    <div className="flex flex-col max-w-[90%] gap-10 my-10">
      <div className="flex flex-row gap-12">
        <div className="flex flex-col">
          <AnalyticsCTA />

          <Separator />

          {(currentRole === 'administrator' || currentRole === 'developer') && (
            <>
              <GetStartedSection />

              <Separator />
            </>
          )}

          <TopResourcesSection />
        </div>

        {(currentRole === 'administrator' || currentRole === 'developer') && <RightSideRail />}
      </div>
    </div>
  );
};
