import { useGate, useConfig } from 'statsig-react';
import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DepositSwitchType } from './charts/deposit-switch-type/index';
import { TrafficByProvider } from './charts/traffic-by-provider/index';
import { FilterRow } from './filters';
import { useFilters } from './filters/state';
import { Funnel } from './funnel';
import { useBreakdownsData, useFunnelQuery } from './funnel/query';
import { OverviewHeader } from './overview/header';
import { Overview } from './overview/overview-section';
import { OutcomeByJob } from './charts/outcome-by-job/index';
import { useDDSQuery, SwitchTypeResponse } from './charts/deposit-switch-type/query';
import { JobErrorChart } from './charts/job-errors/index';
import { UsageOverTime } from './charts/usage-over-time/index';
import { useSession } from '../../utils/session';
import { useAuth } from '../../utils/authentication';
import { getConfig } from '../../utils/statsig';
import { ReportConfig, Report } from './types';
import { GetReports } from './graphql/getReports';
import { Reports } from './charts/reports';

export const AnalyticsPage = () => {
  const showAnalyticsStub = useGate('ff-show-analytics-stub');

  if (showAnalyticsStub.value) {
    return (
      <div className="mt-2 flex flex-col gap-4">
        <h1 className="text-preset-6">Analytics - Coming Soon</h1>

        <p>
          Analytics data for your workspace will be available soon! In the meantime, please reach
          out to sales@pinwheelapi.com with any questions.
        </p>
      </div>
    );
  }

  return <AnalyticsPageDDS />;
};

const AnalyticsPageDDS = () => {
  const filterState = useFilters();
  const { breakdowns, loading: breakdownsLoading } = useBreakdownsData(filterState.filters);
  const { funnel, loading: funnelLoading } = useFunnelQuery(filterState.filters);
  const { switchTypes, jobOutcomes, jobErrors, loading } = useDDSQuery(filterState.filters);

  const [primaryView, setPrimaryView] = React.useState<'funnel' | 'chart'>('funnel');

  const session = useSession();
  const { currentWorkspaceId } = useAuth();

  const [reportConfigs, setReportConfigs] = React.useState<ReportConfig[] | null>(null);
  const [reports, setReports] = React.useState<Report[] | null>(null);
  const [getReports, { data: reportsData, refetch: refetchReports }] = useLazyQuery(GetReports);

  const config = useConfig('dc-report-configs')?.config?.value;
  const configs = config['report_configs'] ?? null;
  if (!reportConfigs && configs) {
    setReportConfigs(configs);
  }

  const hasReports =
    reportConfigs && reportConfigs.some((config) => config.workspace_id === currentWorkspaceId);

  useEffect(() => {
    if (reportsData && !reports) {
      setReports(reportsData.reports);
    }
  }, [reports, reportsData]);

  useEffect(() => {
    if (hasReports && !reports) getReports();
  }, [reports, hasReports]);

  return (
    <div className="flex flex-col gap-4 flex-1 pb-8 mt-10 max-w-[100vw] px-12">
      <OverviewHeader filters={filterState.filters} />

      <FilterRow
        {...filterState}
        primaryView={primaryView}
        togglePrimaryView={() => setPrimaryView(primaryView === 'funnel' ? 'chart' : 'funnel')}
      />

      {primaryView === 'funnel' && <Funnel funnel={funnel} loading={funnelLoading} />}
      {primaryView === 'chart' && (
        <UsageOverTime data={breakdowns.by_date || []} loading={breakdownsLoading} />
      )}

      <Overview filters={filterState.filters} />

      {breakdowns.by_platform && (
        <TrafficByProvider
          data={[...breakdowns.by_platform].sort(
            (platform1, platform2) =>
              platform2.dds_job_success_user_count - platform1.dds_job_success_user_count
          )}
          setFilter={filterState.setFilter}
        />
      )}

      <div className="flex flex-row flex-wrap gap-4 items-stretch [&>*]:basis-[49%] [&>*]:shrink-0">
        {switchTypes && switchTypes.length !== 0 && (
          <div>
            <DepositSwitchType switchTypes={switchTypes} />
          </div>
        )}
        {jobOutcomes && jobOutcomes.length !== 0 && (
          <div>
            <OutcomeByJob jobOutcomes={jobOutcomes} />
          </div>
        )}
        {jobErrors && jobErrors.length !== 0 && (
          <div>
            <JobErrorChart jobErrors={jobErrors} />
          </div>
        )}
      </div>

      {hasReports && <Reports data={reports || []} />}
    </div>
  );
};
