export enum RedirectReasons {
  DuplicateSession = 'duplicate_session',
  NetworkError = 'network_error',
}

export const getRedirectReasonMessage = (reason: RedirectReasons): string | undefined => {
  switch (reason) {
    case RedirectReasons.DuplicateSession: {
      return 'You need to authenticate again, your session is no longer valid';
    }
    case RedirectReasons.NetworkError: {
      return 'Failed to reach the server, our service might be down, please contact support';
    }
    default: {
      return undefined;
    }
  }
};
