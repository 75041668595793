import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { PrimaryButton } from '../uikit/buttons';
import { get } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { DevDashboardError } from '../consts/DevDashboardError';
import {
  columnStylesSimple,
  containerStyles,
  rowStylesNoWrap,
  titleStyles,
} from '../consts/styles';
import { ADD_EMPLOYER_MATCHING_JOB } from '../graphql/mutations/add-employer-matching-job';
import { GET_EMPLOYER_MATCHING_JOBS } from '../graphql/queries/employer-matching-jobs';
import { GET_UPLOAD_EMPLOYERS_PARAMS } from '../graphql/queries/upload-employers-params';
import { useSession } from '../utils/session';

const INVALID_FILE_TYPE_MESSAGE = 'Only text based file types are supported.';
const isValidFileType = (type: string) => {
  // TODO: possibly set stricter rules
  // return type === "text/tab-separated-values" || type === "text/plain" || type === "text/csv"
  return type.startsWith('text');
};

const getEnv = () => {
  if (window.location.host.includes('localhost')) return 'staging';
  if (window.location.host.includes('staging')) return 'staging';

  return 'production';
};

// not sure why the linter complains here
// eslint-disable-next-line
enum UploadState {
  AwaitingFile,
  FileSelected,
  UploadInProgress,
  UploadSuccess,
  AddJobSuccess,
  Error,
}

const JobStatus = ({ matchedFilePath }) => {
  if (matchedFilePath !== null) {
    return (
      <div>
        <a
          href={`https://${getEnv()}-dev-dashboard-api-uploads.s3.amazonaws.com/${matchedFilePath}`}
          download
        >
          Download
        </a>
      </div>
    );
  }
  return <div>Processing</div>;
};

const EmployerMatchingJobs = (jobs) => {
  if (!Array.isArray(jobs.jobs)) {
    return null;
  }
  return (
    <div>
      {jobs.jobs.map((job: any) => {
        return (
          <div key={job.id} sx={rowStylesNoWrap}>
            <div sx={columnStylesSimple}>{job.uploadFilePath.split('/').pop()}</div>
            <div sx={columnStylesSimple}>{job.uploadingUser.email}</div>
            <JobStatus matchedFilePath={job.matchedFilePath} />
          </div>
        );
      })}
    </div>
  );
};

const EmployerMatching = () => {
  const session = useSession();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.AwaitingFile);
  const [uploadError, setUploadError] = useState<TypeError | Error | undefined>();
  const [getParams, { data }] = useLazyQuery(GET_UPLOAD_EMPLOYERS_PARAMS);
  const [addEmployerMatchingJob] = useMutation(ADD_EMPLOYER_MATCHING_JOB);
  const { data: jobsQueryResponse, refetch: refetchJobs } = useQuery(
    GET_EMPLOYER_MATCHING_JOBS,
    {}
  ); // , refetch, loading
  const jobs = jobsQueryResponse?.getEmployerMatchingJobs?.jobs || [];
  const uploadKey = useRef<string | undefined>();
  const inputEl = useRef<HTMLInputElement>(null);

  const showFileUpload = useCallback(() => {
    const el = inputEl.current;
    if (el) {
      el.click();
    }
  }, [inputEl]);

  const changeHandler = useCallback(
    (event) => {
      event.preventDefault();
      let file;
      if (event.dataTransfer?.items) {
        // Handle drop
        for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
          if (event.dataTransfer.items[i].kind === 'file') {
            file = event.dataTransfer.items[i].getAsFile();
            break;
          }
        }
      } else {
        // Handle file selection
        file = event.target.files[0] as File;
      }
      if (!isValidFileType(file.type)) {
        setUploadError(new Error(INVALID_FILE_TYPE_MESSAGE));
        setUploadState(UploadState.Error);
        event.target.value = '';
        return;
      }
      setUploadError(undefined);
      setUploadState(UploadState.FileSelected);
      setSelectedFile(file);
      getParams({
        variables: {
          filename: file.name,
        },
      });
    },
    [getParams, setSelectedFile, setUploadState]
  );

  const handleSubmission = useCallback(async () => {
    if (!selectedFile || !data) {
      return;
    }
    const { url } = data.uploadParams;
    const fields = JSON.parse(data.uploadParams.fields);
    const fd = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      if (key === 'key') {
        uploadKey.current = value as string;
      }
      const typedValue = value as string | Blob;
      if (typedValue) {
        fd.append(key, typedValue);
      }
    });
    fd.append('file', selectedFile);

    try {
      setUploadState(UploadState.UploadInProgress);
      await fetch(url, { method: 'post', body: fd });
      setUploadState(UploadState.UploadSuccess);
    } catch (error) {
      // TODO: show an alert, and update the backend
      const typeError = error as TypeError;
      setUploadState(UploadState.Error);
      setUploadError(typeError);
      datadogLogs.logger.info('displayed error to user', {
        operation: 'addEmployerMatchingJob.upload',
        errorMsg: typeError.message,
      });
    }

    try {
      const result = await addEmployerMatchingJob({
        variables: {
          key: uploadKey.current,
        },
      });
      const errorMsg = get(result, 'data.addEmployerMatchingJob.error.message', null);
      if (errorMsg) {
        setUploadState(UploadState.Error);
        const code = get(result, 'data.addEmployerMatchingJob.error.code', null);
        setUploadError(new DevDashboardError(errorMsg, code));
        datadogLogs.logger.info('displayed error to user', {
          operation: 'addEmployerMatchingJob',
          errorMsg,
        });
      }
      setUploadState(UploadState.AddJobSuccess);
      setSelectedFile(undefined);
      await refetchJobs();
    } catch (error) {
      setUploadState(UploadState.Error);
      setUploadError(error as Error);
      datadogLogs.logger.info('displayed error to user', {
        operation: 'addEmployerMatchingJob',
        errorMsg: (error as Error).message,
      });
    }
  }, [selectedFile, data, setUploadError, setUploadState, addEmployerMatchingJob, refetchJobs]);

  return (
    <div sx={{ ...containerStyles, display: 'flex', flexDirection: 'column' }}>
      <div sx={{ ...titleStyles, marginBottom: '.5em' }}>Employer Matching</div>
      <div sx={{ marginBottom: '48px' }}>
        Get a supported employers report by uploading a CSV list of employers.
        <br />
        <span sx={{ fontSize: 'small' }}>
          <a
            href="https://production-dev-dashboard-api-uploads.s3.amazonaws.com/employer-matching-template.csv"
            download
          >
            Download template
          </a>
        </span>
      </div>
      <div
        id="uploadContainer"
        onDrop={changeHandler}
        onDragOver={(e) => e.preventDefault()}
        sx={{
          display: 'flex ',
          flexDirection: 'column',
          alignItems: 'center',
          borderColor: 'lightgray',
          borderWidth: '2px',
          borderStyle: 'dashed',
          padding: '2em',
          borderRadius: '16px',
          marginBottom: '48px',
        }}
      >
        <div sx={{ marginBottom: '32px' }}>
          Drag a file here or{' '}
          <span onClick={() => showFileUpload()} sx={{ color: 'blue', cursor: 'pointer' }}>
            browse
          </span>{' '}
          for a file to upload.
        </div>
        <input
          id="fileSelection"
          ref={inputEl}
          type="file"
          name="file"
          onChange={changeHandler}
          sx={{ display: 'none' }}
        />
        <div sx={{ fontWeight: 'bold', fontSize: 'larger', margin: '20px', height: '2em' }}>
          {selectedFile?.name}
          {session.loading ? <div>Loading</div> : null}
          {uploadState === UploadState.UploadSuccess && (
            <div sx={{ fontSize: 'initial', fontWeight: 'initial', textAlign: 'center' }}>
              File uploaded successfully
            </div>
          )}
          {uploadState === UploadState.AddJobSuccess && (
            <div sx={{ fontSize: 'initial', fontWeight: 'initial', textAlign: 'center' }}>
              Matching report started successfully. <br />
              You will get an email when your report is ready.
            </div>
          )}
          {uploadState === UploadState.Error && <div>{uploadError?.message}</div>}
        </div>
        <div
          sx={{
            display: 'flex',
            '&>*': {
              margin: 1,
            },
          }}
        >
          <PrimaryButton
            block
            disabled={!data || !selectedFile}
            onClick={handleSubmission}
            sx={{ width: 'auto' }}
          >
            Upload File
          </PrimaryButton>
        </div>
      </div>
      <h3 sx={{ marginBottom: 0 }}>Employer matching reports</h3>
      <div>
        <EmployerMatchingJobs jobs={jobs} />
      </div>
    </div>
  );
};

export default EmployerMatching;
