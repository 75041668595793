const emailRegex = new RegExp(
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/,
  'i'
);

const URL_VALIDATION_REGEXP = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

export const MIN_PASSWORD_LENGTH = 10;
export const MAX_PASSWORD_LENGTH = 64;

export const isEmailValid = (subject: string) => subject.match(emailRegex) != null;

export const isUrlValid = (url: string) => !!URL_VALIDATION_REGEXP.test(url);

export const getPasswordValidation = (password: string): string | undefined => {
  const upperCaseLeterRegex = new RegExp('(?=.*[A-Z])(.*)');
  if (!upperCaseLeterRegex.test(password)) {
    return 'Your password should at least have one upper case letter';
  }

  const lowerCaseRegex = new RegExp('(?=.*[A-Z])(?=.*[a-z])(.*)');
  if (!lowerCaseRegex.test(password)) {
    return 'Your password should at least have one lower case letter';
  }

  const numberRegex = new RegExp('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(.*)');
  if (!numberRegex.test(password)) {
    return 'Your password should at least have one number';
  }

  const specialCharacterRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.*)');
  if (!specialCharacterRegex.test(password)) {
    return 'Your password should at least have one special character';
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return `Your password should have at least ${MIN_PASSWORD_LENGTH} characters`;
  }

  if (password.length > MAX_PASSWORD_LENGTH) {
    return `Your password should have at most ${MAX_PASSWORD_LENGTH} characters`;
  }

  return undefined;
};
