/** @jsxImportSource theme-ui */
import { Button } from '../../uikit/buttons';
import _ from 'lodash';
import { useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { ErrorMessage, getDisabledClass, getLabelText } from './utils';

interface InputJSONProps {
  name: string;
  required?: boolean;
  error?: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  subtitleAction?: () => never;
  subtitleText?: string;
}

export const InputAccount = (props: InputJSONProps) => {
  const {
    name,
    label,
    onChange,
    required,
    error,
    disabled,
    subtitleAction,
    subtitleText,
    value,
  } = props;

  const [jsonError, setJsonError] = useState<string>();
  const colors = {
    background: '#E7EBED',
    default: '#727D8B',
    error: '#727D8B',
  };

  const handleAddAccount = () => {
    onChange([
      ...value,
      {
        name: 'New account',
        type: 'checking',
        routing_number: _.times(9)
          .map(() => Math.floor(Math.random() * 10))
          .join(''),
        account_number: _.times(10)
          .map(() => Math.floor(Math.random() * 10))
          .join(''),
      },
    ]);
  };

  const handleReset = () => {
    setJsonError(undefined);
    onChange([]);
  };

  return (
    <div className={`form-field ${getDisabledClass(disabled)}`}>
      <label
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          {getLabelText('account(s)', required)}
          {!!subtitleText && (
            <span className="subtitle" onClick={() => subtitleAction && subtitleAction()}>
              {subtitleText}
            </span>
          )}
        </div>
        <div
          sx={{
            display: 'flex',
            '& > button': {
              marginLeft: '8px',
            },
          }}
        >
          <Button
            size="small"
            disabled={disabled || !!(jsonError || error)}
            onClick={handleAddAccount}
          >
            Add account
          </Button>
          <Button size="small" disabled={disabled} onClick={handleReset}>
            Reset
          </Button>
        </div>
      </label>
      <JSONInput
        id={name}
        viewOnly={disabled}
        locale={locale}
        placeholder={value || []}
        data-testid={`input-${name}`}
        height="133px"
        width="100%"
        theme="light_mitsuketa_tribute"
        colors={colors}
        confirmGood={false}
        style={{
          container: {
            borderRadius: '4px',
          },
          outerBox: {
            borderRadius: '4px',
          },
          warningBox: {
            display: 'none',
          },
          body: {
            height: '100%',
          },
        }}
        onChange={(val) => {
          if (val.error) {
            setJsonError(`JSON error on line ${val.error.line}`);
          } else {
            setJsonError(undefined);
            onChange(val.jsObject);
          }
        }}
      />
      <div>
        <ErrorMessage error={error || jsonError} />
      </div>
    </div>
  );
};
