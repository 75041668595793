import React from 'react';
import { Link } from 'react-router-dom';
import CreateKeyModal from '../components/CreateKeyModal';
import CreateKeySuccessModal from '../components/CreateKeySuccessModal';
import ErrorModal from '../components/ErrorModal';
import RevokeKeyModal from '../components/RevokeKeyModal';
import ViewSecretModal from '../components/ViewSecretModal';
import { DevDashboardError } from '../consts/DevDashboardError';
import { APIKey, Mode, User } from '../consts/models';
import { useWorkspace } from '../hooks/useWorkspace';
import ListOfApiKeys from '../uikit/ListOfApiKeys';
import { useSession } from '../utils/session';

const modeToDomain = {
  sandbox: 'sandbox',
  development: 'development',
  production: 'api',
};

const getServerByModeAndEnv = (mode: Mode) => {
  const nodeEnv = process.env.REACT_APP_PINWHEEL_ENVIRONMENT?.includes('prod') ? '' : 'staging-';
  return `https://${nodeEnv}${modeToDomain[mode]}.getpinwheel.com`;
};

const APIKeySection = ({ apiKeys, mode, user, workspace }) => {
  const [createKeyModalOpen, setCreateKeyModalOpen] = React.useState<boolean>(false);
  const [newAPIKey, setNewAPIKey] = React.useState<APIKey | null>(null);
  const [keyToView, setKeyToView] = React.useState<APIKey | null>(null);
  const [keyToRevoke, setKeyToRevoke] = React.useState<APIKey | null>(null);
  const [error, setError] = React.useState<DevDashboardError | null>(null);

  // expired keys should be sorted after active keys
  const sortedKeys = [...apiKeys].sort((a, b) =>
    a.isActive != b.isActive
      ? b.isActive - a.isActive
      : a.lastUsedAt != b.lastUsedAt
      ? b.lastUsedAt - a.lastUsedAt
      : b.createdAt - a.createdAt
  );

  return (
    <React.Fragment>
      <CreateKeyModal
        isOpen={createKeyModalOpen}
        onRequestClose={() => setCreateKeyModalOpen(false)}
        setError={setError}
        setNewAPIKey={setNewAPIKey}
        mode={mode}
      />
      <ErrorModal isOpen={!!error} onRequestClose={() => setError(null)} error={error} />
      <CreateKeySuccessModal
        isOpen={!!newAPIKey}
        onRequestClose={() => window.location.reload()}
        newAPIKey={newAPIKey}
        mode={mode}
      />
      <ViewSecretModal
        isOpen={!!keyToView}
        onRequestClose={() => {
          setKeyToView(null);
        }}
        keyToView={keyToView}
      />
      <RevokeKeyModal
        isOpen={!!keyToRevoke}
        mode={mode}
        onRequestClose={() => {
          setKeyToRevoke(null);
        }}
        setError={setError}
        keyToRevoke={keyToRevoke}
      />

      <div>
        <ListOfApiKeys
          mode={mode}
          url={getServerByModeAndEnv(mode)}
          keys={sortedKeys}
          user={user}
          workspace={workspace}
          setCreateKeyModalOpen={setCreateKeyModalOpen}
          setKeyToView={setKeyToView}
          setKeyToRevoke={setKeyToRevoke}
        />
      </div>
    </React.Fragment>
  );
};

const APIKeys = () => {
  const session = useSession();

  if (session.loading || !session.data) {
    return null;
  }

  const user = session.data as User;
  const workspace = useWorkspace();

  if (!workspace) {
    return (
      <div>
        <div
          sx={{
            fontSize: 2,
            fontWeight: 500,
            marginBottom: '2.5rem',
          }}
        >
          API Keys
        </div>
        <p>
          You need access to a workspace in order to use this feature. Please contact an
          adminstrator.
        </p>
      </div>
    );
  }
  const { sandboxEnabledAt, developmentEnabledAt, productionEnabledAt } = workspace;

  return (
    <div>
      <div className="flex flex-row gap-8 w-full items-end mb-4">
        <div className="text-preset-5">API Keys</div>

        <Link className="text-blue-500" to="/smart-branch">
          Smart Branch
        </Link>
      </div>

      {sandboxEnabledAt && (
        <APIKeySection
          apiKeys={workspace.sandboxAPIKeys || []}
          mode="sandbox"
          user={user}
          workspace={workspace}
        />
      )}
      {developmentEnabledAt && (
        <APIKeySection
          apiKeys={workspace.developmentAPIKeys || []}
          mode="development"
          user={user}
          workspace={workspace}
        />
      )}
      {productionEnabledAt && (
        <APIKeySection
          apiKeys={workspace.productionAPIKeys || []}
          mode="production"
          user={user}
          workspace={workspace}
        />
      )}
    </div>
  );
};

export default APIKeys;
