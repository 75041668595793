/** @jsxImportSource theme-ui */
import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { Button, PrimaryButton } from '../../uikit/buttons';
import Modal from '../../uikit/Modal';

import { get } from 'lodash';
import React, { FormEvent } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DevDashboardError } from '../../consts/DevDashboardError';
import { MemberRole } from '../../consts/models';
import { UPDATE_MEMBER } from '../../graphql/mutations/update-member';
import { TeamsRadioGroup } from './roles';

const EditWorkspaceMemberRoleModal: React.FC<{
  onRequestClose: () => void;
  memberToEditRole: any;
}> = ({ onRequestClose, memberToEditRole }) => {
  const { addToast } = useToasts();
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [role, setRole] = React.useState<MemberRole>('developer');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (memberToEditRole) {
      setRole(memberToEditRole.role);
    }
  }, [memberToEditRole]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await updateMember({
        variables: {
          userId: memberToEditRole.user.id,
          role,
        },
      });
      const errorMsg = get(result, 'data.updateMember.error.message', null);
      if (errorMsg) {
        const code = get(result, 'data.updateMember.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }
    } catch (error) {
      const errorMsg = (error as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', { operation: 'updateMember', errorMsg });
      addToast(errorMsg, { appearance: 'error', autoDismiss: true });
    } finally {
      setLoading(false);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={Boolean(memberToEditRole)}
      width="30rem"
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        sx={{
          fontSize: 1,
          fontWeight: 500,
          marginBottom: '1.5rem',
        }}
      >
        Edit role for {memberToEditRole && memberToEditRole.user.email}
      </div>

      <form onSubmit={handleSubmit}>
        <TeamsRadioGroup role={role} setRole={setRole} />

        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2rem',
          }}
        >
          <Button type="button" onClick={onRequestClose}>
            Cancel
          </Button>
          <PrimaryButton type="submit" sx={{ marginLeft: '1rem' }} disabled={loading}>
            {loading ? 'Updating...' : 'Update'}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};

export default EditWorkspaceMemberRoleModal;
